import Images from "@Theme/Images";
import React, { useState } from "react";
import IndustrySupported from "./IndustrySupportedLayout";
import Styles from "./Styles.module.scss";

const allIndustry = [
  {
    name: "Insurance",
    logo: [Images.INSURANCE],
    jobs: [
      "Amin QC",
      "ASDC Admin",
      "Back Office",
      "Business Service Staff",
      "Call Center Officer",
      "Account Officer",
      "Data Analyst",
      "QA Officer",
    ],
  },
  {
    name: "Media",
    logo: [Images.MEDIA],

    jobs: [
      "Admin Staff",
      "Direct Sales",
      "Finance Officer",
      "HR Officer",
      "Motorist Sales",
      "Relationship Officer",
      "Staff Data Gathering",
      "Team Leader Inbound",
    ],
  },
  {
    name: "Construction",
    logo: [Images.CONSTRUCTION],

    jobs: [
      "Drafter",
      "Safety Officer",
      "Building Inspector",
      "Engineering Technician",
      "Product Manager",
      "Teknisi",
      "Supervisor",
      "CAD Data Base Staff",
    ],
  },
  {
    name: "Energy, Oil & Gas",
    logo: [Images.ENERGY],

    jobs: [
      "IT Billing Operator",
      "Analyst Operation",
      "Admin Internal Audit",
      "Admin Product Strategic",
      "Admin Treasury",
      "Admin Pajak",
      "Admin Marketing",
      "Admin Filling Corp Controller",
    ],
  },
  {
    name: "Telecommunication",
    logo: [Images.TELECOMMUNICATION],

    jobs: [
      "Admin Support",
      "Analyst Operation",
      "Team Leader DCO",
      "Sales Support",
      "Controller MT",
      "Finance Staff",
      "Quality Management",
      "Facilitator",
    ],
  },
  {
    name: "Machine & Automatic Equipment",
    logo: [Images.MACHINE],

    jobs: [
      "Production Engineer",
      "Operation Production",
      "Repairing Staff",
      "Inventory Executive",
      "Onda Teritory Sales",
      "Sales Promotion Girl",
      "Supervisor Sales Merchantdiser",
      "Team Leader",
    ],
  },
  {
    name: "Information Technology",
    logo: [Images.IT],

    jobs: [
      "Network Operation Center",
      "Network Provisioning",
      "IT Logistic Admin",
      "Junior IT field Support",
      "Junior IT Support",
      "Aplication Process",
      "IT and EDP",
      "Planinning and Design",
    ],
  },
  {
    name: "Health & Pharmacy",
    logo: [Images.HEALTH],

    jobs: [
      "Surveyor",
      "Asisten Apoteker",
      "Team Leader",
      "Nutritionist",
      "Medical Account Executive",
      "Representative Officer",
      "Relationship Officer",
      "Merchant Excellent",
    ],
  },
  {
    name: "Banking",
    logo: [Images.BANKING],

    jobs: [
      "Contenct Specialist",
      "Lead Creative Officer",
      "Recepsionist",
      "Admin Kredit",
      "Team Manager",
      "Digital Marketing",
      "Marketing Aquisition",
      "Leader Desk Collection",
    ],
  },
  {
    name: "Trade & Distribution",
    logo: [Images.PRODUCT],

    jobs: [
      "BPO and Policy",
      "Billing Retail Officer",
      "HR Admin",
      "QA IT Development",
      "Validation Officer",
      "Warehouse IKR",
      "Team Leader AE",
      "Goverment Relation Staff",
    ],
  },
  {
    name: "Development and Property",
    logo: [Images.PROPERTY],

    jobs: [
      "Design",
      "Staff Promotion & Event",
      "Building Approval",
      "Building Manager",
      "Accounting",
      "Staff Filling & PPJB",
      "Supervisor ME",
      "Civil Engineering",
    ],
  },
  {
    name: "General Business Services",
    logo: [Images.BUSSINESS],

    jobs: [
      "Warehouse Operation",
      "Sales Representative",
      "Stock Control",
      "Leader",
      "Recepsionist",
      "Supervisor",
      "General Worker",
      "Admin Fleet Service",
    ],
  },
];

export default function HomeProductContainerLayout() {
  const [isFeatureActive, setIsFeatureActive] = useState(0);
  const [selectedFeature, setSelectedFeature] = useState("");

  return (
    <div className={Styles.productContainer}>
      <div className={Styles.title}>
        <span className={Styles.smallTitle}>Kenapa harus memilih kami</span>
        <span className={Styles.bigTitle}>
          Kami Memiliki Banyak Layanan Yang Bisa Anda Pilih
        </span>
      </div>
      <div className={Styles.content}>
        <div className={Styles.productCard}>
          <div className={Styles.imageBackground}>
            <img
              src={Images.MAN_POWER_ILLUSTRATION}
              alt="MAN_POWER_ILLUSTRATION"
            />
          </div>
          <div className={Styles.name}>
            <span>Man Power Oursource</span>
          </div>
          <div className={Styles.info}>
            <span>
              Temukan layanan pekerjaan yang anda butuhkan, kami memiliki banyak
              layanan pekerjaan yang tersedia
            </span>
          </div>
        </div>
        <div className={Styles.productCard}>
          <div className={Styles.imageBackground}>
            <img
              src={Images.PAYING_AGENT_ILLUSTRATION}
              alt="PAYING_AGENT_ILLUSTRATION"
            />
          </div>
          <div className={Styles.name}>
            <span>Paying Agent</span>
          </div>
          <div className={Styles.info}>
            <span>
              Temukan jasa layanan pembayaran terbaik dari kami untuk setiap
              kebutuhan anda
            </span>
          </div>
        </div>
        <div className={Styles.productCard}>
          <div className={Styles.imageBackground}>
            <img
              src={Images.FREELANCE_ILLUSTRATION}
              alt="FREELANCE_ILLUSTRATION"
            />
          </div>
          <div className={Styles.name}>
            <span>Freelancer</span>
          </div>
          <div className={Styles.info}>
            <span>
              Kami memiliki lebih banyak layanan freelance untuk memenuhi
              kebutuhan anda
            </span>
          </div>
        </div>
        <div className={Styles.productCard}>
          <div className={Styles.imageBackground}>
            <img src={Images.IT_ILLUSTRATION} alt="IT_ILLUSTRATION" />
          </div>
          <div className={Styles.name}>
            <span>IT System Outsource</span>
          </div>
          <div className={Styles.info}>
            <span>
              Jadikan bisnis menjadi lebih lancar, kami memiliki tenaga ahli
              yang sudah berpengalaman
            </span>
          </div>
        </div>
      </div>

      <div className={Styles.subTitle}>
        <span className={Styles.smallTitle}> Layanan kami</span>
        <span className={Styles.regularTitle}>
          Kami Mendukung Berbagai Industry
        </span>
      </div>

      <div
        className={`${Styles.industry} ${
          Boolean(isFeatureActive) && Styles.active
        }
`}
      >
        {allIndustry
          .filter((item, index) => index < 6)
          .map((el, idx) => {
            const data = Styles.chip + idx;
            return (
              <div key={idx}>
                <div
                  onClick={() => [
                    setIsFeatureActive((id) => (id === idx + 1 ? 0 : idx + 1)),
                    setSelectedFeature(el),
                  ]}
                  className={`${Styles.chip} ${data}`}
                >
                  <div
                    className={Styles.imageBackground}
                    // onClick={() => }
                  >
                    <img src={el.logo} alt="CHIP_IMAGE" />
                  </div>
                  <span className={Styles.label}>{el.name}</span>
                </div>
              </div>
            );
          })}
        {Boolean(isFeatureActive) ? (
          <>
            <div className={`${Styles.chip} ${Styles.relations}`}>
              <IndustrySupported feature={selectedFeature} />
            </div>
          </>
        ) : null}

        {allIndustry.slice(6, 12).map((el, idx) => {
          const data = Styles.chip + idx;
          return (
            <div key={idx}>
              <div
                onClick={() => [
                  setIsFeatureActive((id) => (id === idx + 1 ? 0 : idx + 1)),
                  setSelectedFeature(el),
                ]}
                className={`${Styles.chip} ${data}`}
              >
                <div
                  className={Styles.imageBackground}
                  // onClick={() => }
                >
                  <img src={el.logo} alt="FINANCIAL_CHIP_IMAGE" />
                </div>
                <span className={Styles.label}>{el.name}</span>
              </div>
            </div>
          );
        })}
      </div>

      <div className={Styles.title}>
        <span className={Styles.bigTitle}>
          Kami Memiliki Keuntungan Untuk Anda
        </span>
        <p className={Styles.smallTitle}>
          Kami memahami apa yang anda inginkan dan kami siap mendukung setiap
          program kerja kami
        </p>
      </div>

      <div className={Styles.benefit}>
        <div className={Styles.window}>
          <img src={Images.BENEFIT_IMAGE_1} alt="BENEFIT_IMAGE_1" />
          <div className={Styles.title}>
            <span>Dari Kami</span>
          </div>
          <div className={Styles.content}>
            <ul>
              <li>Disupport oleh sistem hiring yang handal</li>
              <li>Transparansi</li>
              <li>Memiliki tenaga ahli rekrutment</li>
            </ul>
          </div>
        </div>
        <div className={Styles.window}>
          <img src={Images.BENEFIT_IMAGE_2} alt="BENEFIT_IMAGE_2" />
          <div className={Styles.title}>
            <span>Untuk Klien Kami</span>
          </div>
          <div className={Styles.content}>
            <ul>
              <li>Monitoring tenaga kerja dengan baik</li>
              <li>Manajemen administrasi lebih mudah</li>
              <li>Variasi Terms Of Payment</li>
            </ul>
          </div>
        </div>
        <div className={Styles.window}>
          <img src={Images.BENEFIT_IMAGE_3} alt="BENEFIT_IMAGE_3" />
          <div className={Styles.title}>
            <span>Pencari Kerja</span>
          </div>
          <div className={Styles.content}>
            <ul>
              <li>Tersedia banyak lowongan pekerjaan</li>
              <li>Employee benefit program</li>
              <li>Disupport oleh sistem yang memudahkan pekerjaan</li>
              <li>Proses rekrutmen cepat</li>
            </ul>
          </div>
        </div>
      </div>

      <div className={Styles.workHint}>
        <div className={Styles.centerer}>
          <div className={Styles.primary}>
            <h2>Bagaimana Cara Kerjanya</h2>
            <p>
              Kami membuat sistem yang memudahkan client untuk menemukan dan
              melakukan deal
            </p>
            <div className={Styles.step}>
              <div className={Styles.each}>
                <div>
                  <span>1</span>
                </div>
                <div className={Styles.wrapper}>
                  <div className={Styles.chip}>
                    <div>
                      <img src={Images.SIMULATION_ICON} alt="SIMULATION_ICON" />
                    </div>
                  </div>
                  <span>Simulation</span>
                </div>
              </div>
              <img src={Images.HINT_DOT_IMAGE} alt="HINT_DOT_IMAGE" />
              <div className={Styles.each}>
                <div>
                  <span>2</span>
                </div>
                <div className={Styles.wrapper}>
                  <div className={Styles.chip}>
                    <div>
                      <img
                        src={Images.REGISTRATION_ICON}
                        alt="REGISTRATION_ICON"
                      />
                    </div>
                  </div>
                  <span>Registration</span>
                </div>
              </div>
              <img src={Images.HINT_DOT_IMAGE} alt="HINT_DOT_IMAGE" />
              <div className={Styles.each}>
                <div>
                  <span>3</span>
                </div>
                <div className={Styles.wrapper}>
                  <div className={Styles.chip}>
                    <div>
                      <img
                        src={Images.VERIFICATION_ICON}
                        alt="VERIFICATION_ICON"
                      />
                    </div>
                  </div>
                  <span>Verification</span>
                </div>
              </div>
              <img src={Images.HINT_DOT_IMAGE} alt="HINT_DOT_IMAGE" />
              <div className={Styles.each}>
                <div>
                  <span>4</span>
                </div>
                <div className={Styles.wrapper}>
                  <div className={Styles.chip}>
                    <div>
                      <img src={Images.AGREEMENT_ICON} alt="AGREEMENT_ICON" />
                    </div>
                  </div>
                  <span>Agreement</span>
                </div>
              </div>
            </div>
          </div>
          <div className={Styles.secondary}>
            <div className={Styles.column}>
              <div className={Styles.card}>
                <img src={Images.JOB_AVAILABLE_ICON} alt="JOB_AVAILABLE_ICON" />
                <span>500+</span>
                <span className={Styles.name}>Job Available</span>
              </div>
              <div className={Styles.card}>
                <img src={Images.EASY_PLATFORM_ICON} alt="EASY_PLATFORM_ICON" />
                <span>Easy</span>
                <span className={Styles.name}>Easy Platform</span>
              </div>
            </div>
            <div className={Styles.column}>
              <div className={Styles.card}>
                <img
                  src={Images.PROFESSIONAL_TALENTS_ICON}
                  alt="PROFESSIONAL_TALENTS_ICON"
                />
                <span>1000+</span>
                <span className={Styles.name}>Professional Talents</span>
              </div>
              <div className={Styles.card}>
                <img src={Images.EXPERIENCE_ICON} alt="EXPERIENCE_ICON" />
                <span>
                  14 <span>Years</span>
                </span>
                <span className={Styles.name}>Of Experience</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import DinamicDecorativeInput from "@Atom/DinamicDecorativeInput";
import Styles from "./decorativeInputField.module.scss";

const DinamicDecorativeInputField = ({
  title,
  name,
  value,
  onChange,
  onDelete,
  type,
  adornment,
  index,
  locationIndex,
  preventDelete,
  ...props
}) => {
  return (
    <div className={Styles.container}>
      <div className={Styles.titleWrapper}>{title}</div>
      <DinamicDecorativeInput
        name={name}
        value={value}
        index={index}
        locationIndex={locationIndex}
        onChange={onChange}
        onDelete={onDelete}
        adornment={adornment}
        type={type}
        preventDelete={preventDelete}
      />
    </div>
  );
};

export default DinamicDecorativeInputField;

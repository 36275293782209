/*eslint-disable no-unused-vars*/
/*eslint-disable no-useless-escape*/

import Button from "@Atom/Button";
import { addClient } from "@Services/manager/client";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ContactSection from "./ContactSection";
import DocumentSection from "./DocumentSection";
import InfoSection from "./InfoSection";
import Styles from "./style.module.scss";
import RemarkSection from "./RemarkSection";
import ModalWrapper from "@Atom/ModalWrapper";
import SuccesModal from "./SuccessModal";
import useQuery from "@Hooks/useQuery";
import { validateEmail } from "@Helpers/validateEmail";

const PDFJS = require("pdfjs-dist/webpack");

export default function AddClientLayout({ sync }) {
  const [client, setClient] = useState(null);
  const navigate = useNavigate();
  const [infoAlert, setInfoAlert] = useState(false);
  const [contactAlert, setContactAlert] = useState(false);
  const [documentAlert, setDocumentAlert] = useState(false);
  const [errorFileReject, setErrorFileReject] = useState({});

  const [legalNameType, setLegalNameType] = useState("PT");

  const defaultClientType = sessionStorage.getItem("clientType") || "";

  const [type, setType] = useState(defaultClientType || "EXISTING");
  const [existingType, setExistingType] = useState("mws");

  const defaultExistingType = sessionStorage.getItem("clientExistingType");

  useEffect(() => {
    sessionStorage.setItem("clientType", type);
  }, [type]);

  const defaultClientDocuments = sessionStorage.getItem("clientDocuments")
    ? JSON.parse(sessionStorage.getItem("clientDocuments"))
    : null;

  const [files, setFiles] = useState([
    {
      type: "AKTA CLIENT",
      file:
        defaultClientDocuments && defaultClientDocuments[0]?.file
          ? defaultClientDocuments[0]?.file
          : null,
      preview:
        defaultClientDocuments && defaultClientDocuments[0]?.preview
          ? defaultClientDocuments[0]?.preview
          : null,
      name:
        defaultClientDocuments && defaultClientDocuments[0]?.name
          ? defaultClientDocuments[0]?.name
          : null,
    },
    {
      type: "NPWP CLIENT",
      file:
        defaultClientDocuments && defaultClientDocuments[1]?.file
          ? defaultClientDocuments[1]?.file
          : null,
      preview:
        defaultClientDocuments && defaultClientDocuments[1]?.preview
          ? defaultClientDocuments[1]?.preview
          : null,
      name:
        defaultClientDocuments && defaultClientDocuments[1]?.name
          ? defaultClientDocuments[1]?.name
          : null,
    },
    {
      type: "SIUP CLIENT",
      file:
        defaultClientDocuments && defaultClientDocuments[2]?.file
          ? defaultClientDocuments[2]?.file
          : null,
      preview:
        defaultClientDocuments && defaultClientDocuments[2]?.preview
          ? defaultClientDocuments[2]?.preview
          : null,
      name:
        defaultClientDocuments && defaultClientDocuments[2]?.name
          ? defaultClientDocuments[2]?.name
          : null,
    },

    {
      type: "DOMISILI CLIENT",
      file:
        defaultClientDocuments && defaultClientDocuments[3]?.file
          ? defaultClientDocuments[3]?.file
          : null,
      preview:
        defaultClientDocuments && defaultClientDocuments[3]?.preview
          ? defaultClientDocuments[3]?.preview
          : null,
      name:
        defaultClientDocuments && defaultClientDocuments[3]?.name
          ? defaultClientDocuments[3]?.name
          : null,
    },
    {
      type: "DOKUMEN SPK/PKS/WO/PO",
      file:
        defaultClientDocuments && defaultClientDocuments[4]?.file
          ? defaultClientDocuments[4]?.file
          : null,
      preview:
        defaultClientDocuments && defaultClientDocuments[4]?.preview
          ? defaultClientDocuments[4]?.preview
          : null,
      name:
        defaultClientDocuments && defaultClientDocuments[4]?.name
          ? defaultClientDocuments[4]?.name
          : null,
    },
  ]);

  const [error, setError] = useState(null);

  const [generalAlert, setGeneralAlert] = useState(false);

  const [uploading, setUploading] = useState([
    false,
    false,
    false,
    false,
    false,
  ]);

  const handleEditFiles = async (e, i) => {
    const newFiles = [...files];
    const newUploading = [...uploading];
    newUploading[i] = true;
    setUploading(newUploading);
    const file = e.target.files[0];
    newFiles[i].file = file;
    newFiles[i].name = file.name;
    const uri = URL.createObjectURL(file);
    var pdf = await PDFJS.getDocument({ url: uri }).promise;
    const canvas = document.createElement("canvas");
    const images = [];
    for (let i = 0; i < pdf.numPages; i++) {
      const page = await pdf.getPage(i + 1);
      const viewport = page.getViewport({ scale: 1 });
      const context = canvas.getContext("2d");
      canvas.height = viewport.height;
      canvas.width = viewport.width;
      await page.render({ canvasContext: context, viewport: viewport }).promise;
      images.push(canvas.toDataURL());
    }
    canvas.remove();
    newFiles[i].preview = images[0];
    newUploading[i] = false;
    setUploading(newUploading);
    setFiles(newFiles);
  };

  const [errorFile0, setErrorFile0] = useState("");

  const onDrop0 = useCallback(
    async (acceptedFiles, fileRejections) => {
      if (fileRejections?.length) {
        fileRejections.forEach((file) => {
          file.errors.forEach((err) => {
            if (err.code === "file-too-large") {
              setErrorFileReject({
                message: `File ${file.file.name} terlalu besar. Ukuran maksimal file adalah 5MB !`,
              });
            }
          });
        });
      }

      if (acceptedFiles?.length > 0) {
        const file = acceptedFiles[0];

        if (file?.type === "application/pdf" || file.name.endsWith(".pdf")) {
          setErrorFile0("");
          setErrorFileReject({});
          const newFiles = [...files];
          const newUploading = [...uploading];
          newUploading[0] = true;
          setUploading(newUploading);

          newFiles[0].file = file;
          newFiles[0].name = file.name;
          const uri = URL.createObjectURL(file);
          var pdf = await PDFJS.getDocument({ url: uri }).promise;
          const canvas = document.createElement("canvas");
          const images = [];
          for (let i = 0; i < pdf.numPages; i++) {
            const page = await pdf.getPage(i + 1);
            const viewport = page.getViewport({ scale: 1 });
            const context = canvas.getContext("2d");
            canvas.height = viewport.height;
            canvas.width = viewport.width;
            await page.render({ canvasContext: context, viewport: viewport })
              .promise;
            images.push(canvas.toDataURL());
          }
          canvas.remove();
          newFiles[0].preview = images[0];
          newUploading[0] = false;
          setUploading(newUploading);
          setFiles(newFiles);
        } else {
          setErrorFile0("Format tidak sesuai");
        }
      } else {
        setErrorFile0("Format tidak sesuai");
      }
    },
    [files, uploading]
  );

  const [errorFile1, setErrorFile1] = useState("");

  const onDrop1 = useCallback(
    async (acceptedFiles, fileRejections) => {
      if (fileRejections?.length) {
        fileRejections.forEach((file) => {
          file.errors.forEach((err) => {
            if (err.code === "file-too-large") {
              setErrorFileReject({
                message: `File ${file.file.name} terlalu besar. Ukuran maksimal file adalah 5MB !`,
              });
            }
          });
        });
      }
      if (acceptedFiles?.length > 0) {
        const file = acceptedFiles[0];

        if (file?.type === "application/pdf" || file.name.endsWith(".pdf")) {
          setErrorFileReject({});
          setErrorFile1("");
          const newFiles = [...files];
          const newUploading = [...uploading];
          newUploading[1] = true;
          setUploading(newUploading);

          newFiles[1].file = file;
          newFiles[1].name = file.name;
          const uri = URL.createObjectURL(file);
          var pdf = await PDFJS.getDocument({ url: uri }).promise;
          const canvas = document.createElement("canvas");
          const images = [];
          for (let i = 0; i < pdf.numPages; i++) {
            const page = await pdf.getPage(i + 1);
            const viewport = page.getViewport({ scale: 1 });
            const context = canvas.getContext("2d");
            canvas.height = viewport.height;
            canvas.width = viewport.width;
            await page.render({ canvasContext: context, viewport: viewport })
              .promise;
            images.push(canvas.toDataURL());
          }
          canvas.remove();
          newFiles[1].preview = images[0];
          newUploading[1] = false;
          setUploading(newUploading);
          setFiles(newFiles);
        } else {
          setErrorFile1("Format tidak sesuai");
        }
      } else {
        setErrorFile1("Format tidak sesuai");
      }
    },
    [files, uploading]
  );

  const [errorFile2, setErrorFile2] = useState("");

  const onDrop2 = useCallback(
    async (acceptedFiles, fileRejections) => {
      if (fileRejections?.length) {
        fileRejections.forEach((file) => {
          file.errors.forEach((err) => {
            if (err.code === "file-too-large") {
              setErrorFileReject({
                message: `File ${file.file.name} terlalu besar. Ukuran maksimal file adalah 5MB !`,
              });
            }
          });
        });
      }
      if (acceptedFiles?.length > 0) {
        const file = acceptedFiles[0];
        if (file?.type === "application/pdf" || file.name.endsWith(".pdf")) {
          setErrorFileReject({});
          setErrorFile2("");
          const newFiles = [...files];
          const newUploading = [...uploading];
          newUploading[2] = true;
          setUploading(newUploading);

          newFiles[2].file = file;
          newFiles[2].name = file.name;
          const uri = URL.createObjectURL(file);
          var pdf = await PDFJS.getDocument({ url: uri }).promise;
          const canvas = document.createElement("canvas");
          const images = [];
          for (let i = 0; i < pdf.numPages; i++) {
            const page = await pdf.getPage(i + 1);
            const viewport = page.getViewport({ scale: 1 });
            const context = canvas.getContext("2d");
            canvas.height = viewport.height;
            canvas.width = viewport.width;
            await page.render({ canvasContext: context, viewport: viewport })
              .promise;
            images.push(canvas.toDataURL());
          }
          canvas.remove();
          newFiles[2].preview = images[0];
          newUploading[2] = false;
          setUploading(newUploading);
          setFiles(newFiles);
        } else {
          setErrorFile2("Format tidak sesuai");
        }
      } else {
        setErrorFile2("Format tidak sesuai");
      }
    },
    [files, uploading]
  );

  const [errorFile3, setErrorFile3] = useState("");
  const onDrop3 = useCallback(
    async (acceptedFiles, fileRejections) => {
      if (fileRejections?.length) {
        fileRejections.forEach((file) => {
          file.errors.forEach((err) => {
            if (err.code === "file-too-large") {
              setErrorFileReject({
                message: `File ${file.file.name} terlalu besar. Ukuran maksimal file adalah 5MB !`,
              });
            }
          });
        });
      }
      if (acceptedFiles?.length > 0) {
        const file = acceptedFiles[0];
        if (file?.type === "application/pdf" || file.name.endsWith(".pdf")) {
          setErrorFile3("");
          setErrorFileReject({});
          const newFiles = [...files];
          const newUploading = [...uploading];
          newUploading[3] = true;
          setUploading(newUploading);

          newFiles[3].file = file;
          newFiles[3].name = file.name;
          const uri = URL.createObjectURL(file);
          var pdf = await PDFJS.getDocument({ url: uri }).promise;
          const canvas = document.createElement("canvas");
          const images = [];
          for (let i = 0; i < pdf.numPages; i++) {
            const page = await pdf.getPage(i + 1);
            const viewport = page.getViewport({ scale: 1 });
            const context = canvas.getContext("2d");
            canvas.height = viewport.height;
            canvas.width = viewport.width;
            await page.render({ canvasContext: context, viewport: viewport })
              .promise;
            images.push(canvas.toDataURL());
          }
          canvas.remove();
          newFiles[3].preview = images[0];
          newUploading[3] = false;
          setUploading(newUploading);
          setFiles(newFiles);
        } else {
          setErrorFile3("Format tidak sesuai");
        }
      } else {
        setErrorFile3("Format tidak sesuai");
      }
    },
    [files, uploading]
  );

  const [errorFile4, setErrorFile4] = useState("");
  const onDrop4 = useCallback(
    async (acceptedFiles, fileRejections) => {
      if (fileRejections?.length) {
        fileRejections.forEach((file) => {
          file.errors.forEach((err) => {
            if (err.code === "file-too-large") {
              setErrorFileReject({
                message: `File ${file.file.name} terlalu besar. Ukuran maksimal file adalah 5MB !`,
              });
            }
          });
        });
      }
      if (acceptedFiles?.length > 0) {
        const file = acceptedFiles[0];

        if (file?.type === "application/pdf" || file.name.endsWith(".pdf")) {
          setErrorFile4("");
          setErrorFileReject({});
          const newFiles = [...files];
          const newUploading = [...uploading];
          newUploading[4] = true;
          setUploading(newUploading);

          newFiles[4].file = file;
          newFiles[4].name = file.name;
          const uri = URL.createObjectURL(file);
          var pdf = await PDFJS.getDocument({ url: uri }).promise;
          const canvas = document.createElement("canvas");
          const images = [];
          for (let i = 0; i < pdf.numPages; i++) {
            const page = await pdf.getPage(i + 1);
            const viewport = page.getViewport({ scale: 1 });
            const context = canvas.getContext("2d");
            canvas.height = viewport.height;
            canvas.width = viewport.width;
            await page.render({ canvasContext: context, viewport: viewport })
              .promise;
            images.push(canvas.toDataURL());
          }
          canvas.remove();
          newFiles[4].preview = images[0];
          newUploading[4] = false;
          setUploading(newUploading);
          setFiles(newFiles);
        } else {
          setErrorFile4("Format tidak sesuai");
        }
      } else {
        setErrorFile4("Format tidak sesuai");
      }
    },
    [files, uploading]
  );

  const defaultForm =
    sessionStorage.getItem("clientInformationForm") &&
    sessionStorage.getItem("clientInformationForm") !== "undefined"
      ? JSON.parse(sessionStorage.getItem("clientInformationForm"))
      : null;

  const [form, setForm] = useState({
    name: defaultForm?.name ? defaultForm?.name : "",
    companyName: defaultForm?.companyName ? defaultForm?.companyName : "",
    taxNumber: defaultForm?.taxNumber ? defaultForm?.taxNumber : "",
    brandName: defaultForm?.brandName ? defaultForm?.brandName : "",
    legalName: defaultForm?.legalName ? defaultForm?.legalName : "",
    industry: defaultForm?.industry ? defaultForm?.industry : "",
    groupName: defaultForm?.groupName ? defaultForm?.groupName : "",
    postal: defaultForm?.postal ? defaultForm?.postal : "",
    province: defaultForm?.province ? defaultForm?.province : "",
    address: defaultForm?.address ? defaultForm?.address : "",
    website: defaultForm?.website ? defaultForm?.website : "",
    phoneNumber: defaultForm?.phoneNumber ? defaultForm?.phoneNumber : "",
    email: defaultForm?.email ? defaultForm?.email : "",
    contacts: defaultForm?.contacts ? defaultForm?.contacts : [],
    files: defaultForm?.files ? defaultForm?.files : [],
    remark: defaultForm?.remark ? defaultForm?.remark : "",
  });

  // console.log(form);

  const resetForm = () => {
    setForm((prev) => {
      return {
        ...prev,
        name: "",
        companyName: "",
        taxNumber: "",
        brandName: "",
        legalName: "",
        industry: "",
        groupName: "",
        postal: "",
        province: "",
        address: "",
        website: "",
        phoneNumber: "",
        email: "",
        contacts: [
          {
            name: "",
            jobLevel: "",
            email: "",
            phoneNumber: "",
            workUnit: "",
            selectedUnit: [],
          },
        ],
        files: [],
        remark: "",
      };
    });
    setContacts([
      {
        name: "",
        jobLevel: "",
        email: "",
        phoneNumber: "",
        workUnit: "",
        selectedUnit: [],
      },
    ]);
    setExistingType("");
  };

  const defaultContacts = sessionStorage.getItem("clientContacts")
    ? JSON.parse(sessionStorage.getItem("clientContacts"))
    : null;

  const [contacts, setContacts] = useState(
    defaultContacts?.length > 0
      ? defaultContacts?.map((contact) => ({
          name: contact?.name ? contact?.name : "",
          jobLevel: contact?.jobLevel ? contact?.jobLevel : "",
          email: contact?.email ? contact?.email : "",
          phoneNumber: contact?.phoneNumber ? contact?.phoneNumber : "",
          workUnit: contact?.workUnit ? contact?.workUnit : "",
          selectedUnit:
            contact?.selectedUnit?.length > 0 ? contact?.selectedUnit : [],
        }))
      : [
          {
            name: "",
            jobLevel: "",
            email: "",
            phoneNumber: "",
            workUnit: "",
            selectedUnit: [],
          },
        ]
  );

  const defaultRemark = sessionStorage.getItem("remarkSection")
    ? sessionStorage.getItem("remarkSection")
    : "";

  const [remark, setRemark] = useState(defaultRemark || "");

  const handleAddContactInfo = () => {
    const newContacts = [
      ...contacts,
      {
        name: "",
        jobLevel: "",
        email: "",
        phoneNumber: "",
        workUnit: "",
        selectedUnit: [],
      },
    ];
    setContacts(newContacts);
  };

  const handleChangeContact = (e, i, name) => {
    const newContacts = [...contacts];
    newContacts[i][name] = e.target.value;
    setContacts(newContacts);
  };

  const handleChangeForm = (e, name) => {
    setForm((prev) => {
      return {
        ...prev,
        [name]: e.target.value.replace(
          /[^0-9/\[\]\\_+,.@!`~#%$*&()={}|:;"'<,>?\-\n a-zA-Z]+/gi,
          ""
        ),
      };
    });
  };

  const handleChangeFormValue = (newVal, name) => {
    setForm((prev) => {
      return {
        ...prev,
        [name]: newVal,
      };
    });
  };

  const notEmptyFiles = useMemo(() => {
    let arr = [];

    for (let i = 0; i < files.length; i++) {
      const notEmpty = Boolean(files[i].file !== null);

      notEmpty &&
        arr.push({
          types: files[i].type,
          files: files[i].file,
        });
    }

    return arr;
  }, [files]);

  const notEmptyContacts = useMemo(() => {
    let arr = [];

    for (let i = 0; i < contacts.length; i++) {
      // const notEmpty = Boolean(
      //   contacts[i].name !== "" &&
      //     contacts[i].jobLevel !== "" &&
      //     contacts[i].email !== "" &&
      //     contacts[i].phoneNumber !== "" &&
      //     !contacts[i]?.id
      //     ? contacts[i].selectedUnit?.find((el) => el === "Lainnya")
      //       ? contacts[i]?.workUnit !== ""
      //       : contacts[i].selectedUnit?.length > 0
      //     : contacts[i]?.workUnit !== ""
      // );

      arr.push(contacts[i]);
    }

    return arr;
  }, [contacts]);

  useEffect(() => {
    setForm((prev) => {
      return {
        ...prev,
        files: notEmptyFiles,
      };
    });
  }, [notEmptyFiles]);

  useEffect(() => {
    setForm((prev) => {
      return {
        ...prev,
        remark: remark,
      };
    });
  }, [remark]);

  useEffect(() => {
    setForm((prev) => {
      return {
        ...prev,
        contacts: notEmptyContacts,
      };
    });
  }, [notEmptyContacts]);

  const doesContactContainEmptyString = useMemo(() => {
    let status = contacts.some(
      (contact) =>
        contact?.name === "" ||
        contact?.jobLevel === "" ||
        contact?.email === "" ||
        !validateEmail(contact?.email) ||
        contact?.phoneNumber === "" ||
        (!contact?.id
          ? contact.selectedUnit?.find((el) => el === "Lainnya")
            ? contact?.workUnit === ""
            : contact.selectedUnit?.length === 0
          : contact?.workUnit === "")
    );

    console.log("contacts", contacts);

    return status;
  }, [contacts]);

  console.log("does", doesContactContainEmptyString);

  const infoNotComplete = useMemo(() => {
    return Boolean(
      form?.name === "" ||
        form?.postal === "" ||
        form?.province === "" ||
        form?.address === "" ||
        form?.phoneNumber === "" ||
        form?.taxNumber === "" ||
        form?.brandName === "" ||
        form?.legalName === "" ||
        form?.industry === "" ||
        form?.groupName === "" ||
        form?.email === "" ||
        !validateEmail(form?.email)
    );
  }, [form]);

  const resetAlert = () => {
    setInfoAlert(false);
    setContactAlert(false);
    setDocumentAlert(false);
    setGeneralAlert(false);
    setError(null);
  };

  const [isSubmitting, setIsSubmiting] = useState(false);
  const [isConfirm, setIsConfirm] = useState(null);

  const [validate, setValidate] = useState(null);
  const containerRef = useRef(null);

  // console.log(validate, form?.contacts);

  const handleSubmit = async (e) => {
    if (!isSubmitting) {
      try {
        e.preventDefault();
        resetAlert();
        setIsSubmiting(true);

        const formData = new FormData();
        formData.append("source", type === "NEW" ? "new" : existingType);

        if (type === "EXISTING") {
          formData.append("code", client?.code);
        }
        formData.append("name", form?.companyName?.toUpperCase());
        formData.append("address", form?.address?.toUpperCase());
        formData.append("postal", form?.postal);
        formData.append("province", form?.province?.toUpperCase());
        formData.append(
          "phoneNumber",
          (type !== "EXISTING" ? "62" : "") + form?.phoneNumber
        );
        formData.append("email", form?.email?.toLowerCase());
        formData.append("website", form?.website);
        formData.append("taxNumber", form?.taxNumber);
        formData.append("brandName", form?.brandName?.toUpperCase());
        formData.append(
          "legalName",
          legalNameType + " " + form?.legalName?.toUpperCase()
        );
        formData.append("industry", form?.industry);
        formData.append("groupName", form?.groupName?.toUpperCase());
        formData.append("remark", form?.remark);

        if (existingType !== "k365") {
          for (let i = 0; i < form?.files?.length; i++) {
            formData.append("types", form?.files[i].types);
            formData.append("files", form?.files[i].files);
          }
        }

        for (let i = 0; i < form?.contacts?.length; i++) {
          formData.append(
            `contacts[${i}][name]`,
            form?.contacts[i]?.name?.toUpperCase()
          );
          formData.append(
            `contacts[${i}][jobTitle]`,
            form?.contacts[i]?.jobLevel?.toUpperCase()
          );
          formData.append(
            `contacts[${i}][email]`,
            form?.contacts[i]?.email?.toLowerCase()
          );
          formData.append(
            `contacts[${i}][phoneNumber]`,
            (!form?.contacts[i]?.viewOnly ? "62" : "") +
              form?.contacts[i]?.phoneNumber
          );

          formData.append(
            `contacts[${i}][workUnit]`,
            !form?.contacts[i]?.id
              ? form?.contacts[i]?.selectedUnit?.length > 0
                ? form?.contacts[i]?.selectedUnit?.find(
                    (selected) => selected !== "Lainnya"
                  )
                  ? form?.contacts[i]?.selectedUnit
                      ?.map((item) => item.toUpperCase())
                      .join(" & ")
                  : form?.contacts[i]?.workUnit?.toUpperCase()
                : form?.contacts[i]?.workUnit?.toUpperCase() || ""
              : form?.contacts[i]?.workUnit?.toUpperCase()
          );
        }

        if (
          infoNotComplete ||
          doesContactContainEmptyString === true ||
          !contacts?.length
        ) {
          setIsSubmiting(false);

          if (!form?.taxNumber) {
            setValidate((prev) => ({
              ...prev,
              taxNumber: true,
            }));
          } else {
            setValidate((prev) => ({
              ...prev,
              taxNumber: false,
            }));
          }

          if (form?.taxNumber) {
            if (!form?.companyName) {
              setValidate((prev) => ({
                ...prev,
                companyName: true,
              }));
            } else {
              setValidate((prev) => ({
                ...prev,
                companyName: false,
              }));
            }

            if (!form?.legalName) {
              setValidate((prev) => ({
                ...prev,
                legalName: true,
              }));
            } else {
              setValidate((prev) => ({
                ...prev,
                legalName: false,
              }));
            }

            if (!form?.groupName) {
              setValidate((prev) => ({
                ...prev,
                groupName: true,
              }));
            } else {
              setValidate((prev) => ({
                ...prev,
                groupName: false,
              }));
            }

            if (!form?.brandName) {
              setValidate((prev) => ({
                ...prev,
                brandName: true,
              }));
            } else {
              setValidate((prev) => ({
                ...prev,
                brandName: false,
              }));
            }

            if (!form?.industry) {
              setValidate((prev) => ({
                ...prev,
                industry: true,
              }));
            } else {
              setValidate((prev) => ({
                ...prev,
                industry: false,
              }));
            }

            if (!form?.phoneNumber) {
              setValidate((prev) => ({
                ...prev,
                phoneNumber: true,
              }));
            } else {
              setValidate((prev) => ({
                ...prev,
                phoneNumber: false,
              }));
            }

            if (!form?.province) {
              setValidate((prev) => ({
                ...prev,
                province: true,
              }));
            } else {
              setValidate((prev) => ({
                ...prev,
                province: false,
              }));
            }

            if (!form?.address) {
              setValidate((prev) => ({
                ...prev,
                address: true,
              }));
            } else {
              setValidate((prev) => ({
                ...prev,
                address: false,
              }));
            }

            if (!form?.email || !validateEmail(form?.email)) {
              setValidate((prev) => ({
                ...prev,
                email: true,
              }));
            } else {
              setValidate((prev) => ({
                ...prev,
                email: false,
              }));
            }

            if (!form?.postal) {
              setValidate((prev) => ({
                ...prev,
                postal: true,
              }));
            } else {
              setValidate((prev) => ({
                ...prev,
                postal: false,
              }));
            }
            if (form?.contacts?.length > 0) {
              form?.contacts?.forEach((el, idx) => {
                if (!el?.email || !validateEmail(el?.email)) {
                  setValidate((prev) => ({
                    ...prev,
                    [`email_${idx}`]: true,
                  }));
                } else {
                  setValidate((prev) => ({
                    ...prev,
                    [`email_${idx}`]: false,
                  }));
                }

                if (!el?.jobLevel) {
                  setValidate((prev) => ({
                    ...prev,
                    [`jobLevel_${idx}`]: true, // Menambahkan indeks ke dalam key jobLevel
                  }));
                } else {
                  setValidate((prev) => ({
                    ...prev,
                    [`jobLevel_${idx}`]: false, // Menambahkan indeks ke dalam key jobLevel
                  }));
                }

                if (!el?.name) {
                  setValidate((prev) => ({
                    ...prev,
                    [`name_${idx}`]: true, // Menambahkan indeks ke dalam key name
                  }));
                } else {
                  setValidate((prev) => ({
                    ...prev,
                    [`name_${idx}`]: false, // Menambahkan indeks ke dalam key name
                  }));
                }

                if (!el?.email || !validateEmail(el?.email)) {
                  setValidate((prev) => ({
                    ...prev,
                    [`email_${idx}`]: true, // Menambahkan indeks ke dalam key email
                  }));
                } else {
                  setValidate((prev) => ({
                    ...prev,
                    [`email_${idx}`]: false, // Menambahkan indeks ke dalam key email
                  }));
                }

                if (!el?.phoneNumber) {
                  setValidate((prev) => ({
                    ...prev,
                    [`phoneNumber_${idx}`]: true, // Menambahkan indeks ke dalam key phoneNumber
                  }));
                } else {
                  setValidate((prev) => ({
                    ...prev,
                    [`phoneNumber_${idx}`]: false, // Menambahkan indeks ke dalam key phoneNumber
                  }));
                }

                if (el?.selectedUnit?.length === 0) {
                  setValidate((prev) => ({
                    ...prev,
                    [`selectedUnit_${idx}`]: true, // Menambahkan indeks ke dalam key selectedUnit
                  }));
                } else {
                  setValidate((prev) => ({
                    ...prev,
                    [`selectedUnit_${idx}`]: false, // Menambahkan indeks ke dalam key selectedUnit
                  }));
                }

                if (
                  el?.selectedUnit?.length > 0 &&
                  el?.selectedUnit?.find((el) => el === "Lainnya") &&
                  !el?.workUnit
                ) {
                  setValidate((prev) => ({
                    ...prev,
                    [`workUnit_${idx}`]: true, // Menambahkan indeks ke dalam key selectedUnit
                  }));
                } else {
                  setValidate((prev) => ({
                    ...prev,
                    [`workUnit_${idx}`]: false, // Menambahkan indeks ke dalam key selectedUnit
                  }));
                }
              });
            }
          }

          containerRef.current.scrollIntoView({ behavior: "smooth" });
        } else {
          // if (type === 'NEW') {
          const response = await addClient(formData);
          // } else {
          //   await addClientExisting(formData);
          // }

          sessionStorage.removeItem("clientType");
          sessionStorage.removeItem("clientDocuments");
          sessionStorage.removeItem("clientInformationForm");
          sessionStorage.removeItem("clientContacts");
          sessionStorage.removeItem("remarkSection");

          setIsSubmiting(false);
          setIsConfirm({
            clientName:
              response?.response?.client?.name ||
              response?.response?.client[1][0]?.name ||
              "",
            clentCode:
              response?.response?.client?.code ||
              response?.response?.client[1][0]?.code ||
              "",
          });
          // navigate(
          //   `/clients/add-new-client?n=${
          //     response?.response?.client?.name ||
          //     response?.response?.client[1][0]?.name ||
          //     ""
          //   }&c=${response?.response?.client[1][0]?.code || ""}`
          // );
        }
      } catch (err) {
        setIsSubmiting(false);
        if (err?.response?.data?.code === 400) {
          setError(err?.response?.data?.error);
          setForm((prev) => {
            return {
              ...prev,
              code: "",
            };
          });
          setInfoAlert(true);
          setGeneralAlert(true);
        } else if (err?.response?.data?.code === 401) {
          setError("Server error");
          setGeneralAlert(true);
        } else {
          setError(err?.response?.data?.error || "Something went wrong");
          setGeneralAlert(true);
        }
      }
    }
  };

  const typesTemplate = [
    {
      type: "NEW",
      title: "Tambah Baru",
      desc: "Tambahkan dan simpan klien baru ke dalam aplikasi Kerja365.",
      icon: "client-new",
    },
    {
      type: "EXISTING",
      title: "Tambah Existing",
      desc: "Tambahkan dan simpan klien yang sudah ada sebelumnya di dalam aplikasi Kerja365 atau MWS.",
      icon: "client-existing",
    },
  ];

  // console.log(validate);

  return (
    <div className={Styles.container}>
      {/* <div className={Styles.typesWrapper}>
        {typesTemplate?.map((each, i) => (
          <div key={i} className={`${Styles.type} ${type === each?.type ? Styles.active : ''}`} onClick={() => {
            setType(each?.type)
            resetForm()
          }}>
            <CheckboxJobPosting
              type='radio-section'
              checked={type === each?.type}
              onChange={() => setType(each?.type)}
            />
            <Icon icon={each?.icon} size={60} className={Styles.icon} />
            <div className={Styles.desc}>
              <span>{each?.title}</span>
              <span>{each?.desc}</span>
            </div>
          </div>
        ))}
      </div> */}
      <form onSubmit={handleSubmit} ref={containerRef}>
        <div className={Styles.formContent}>
          <InfoSection
            alert={infoAlert}
            form={form}
            setForm={setForm}
            resetForm={resetForm}
            onChange={handleChangeForm}
            onChangeValue={handleChangeFormValue}
            type={type}
            setType={setType}
            setContacts={setContacts}
            setFiles={setFiles}
            existingType={existingType}
            setExistingType={setExistingType}
            client={client}
            setClient={setClient}
            legalNameType={legalNameType}
            setLegalNameType={setLegalNameType}
            cities={sync?.cities || []}
            industries={sync?.industries || []}
            setRemark={setRemark}
            validate={validate}
          />
          <ContactSection
            alert={contactAlert}
            form={form}
            contacts={contacts}
            setContacts={setContacts}
            onChange={handleChangeContact}
            onAdd={handleAddContactInfo}
            type={type}
            validate={validate}
          />
          <DocumentSection
            errorFileReject={errorFileReject}
            alert={documentAlert}
            onDrop0={onDrop0}
            onDrop1={onDrop1}
            onDrop2={onDrop2}
            onDrop3={onDrop3}
            onDrop4={onDrop4}
            uploadings={uploading}
            items={files}
            onChange={handleEditFiles}
            type={type}
            existingType={existingType}
            client={client}
            errorFile0={errorFile0}
            errorFile1={errorFile1}
            errorFile2={errorFile2}
            errorFile3={errorFile3}
            errorFile4={errorFile4}
          />
          <RemarkSection remark={remark} setRemark={setRemark} />
        </div>
        <div className={Styles.generalAlertWrapper}>
          <span className={generalAlert ? Styles.alert : Styles.normal}>
            {error
              ? error
              : "Terdapat informasi yang tidak lengkap. Form harap dicek kembali."}
          </span>
        </div>
        <div className={Styles.buttonWrapper}>
          <div
            className={Styles.cancelButtonWrapper}
            onClick={() => navigate("/clients")}
          >
            <Button type="secondary-black" title="Cancel" />
          </div>
          <div className={Styles.addButtonWrapper} onClick={handleSubmit}>
            <Button
              type="primary"
              title={isSubmitting ? "Saving..." : "Save"}
              disabled={isSubmitting}
            />
          </div>
        </div>
      </form>

      {isConfirm ? (
        <SuccesModal
          name={isConfirm?.clientName || ""}
          code={isConfirm?.clentCode || ""}
        />
      ) : null}
    </div>
  );
}

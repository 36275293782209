import React, { useState, useEffect, useRef, useMemo } from "react";
import Styles from "./style.module.scss";
import Images from "@Theme/Images";
import InputField from "@Molecule/InputField";
import Stepper from "@Molecule/Stepper";
import { clientRegister } from "@Services/client/auth";

const RegisterCard = ({ setHash, data, setData }) => {
  const [currentSubStep, setCurrentSubStep] = useState(1);
  const [value, setValue] = useState({
    companyName: data?.clientInformation?.name || null,
    npwp: data?.clientInformation?.taxNumber || null,
    postalCode: data?.clientInformation?.postal || null,
    province: data?.clientInformation?.province || null,
    companyAddress: data?.clientInformation?.address || null,
    companyPhoneNumber: data?.clientInformation?.phoneNumber || null,
    companyEmail: data?.clientInformation?.email || null,
    companyWebsite: data?.clientInformation?.website || null,
    contactPersonName: data?.contactInformation?.contactName || null,
    contactPersonPosition: data?.contactInformation?.contactJobTitle || null,
    contactPersonPhoneNumber:
      data?.contactInformation?.contactPhoneNumber?.slice(2) || null,
    contactPersonEmail: data?.contactInformation?.contactEmail || null,
    password: data?.contactInformation?.contactPassword1 || null,
    confirmationPassword: data?.contactInformation?.contactPassword2 || null,
    workUnit: data?.contactInformation?.contactWorkUnit || null,
  });

  const [error, setError] = useState(null);

  const [isRegistering, setIsRegistering] = useState(false);

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setError(null);
      }, 5000);
    }
  }, [error]);

  const stepsOptions = [
    {
      id: 1,
      label: "Informasi Perusahaan",
      isDone: true,
    },
    {
      id: 2,
      label: "Informasi Kontak",
      isDone: false,
    },
  ];

  const onClickStep = (id) => {
    if (
      id === 2 &&
      value.companyName &&
      value.npwp &&
      value.postalCode &&
      value.province &&
      value.companyAddress &&
      value.companyPhoneNumber &&
      value.companyEmail &&
      value.companyWebsite
    ) {
      return setCurrentSubStep(2);
    }
    setCurrentSubStep(1);
  };

  const handleChangeForm = (e, name) => {
    const re = /^[0-9\b]+$/;
    let targetValue;
    if (name === "companyPhoneNumber") {
      if (e.target.value === "" || re.test(e.target.value)) {
        targetValue = e.target.value;
      } else {
        targetValue = value?.companyPhoneNumber;
      }
    } else if (name === "npwp") {
      if (e.target.value === "" || re.test(e.target.value)) {
        targetValue = e.target.value;
      } else {
        targetValue = value?.npwp;
      }
    } else {
      targetValue = e.target.value;
    }
    setValue((prev) => {
      return {
        ...prev,
        [name]: targetValue,
      };
    });
  };

  const handleNextPage = () => {
    if (
      value.companyName &&
      value.npwp &&
      value.postalCode &&
      value.province &&
      value.companyAddress
    ) {
      return setCurrentSubStep(2);
    }
    return null;
  };

  const targetElement = useRef();
  const scrollingTop = (event) => {
    const elmnt = targetElement;
    elmnt.current.scrollIntoView({
      behavior: "auto",
      block: "center",
      inline: "start",
    });
  };
  useEffect(() => {
    scrollingTop();
  }, [currentSubStep]);

  const handleSignUp = async (e) => {
    try {
      e.preventDefault();
      setIsRegistering(true);
      if (value?.password !== value?.confirmationPassword) {
        setError("Passwords do not match");
        setIsRegistering(false);
      } else {
        const { response } = await clientRegister({
          contactInformation: {
            contactEmail: value?.contactPersonEmail,
          },
          clientInformation: {
            name: value?.companyName,
            taxNumber: value?.npwp,
            postal: value?.postalCode,
            province: value?.province,
            address: value?.companyAddress,
            phoneNumber: value?.companyPhoneNumber,
            website: value?.companyWebsite || "",
          },
        });

        setIsRegistering(false);
        setHash(response?.hash);
      }
    } catch (err) {
      setIsRegistering(false);
      if (
        err?.response?.data?.error
          ?.toLowerCase()
          ?.includes("contact already exist")
      ) {
        setError(`Email kontak sudah terdaftar. Coba email lain.`);
      } else {
        setError(err?.response?.data?.error);
      }
    }
  };

  useEffect(() => {
    setData({
      clientInformation: {
        name: value?.companyName,
        taxNumber: value?.npwp,
        postal: value?.postalCode,
        province: value?.province,
        address: value?.companyAddress,
        phoneNumber: value?.companyPhoneNumber,
        email: value?.companyEmail,
        website: value?.companyWebsite || "",
      },
      contactInformation: {
        contactName: value?.contactPersonName,
        contactJobTitle: value?.contactPersonPosition,
        contactPhoneNumber: "62" + value?.contactPersonPhoneNumber,
        contactEmail: value?.contactPersonEmail,
        contactPassword1: value?.password,
        contactPassword2: value?.confirmationPassword,
        contactWorkUnit: value?.workUnit,
      },
    });

    // eslint-disable-next-line
  }, [value]);

  const disableRegister = useMemo(() => {
    if (
      value.companyName &&
      value.npwp &&
      value.postalCode &&
      value.province &&
      value.companyAddress &&
      value.companyPhoneNumber &&
      value.companyEmail &&
      value.contactPersonName &&
      value.contactPersonPosition &&
      value.contactPersonPhoneNumber &&
      value.contactPersonEmail &&
      value.password &&
      value.confirmationPassword &&
      value.workUnit
    ) {
      return false;
    } else {
      return true;
    }
  }, [value]);

  return (
    <form className={Styles.container} onSubmit={handleSignUp}>
      <img
        className={Styles.logo}
        src={Images.KERJA365_LIGHT}
        alt="KERJA_365_LOGO"
        ref={targetElement}
      />
      <div className={Styles.textWrapper}>
        <div className={Styles.titleText}>Daftar</div>
        <div className={Styles.informationText}>
          Daftarkan perusahaan anda sekarang untuk mulai bergabung bersama kami
        </div>
      </div>
      <div>
        <Stepper
          options={stepsOptions}
          currentStep={currentSubStep}
          onClick={onClickStep}
        />
      </div>
      {currentSubStep === 1 && (
        <div className={Styles.formWrapper}>
          <InputField
            required
            title="Nama Perusahaan"
            placeholder="Berdasarkan pendirian perusahaan"
            onChange={(e) => handleChangeForm(e, "companyName")}
            value={value.companyName}
          />
          <InputField
            required
            title="NPWP Perusahaan"
            placeholder="NPWP Perusahaan"
            onChange={(e) => handleChangeForm(e, "npwp")}
            value={value.npwp}
          />
          <div className={Styles.doubleInputWrapper}>
            <InputField
              required
              title="Kode Pos"
              placeholder="Kode pos daerah perusahaan"
              onChange={(e) => handleChangeForm(e, "postalCode")}
              value={value.postalCode}
              onKeyDown={(evt) => ["e", "E", "+", "-", "."].includes(evt.key) && evt.preventDefault()}
              type='number'  
            />
            <InputField
              required
              title="Provinsi Perusahaan"
              placeholder="Provinsi perusahaan anda"
              onChange={(e) => handleChangeForm(e, "province")}
              value={value.province}
            />
          </div>
          <InputField
            required
            title="Alamat Perusahaan"
            placeholder="Alamat lengkap perusahaan anda"
            type="address"
            hideIcon
            onChange={(e) => handleChangeForm(e, "companyAddress")}
            value={value.companyAddress}
          />
          <div className={Styles.doubleInputWrapper}>
            <InputField
              required
              title="Nomor Telepon Perusahaan"
              type="tel"
              placeholder="021*** atau 62***"
              onChange={(e) => handleChangeForm(e, "companyPhoneNumber")}
              value={value.companyPhoneNumber}
            />
            <InputField
              required
              title="Email Perusahaan"
              placeholder="example@mail.com"
              onChange={(e) => handleChangeForm(e, "companyEmail")}
              value={value.companyEmail}
            />
          </div>
          <InputField
            title="Website Perusahaan"
            placeholder="Website Perusahaan"
            onChange={(e) => handleChangeForm(e, "companyWebsite")}
            value={value.companyWebsite}
          />
        </div>
      )}
      {currentSubStep === 2 && (
        <div className={Styles.formWrapper}>
          <div className={Styles.doubleInputWrapper}>
            <InputField
              required
              id="cpName"
              title="Nama"
              placeholder="Nama"
              onChange={(e) => handleChangeForm(e, "contactPersonName")}
              value={value.contactPersonName}
            />
            <InputField
              required
              id="cpPosition"
              title="Posisi"
              placeholder="Posisi pekerjaan"
              onChange={(e) => handleChangeForm(e, "contactPersonPosition")}
              value={value.contactPersonPosition}
            />
          </div>
          <div className={Styles.doubleInputWrapper}>
            <InputField
              required
              id="cpPhoneNumber"
              title="Nomor Telepon"
              placeholder="8***"
              type="phone"
              onChange={(e) => handleChangeForm(e, "contactPersonPhoneNumber")}
              value={value.contactPersonPhoneNumber}
            />
            <InputField
              required
              id="cpEmail"
              title="Email"
              placeholder="example@mail.com"
              onChange={(e) => handleChangeForm(e, "contactPersonEmail")}
              value={value.contactPersonEmail}
            />
          </div>
          <div className={Styles.doubleInputWrapper}>
            <InputField
              title="Kata Sandi"
              id="cpPassword"
              placeholder="Kata sandi akun"
              type="password"
              onChange={(e) => handleChangeForm(e, "password")}
              value={value.password}
            />
            <InputField
              title="Konfirmasi Kata Sandi"
              id="cpConfirmation"
              placeholder="Konfirmasi kata sandi"
              type="password"
              onChange={(e) => handleChangeForm(e, "confirmationPassword")}
              value={value.confirmationPassword}
            />
          </div>
          <InputField
            required
            title="Unit Kerja"
            id="cpWorkUnit"
            placeholder="Tulis unit kerja"
            onChange={(e) => handleChangeForm(e, "workUnit")}
            value={value.workUnit}
          />
        </div>
      )}
      <div className={`${Styles.ErrorWrapper} ${error ? Styles.Error : ""}`}>
        <p>{error ? error : "error placeholder"}</p>
      </div>
      <div className={Styles.nextButtonWrapper}>
        {currentSubStep === 1 && (
          <button
            type="button"
            disabled={
              !value.companyName ||
              !value.npwp ||
              !value.postalCode ||
              !value.province ||
              !value.companyAddress ||
              !value.companyPhoneNumber ||
              !value.companyEmail
            }
            className={
              value.companyName &&
              value.npwp &&
              value.postalCode &&
              value.province &&
              value.companyAddress &&
              value.companyPhoneNumber &&
              value.companyEmail
                ? Styles.button
                : Styles.buttonDisabled
            }
            onClick={handleNextPage}
          >
            Selanjutnya
          </button>
        )}
        {currentSubStep === 2 && (
          <>
            <button
              type="button"
              className={Styles.buttonBack}
              onClick={() => setCurrentSubStep(1)}
            >
              Kembali
            </button>
            <button
              type="submit"
              disabled={isRegistering || disableRegister}
              className={
                !disableRegister && !isRegistering
                  ? Styles.button
                  : Styles.buttonDisabled
              }
            >
              {isRegistering ? "Mendaftarkan..." : "Daftar Sekarang"}
            </button>
          </>
        )}
      </div>
    </form>
  );
};

export default RegisterCard;

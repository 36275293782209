import Images from "@Theme/Images";
// import { OverviewCardComponent, OverviewContainerComponent } from '../../../../../../../k365_old/src/components'
import Styles from "./Styles.module.scss";

export default function HomeAboutContainerLayout(props) {
  const { aboutRef } = props;

  //   const opinions = useMemo(() => ([{
  //     rating: '4.0',
  //     userName: 'Jerry Dane',
  //     userPosition: 'HR PT Gojek',
  //     opinion: 'Dari awal mengenal Kerja 365 kami tertarik.'
  //   }, {
  //     rating: '4.0',
  //     userName: 'Jerry Dane',
  //     userPosition: 'HR PT Gojek',
  //     opinion: 'Dari awal mengenal Kerja 365 kami tertarik karena memiliki tenaga profesional di bidangnya, ternyata hasil kerjanya sangat memuaskan sekali. Kerja 365 memang selalu menyeleksi kandidat yang sesuai dengan kualifikasi yang di inginkan.'
  //   }, {
  //     rating: '4.0',
  //     userName: 'Jerry Dane',
  //     userPosition: 'HR PT Gojek',
  //     opinion: 'Dari awal mengenal Kerja 365 kami tertarik karena memiliki tenaga profesional di bidangnya, ternyata hasil kerjanya sangat memuaskan sekali. Kerja 365 memang selalu menyeleksi kandidat yang sesuai dengan kualifikasi yang di inginkan.'
  //   }, {
  //     rating: '4.0',
  //     userName: 'Jerry Dane',
  //     userPosition: 'HR PT Gojek',
  //     opinion: 'Dari awal mengenal Kerja 365 kami tertarik karena memiliki tenaga profesional di bidangnya, ternyata hasil kerjanya sangat memuaskan sekali. Kerja 365 memang selalu menyeleksi kandidat yang sesuai dengan kualifikasi yang di inginkan.'
  //   },
  // ]), [])

  // const groupedOpinions = useMemo(() => {
  //   const result = [];
  //   let temporary = [];
  //   for (let i = 0; i < opinions.length; i++) {
  //     if (temporary.length < 3) {
  //       temporary.push(opinions[i]);
  //     } else {
  //       result.push(temporary);
  //       temporary = [];
  //       temporary.push(opinions[i]);
  //     }
  //   }
  //   if (temporary.length > 0) {
  //     result.push(temporary);
  //   }
  //   return result;
  // }, [opinions]);

  return (
    <div ref={aboutRef} className={Styles.aboutContainer}>
      {/* <span className={ Styles.Title }>Kisah Sukses Kami</span> */}
      {/* <OverviewContainerComponent pageMax={ groupedOpinions.length - 1 }>
      { groupedOpinions.map((element, index) => (
        element.map((element1, index1) => (
          <OverviewCardComponent index={ `${ index }.${ index1 }` } key={ `${ index }.${ index1 }` } rating={ element1.rating } userName={ element1.userName } userPosition={ element1.userPosition }>
            { element1.opinion }
          </OverviewCardComponent>
        ))
      )) }
      </OverviewContainerComponent> */}
      <div className={Styles.purpose}>
        <div className={Styles.visionContainer}>
          <img
            src={Images.PERSON_HOLDING_TARGET_BOARD_IMAGE}
            alt="PERSON_HOLDING_TARGET_BOARD_IMAGE"
          />
          <div className={Styles.content}>
            <span>
              Visi Kami Menjadi Perusahaan Outsourcing Terbaik Dan Profesional
            </span>
            <div className={Styles.grid}>
              <div>
                <div className={Styles.image}>
                  <img
                    src={Images.PENGEMBANGAN_IMAGE}
                    alt="PENGEMBANGAN_IMAGE"
                  />
                </div>
                <div className={Styles.info}>
                  <span className={Styles.title}>Pengembangan</span>
                  <span className={Styles.vision}>
                    Membantu badan usaha baik sebagai pelanggan maupun mitra
                    dalam meningkatkan dan mengembangkan usaha melalui pemberian
                    jasa penyediaan tenaga kerja dan outsourcing proses bisnis
                    secara profesional
                  </span>
                </div>
              </div>
              <div>
                <div className={Styles.image}>
                  <img src={Images.TARAF_HIDUP_IMAGE} alt="TARAF_HIDUP_IMAGE" />
                </div>
                <div className={Styles.info}>
                  <span className={Styles.title}>Taraf Hidup</span>
                  <span className={Styles.vision}>
                    Membantu perorangan baik sebagai tenaga outsource maupun
                    karyawan perusahaan dalam meningkatkan taraf hidup dengan
                    kemampuan teknis, non teknis dan finansial
                  </span>
                </div>
              </div>
              <div>
                <div className={Styles.image}>
                  <img
                    src={Images.OPTIMALISASI_IMAGE}
                    alt="OPTIMALISASI_IMAGE"
                  />
                </div>
                <div className={Styles.info}>
                  <span className={Styles.title}>Optimalisasi</span>
                  <span className={Styles.vision}>
                    Membantu investor untuk mengembangkan bisnis mereka yang
                    menguntungkan melalui optimalisasi kinerja, sistem dan
                    teknologi
                  </span>
                </div>
              </div>
              <div>
                <div className={Styles.image}>
                  <img
                    src={Images.DEDIKASI_BANGSA_IMAGE}
                    alt="DEDIKASI_BANGSA_IMAGE"
                  />
                </div>
                <div className={Styles.info}>
                  <span className={Styles.title}>Dedikasi Bangsa</span>
                  <span className={Styles.vision}>
                    Membantu pemerintah dalam mengembangkan kegiatan ekonomi,
                    penyediaan lapangan kerja dan penyaluran tenaga kerja yang
                    produktif.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

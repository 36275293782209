import Icon from "@Atom/Icon";
import { useDebounce } from "@Hooks/useDebounce";
import UseQuery from "@Hooks/useQuery";
import CustomBreadCrumb from "@Molecule/CustomBreadCrumb";
import ClientDetailLayout from "@Organism/Manager/Client/ClientDetail";
import AsyncErrorBoundary from "@Organism/Utils/AsyncErrorBoundary";
import { addClientSyncOptions, getClientDetail } from "@Services/manager/client";
import styled from "@emotion/styled";
import { TextField } from "@mui/material";
import { Suspense, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Await, defer, useLoaderData, useLocation, useNavigate } from "react-router-dom";
import LoadingSkeleton from "./Skeleton";
import Styles from "./clientDetail.module.scss";

// eslint-disable-next-line no-unused-vars
const MuiTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    color: '#0a0a0a',
    fontSize: '14px',
    fontWeight: 400,
    background: '#ffffff',
    borderRadius: 8,
    padding: '8px 12px',
    '& .MuiAutocomplete-input': {
      padding: 0
    },
    "& fieldset": {
      borderRadius: 8,
      border: '1px solid #E0E0E0',
    },
    '&:hover fieldset': {
      border: '1px solid #E0E0E0',
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #1571DE",
      padding: '8px 12px',
    }
  },
  '& .MuiInputBase-input::placeholder': {
    fontSize: '14px',
  },
  '& .MuiInputBase-input': {
    // height: '16px',
    padding: 0
  },

});

export async function loader(route) {
  const params = new URLSearchParams(route?.request?.url);
  const code = params?.get("c");
  const page = params?.get("p") || 1
  const limit = params?.get("l") || 10
  const type = params?.get("t") || 'companyInfo'
  const search = params?.get("s") || ''
  const verify = params?.get('v') || 'yes'

  const clientDetailPromise = getClientDetail(code, limit, page, 10, 1, '', type, search, verify)
  // const orderSyncResult = orderSync();

  return defer({
    packageClientDetail: clientDetailPromise,
    packageSync: addClientSyncOptions(),
    // packageOrderSync: orderSyncResult,
  });
}

export default function ClientDetail({
  rbmView = false
}) {
  const { packageClientDetail, packageSync } = useLoaderData();

  const query = UseQuery();
  const navigate = useNavigate();
  const { pathname } = useLocation()

  const name = query.get("n");
  const code = query.get("c");

  const [search, setSearch] = useState(query.get('s') || '')

  const debouncedSearch = useDebounce(search, 500)

  const [value, setValue] = useState(10);
  const [selectedPage, setSelectedPage] = useState(1);
  const [verify, setVerify] = useState(query.get('v') || 'yes')


  const handleClickBack = () => {
    if (rbmView) {
      navigate(-1)
    } else {
      navigate(`/clients`);
    }
  };

  const tabs = [
    {
      title: 'Company Information',
      query: 'companyInfo'
    },
    {
      title: 'Form Layanan Bisnis',
      query: 'flb'
    },
    {
      title: 'Man Power',
      query: 'mp',
      // disabled: true,
    },
    {
      title: 'Contact Information',
      query: 'contact'
    },
    // {
    //   title: 'Delegation',
    //   query: 'delegation'
    // }
  ]

  const defaultTab = query.get('t') || tabs[0].query

  const [activeTab, setActiveTab] = useState(defaultTab)

  useEffect(() => {
    navigate(`${pathname}?n=${name}&s=${debouncedSearch}&c=${code}&tt=${query.get('tt') || '1'}&p=${query.get('p') || '1'}&l=${query.get('l') || '10'}&t=${activeTab}&v=${verify}`, { replace: true })
  }, [activeTab, code, debouncedSearch, name, navigate, pathname, query, verify])

  return (
    <div className={Styles.container}>
      <Helmet>
        <title>Kerja 365 | Client Details</title>
      </Helmet>
      <div className={Styles.topWrapper}>
        <div className={Styles.breadcrumbWrapper}>
          <span onClick={handleClickBack}>
            <Icon icon="arrow-left-rounded-primary" color="#1571DE" size={20} />
          </span>
          <CustomBreadCrumb>
            <span onClick={handleClickBack} className={Styles.breadcrumbText}>
              Client
            </span>
            <span className={Styles.breadcrumbText2}>{name}</span>
          </CustomBreadCrumb>
        </div>
        <div className={Styles.tabs}>
          {tabs?.map((each, i) => (
            <button key={i} disabled={each?.disabled} className={`${Styles.tab} ${activeTab === each?.query ? Styles.active : ''}`} onClick={() => setActiveTab(each?.query)}>
              {each?.title}
            </button>
          ))}
        </div>
      </div>

      <div className={Styles.contentWrapper}>
        <Suspense fallback={
          <LoadingSkeleton />
        }>
          <Await resolve={packageClientDetail} errorElement={
            <div className={Styles.errorWrapper}>
              <AsyncErrorBoundary />
            </div>}>
            {(packageClientDetail) => (
              <Suspense fallback={
                <LoadingSkeleton />
              }>
                <Await
                  resolve={packageSync}
                  errorElement={<div className={Styles.errorWrapper}>
                    <AsyncErrorBoundary />
                  </div>}
                >
                  {(packageSync) => (
                    <ClientDetailLayout
                      defaultData={packageClientDetail?.response}
                      cities={[]}
                      activeTab={activeTab}
                      type={activeTab}
                      value={value}
                      setValue={setValue}
                      selectedPage={selectedPage}
                      setSelectedPage={setSelectedPage}
                      search={search}
                      setSearch={setSearch}
                      verify={verify}
                      setVerify={setVerify}
                      rbmView={rbmView}
                      sync={packageSync?.response}
                    />
                  )}
                </Await>
              </Suspense>
            )}
          </Await>
        </Suspense>
      </div>
    </div>
  );
}

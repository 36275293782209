import OTPComponent from '@Molecule/OTPInput';
import Styles from './style.module.scss'

export default function Second({
  email,
  otp,
  setOtp,
  error
}) {

  return (
    <div className={Styles.container}>
      <OTPComponent
        onChange={(value) => {
          setOtp(value);
        }}
        alert={error}
      />
    </div>
  )
}
import { API } from "@Config/api";
import axios from "axios";

export function getClients(query) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(`/client?${query}`, {
        headers: {
          // Authorization: sessionStorage.getItem('accessToken'),
          Authorization: localStorage.getItem("accessToken"),
        },
      });

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function getClientDetail(
  code,
  limit,
  page,
  candidateLimit,
  candidatePage,
  candidateFilter,
  type,
  flbCode,
  verify = ""
) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(
        `/client/tools/${code}?limit=${limit}&page=${page}&candidateLimit=${candidateLimit}&candidatePage=${candidatePage}&candidateSearch=${candidateFilter}&type=${type}&flbCode=${flbCode}&verify=${verify}`,
        {
          headers: {
            // Authorization: sessionStorage.getItem('accessToken'),
            Authorization: localStorage.getItem("accessToken"),
          },
        }
      );

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}
export function getManPowerPerScheme(code, limit, page) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(
        `/client/manPower/${code}?limit=${limit}&page=${page}`,
        {
          headers: {
            // Authorization: sessionStorage.getItem('accessToken'),
            Authorization: localStorage.getItem("accessToken"),
          },
        }
      );

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}
export function getCandidatePipeline(
  limit,
  page,
  name,
  positionID = "",
  status = ""
) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(`/candidate/pipeline/bm`, {
        headers: {
          // Authorization: sessionStorage.getItem('accessToken'),
          Authorization: localStorage.getItem("accessToken"),
        },
        params: {
          limit,
          page,
          name,
          positionID,
          status,
        },
      });

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}
export function getCandidatePipelineDetails(candidateID = "") {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(`/candidate/pipeline/bm/${candidateID}`, {
        headers: {
          Authorization: sessionStorage.getItem("accessToken"),
        },
      });

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function addClientSync(search) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(
        `/client/active-client-mws?search=${search}`,
        {
          headers: {
            // Authorization: sessionStorage.getItem('accessToken'),
            Authorization: localStorage.getItem("accessToken"),
          },
        }
      );

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}
export function addClientSyncOptions() {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(`/client/sync`, {
        headers: {
          // Authorization: sessionStorage.getItem('accessToken'),
          Authorization: localStorage.getItem("accessToken"),
        },
      });

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function addClient(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.post(`/client`, payload, {
        headers: {
          // Authorization: sessionStorage.getItem('accessToken'),
          Authorization: localStorage.getItem("accessToken"),
        },
      });

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}
export function addClientExisting(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.post(`/client/existing`, payload, {
        headers: {
          // Authorization: sessionStorage.getItem('accessToken'),
          Authorization: localStorage.getItem("accessToken"),
        },
      });

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function addClientContact(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.post(`/client-contact`, payload, {
        headers: {
          // Authorization: sessionStorage.getItem('accessToken'),
          Authorization: localStorage.getItem("accessToken"),
        },
      });

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}
export function updateClientContact(id, payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.patch(`/client-contact/${id}`, payload, {
        headers: {
          // Authorization: sessionStorage.getItem('accessToken'),
          Authorization: localStorage.getItem("accessToken"),
        },
      });

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}
export function deleteClientContact(id) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.delete(`/client-contact/${id}`, {
        headers: {
          // Authorization: sessionStorage.getItem('accessToken'),
          Authorization: localStorage.getItem("accessToken"),
        },
      });

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function createDocument(payload) {
  const isClient = localStorage.getItem("isClient") === "true";
  // const isClient = sessionStorage.getItem("isClient") === 'true';
  if (isClient) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await API.post(`/documents/client`, payload, {
          headers: {
            // Authorization: sessionStorage.getItem('accessToken'),
            Authorization: localStorage.getItem("accessToken"),
          },
        });

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  } else {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await API.post(`/documents`, payload, {
          headers: {
            // Authorization: sessionStorage.getItem('accessToken'),
            Authorization: localStorage.getItem("accessToken"),
          },
        });

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  }
}

export function updateDocument(id, payload) {
  const isClient = localStorage.getItem("isClient") === "true";
  // const isClient = sessionStorage.getItem("isClient");
  if (isClient) {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await API.patch(`/documents/client/${id}`, payload, {
          headers: {
            // Authorization: sessionStorage.getItem('accessToken'),
            Authorization: localStorage.getItem("accessToken"),
          },
        });

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  } else {
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await API.patch(`/documents/${id}`, payload, {
          headers: {
            // Authorization: sessionStorage.getItem('accessToken'),
            Authorization: localStorage.getItem("accessToken"),
          },
        });

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  }
}

export function orderSync() {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(`/order-sync`, {
        headers: {
          // Authorization: sessionStorage.getItem('accessToken'),
          Authorization: localStorage.getItem("accessToken"),
        },
      });

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function getAllContactsByClientCode(clientCode='') {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(`/client-contact/by-client-code/${clientCode}`, {
        headers: {
          // Authorization: sessionStorage.getItem('accessToken'),
          Authorization: localStorage.getItem('accessToken'),
        },
      });

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function addOrder(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.post(`/order`, payload, {
        headers: {
          // Authorization: sessionStorage.getItem('accessToken'),
          Authorization: localStorage.getItem("accessToken"),
        },
      });

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function saveOrderAsDraft(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.post(`/order/draft`, payload, {
        headers: {
          // Authorization: sessionStorage.getItem('accessToken'),
          Authorization: localStorage.getItem("accessToken"),
        },
      });

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function editOrderAsDraft(payload, id) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.patch(`/order/draft/${id}`, payload, {
        headers: {
          // Authorization: sessionStorage.getItem('accessToken'),
          Authorization: localStorage.getItem("accessToken"),
        },
      });

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function editOrderAndSendFLB(payload, id) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.patch(`/order/send/${id}`, payload, {
        headers: {
          // Authorization: sessionStorage.getItem('accessToken'),
          Authorization: localStorage.getItem("accessToken"),
        },
      });

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function getUmk() {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await axios.get(
        "http://ios.permataindonesia.com/api/get-umk"
      );

      resolve(data);
    } catch (error) {
      reject(error);
      console.log(error);
    }
  });
}

export function getCityUmk(city) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(`/order-sync/umk/${city}`, {
        headers: {
          // Authorization: sessionStorage.getItem('accessToken'),
          Authorization: localStorage.getItem("accessToken"),
        },
      });

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function getSchemeDetail(id) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(`/order/detail/${id}`, {
        headers: {
          // Authorization: sessionStorage.getItem('accessToken'),
          Authorization: localStorage.getItem("accessToken"),
        },
      });

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function getSchemeDetailRO(id) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(`/order/detail-for-ro/${id}`, {
        headers: {
          // Authorization: sessionStorage.getItem('accessToken'),
          Authorization: localStorage.getItem("accessToken"),
        },
      });

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function getAssignData(code) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(`/client/assign/${code}`, {
        headers: {
          // Authorization: sessionStorage.getItem('accessToken'),
          Authorization: localStorage.getItem("accessToken"),
        },
      });

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function clientAssignDelegation(hash, payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.post(`/client/assign/${hash}`, payload, {
        headers: {
          // Authorization: sessionStorage.getItem('accessToken'),
          Authorization: localStorage.getItem("accessToken"),
        },
      });

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function getManPowerDetailNeed(id, limit, page, search, tag) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(
        `/candidate/tools/position/${id}?limit=${limit}&page=${page}&search=${search}&filter=${tag}`,
        {
          headers: {
            // Authorization: sessionStorage.getItem('accessToken'),
            Authorization: localStorage.getItem("accessToken"),
          },
        }
      );

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function updateClientInfo(id, payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.patch(`/client/tools/${id}`, payload, {
        headers: {
          // Authorization: sessionStorage.getItem('accessToken'),
          Authorization: localStorage.getItem("accessToken"),
        },
      });

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function getClientOrders(
  clientCode,
  page,
  limit,
  search = "",
  status = ""
) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(
        `/client/tools/orders/${clientCode}?page=${page}&limit=${limit}&search=${search}&status=${status}`,
        {
          headers: {
            // Authorization: sessionStorage.getItem('accessToken'),
            Authorization: localStorage.getItem("accessToken"),
          },
        }
      );

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function getLogFLB(id, page, limit) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(`/order/log?schemeID=${id}&page=${page}&limit=${limit}`, {
        headers: {
          Authorization: localStorage.getItem("accessToken"),
        },
      });

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}
/* eslint-disable no-unused-vars */
import convertHtmlToPdf from "@Helpers/convertHtmlToPdf";
import React, { useEffect, useRef, useState } from "react";
import PdfView from "./ContractView";
import SignContract from "./SignContract";
import Styles from "./style.module.scss";
import { isMobile } from "react-device-detect";
import SignPreview from "./SignPreview";
import { signContract } from "@Services/contract/contract";
import SuccessSign from "./SuccessSign";
import ApprovedContract from "./ApprovedContract";
import { useNavigate } from "react-router-dom";
import LocationContract from "./LocationContract";

const PDFJS = window.pdfjsLib;

export default function CandidateContractLayout({ data }) {
  const [dataToSend, setDataToSend] = useState({
    longitude: 0,
    latitude: 0,
    signature: "",
    initials: "",
    image: "",
  });
  const [pdf, setPdf] = useState("");
  const [pdfUrl, setPdfUrl] = useState("");
  const [images, setImages] = useState([]);
  const getStep = sessionStorage?.getItem("stepContractCandidate") || 1;
  const [step, setStep] = useState(+getStep || 1);
  const navigate = useNavigate();

  useEffect(() => {
    sessionStorage.setItem("stepContractCandidate", step || 1);
  }, [step]);

  useEffect(() => {
    if (step === 4) {
      sessionStorage?.removeItem("stepContractCandidate");
    }
  }, [step]);

  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(function (position) {
      setDataToSend((prev) => ({
        ...prev,
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
      }));
    });
  }, []);

  const handleSubmitSign = async () => {
    try {
      setIsSubmitting(true);
      const res = await signContract(
        data.response.candidateContract.id,
        dataToSend
      );
      if (res.response.candidate.tag === "Accepted") {
        navigate(".", { replace: true });
        setStep(5);
      }
      setIsSubmitting(false);
    } catch (error) {
      console.log(error);
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    if (
      data.response.candidate.stage === "SIGN-CONTRACT" &&
      data.response.candidate.tag === "Accepted"
    ) {
      setStep(5);
    }
    if (
      data.response.candidate.stage === "DONE" ||
      data.response.candidate.stage === "IOS-SUCCESS"
    ) {
      setStep(6);
    }
    // console.log(data.response.candidate.stage === "SIGN-CONTRACT");
  }, [data]);

  useEffect(() => {
    const getPdfUrl = async () => {
      const convertedHtml = await convertHtmlToPdf(
        data.response.candidateContract?.template,
        "contract",
        {
          ...data.response.candidate,
          ...data.response.candidateContract,
          ...dataToSend,
          position: data.response?.expectedPosition?.name,
          contractType: data.response?.expectedPosition?.contractType,
        }
      );
      setPdfUrl(convertedHtml);
    };
    getPdfUrl();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.response.candidateContract?.template, step]);

  async function showPdf(url) {
    try {
      var _PDF_DOC = await PDFJS.getDocument({
        url: url,
      });
      setPdf(_PDF_DOC);
    } catch (error) {
      alert(error.message);
    }
  }

  useEffect(() => {
    isMobile && pdf && renderPage();
    // eslint-disable-next-line
  }, [pdf]);

  useEffect(() => {
    isMobile && pdfUrl && showPdf(pdfUrl);
  }, [pdfUrl]);

  async function renderPage() {
    const imagesList = [];
    const canvas = document.createElement("canvas");
    canvas.setAttribute("className", "canv");
    // let canv = document.querySelector(".canv");

    for (let i = 1; i <= pdf.numPages; i++) {
      var page = await pdf.getPage(i);
      var viewport = page.getViewport({ scale: 1 });
      canvas.height = viewport.height;
      canvas.width = viewport.width;
      var render_context = {
        canvasContext: canvas.getContext("2d"),
        viewport: viewport,
      };

      await page.render(render_context).promise;
      let img = canvas.toDataURL("image/png");
      imagesList.push(img);
    }
    setImages(imagesList);
  }

  return (
    <div className={Styles.Container}>
      {step === 1 && (
        <PdfView pdf={pdfUrl} images={images} setStep={setStep} data={data} />
      )}
      {step === 2 && <LocationContract state={dataToSend} setStep={setStep} />}
      {step === 3 && (
        <SignContract
          state={dataToSend}
          setState={setDataToSend}
          setStep={setStep}
        />
      )}
      {step === 4 && (
        <SignPreview
          data={dataToSend}
          setStep={setStep}
          pdf={pdfUrl}
          images={images}
          handleSubmit={() => handleSubmitSign()}
          isSubmitting={isSubmitting}
        />
      )}
      {step === 5 && <SuccessSign data={data.response} pdf={pdfUrl} />}
      {step === 6 && <ApprovedContract data={data.response} pdf={pdfUrl} />}
    </div>
  );
}

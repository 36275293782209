import OutlinedSearchBar from "@Atom/OutlinedSearchBar";
import StackedProfile from "@Atom/StackedProfile";
import CustomTable from "@Molecule/CustomTable";
import Images from "@Theme/Images";
import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import Styles from "./style.module.scss";
import { useLocation, useNavigate } from "react-router-dom";
import Icon from "@Atom/Icon";
import { getFLBListVerif } from "@Services/hc/flbVerification";
import { Tooltip } from "@mui/material";
import ModalWrapper from "@Atom/ModalWrapper";
import FilterDownloadReport from "@Molecule/_modal/FilterDownloadReport";
moment.locale("id");

export default function FLBVerificationListLayout({
  initialData,
  limit = 0,
  setLimit = () => {},
  page = 1,
  setPage = () => {},

  search,
  setSearch,
  verify = "no",
  setVerify = () => {},
  isFecthingData,
  setIsFecthingData,
}) {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  // const [isFecthingData, setIsFecthingData] = useState(false);
  const [data, setData] = useState(initialData || null);
  const [modalDownloadReport, setModalDownloadReport] = useState(false);
  const [isLoadingDownload, setIsLoadingDownload] = useState(false);

  const refetchDataSync = useCallback(async () => {
    try {
      const previousPage = page;
      setIsFecthingData(true);
      const { response } = await getFLBListVerif(
        limit,
        page,
        search,
        verify === "no"
          ? "Waiting"
          : verify === "yes"
          ? "Active"
          : verify === "expired"
          ? "Expired"
          : "Waiting"
      );
      setData(response);
      setIsFecthingData(false);

      if (response?.totalPage === 1 || response?.totalPage === 0) {
        setPage(1);
      } else {
        const validPage = Math.min(previousPage, response?.totalPage);
        // Mengembalikan msisdnPage ke nilai sebelumnya setelah pencarian selesai
        setPage(validPage);
      }
    } catch (err) {
      setIsFecthingData(false);
      console.log(err);
    }
  }, [limit, page, search, verify, setIsFecthingData, setPage]);

  useEffect(() => {
    refetchDataSync();
  }, [refetchDataSync]);

  const totalPage = useMemo(() => {
    if (data) {
      return data?.totalPage;
    } else {
      return 0;
    }
  }, [data]);

  const totalData = useMemo(() => {
    if (data) {
      return data?.totalData;
    } else {
      return 0;
    }
  }, [data]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getMonthsDifference = (endDate) => {
    const currentDate = new Date();
    const contractEndDate = new Date(endDate);

    const yearsDifference =
      contractEndDate.getFullYear() - currentDate.getFullYear();
    const monthsDifference =
      yearsDifference * 12 +
      (contractEndDate.getMonth() - currentDate.getMonth());

    return monthsDifference;
  };

  const template = useMemo(() => {
    return {
      data: data?.data?.map((obj) => {
        return {
          id: obj?.id,
          docType: obj?.clientContracts?.docType || "-",
          docNumber: obj?.clientContracts?.docNumber,
          clientName: obj?.Client?.name || "-",
          bmName: obj?.businessManager?.name ? obj?.businessManager?.name : "-",
          bmPhoneNumber: obj?.businessManager?.phoneNumber
            ? obj?.businessManager?.phoneNumber
            : "-",
          timestamp: new Date(obj?.createdAt),
          verificationStatus: obj?.isVerified ? "approved" : "rejected",
          endDate: obj?.clientContracts.endDate,
          startDate: obj?.clientContracts.startDate,
          updatedAt: obj?.updatedAt,
          isVerified: obj?.isVerified,
          verifyDate: obj?.verifyDate,
          // isExpiringSoon: checkContractExpiration(obj?.clientContracts.endDate),
          getMonthsDifference: getMonthsDifference(
            obj?.clientContracts.endDate
          ),
          link: obj?.link,
        };
      }),

      columns: [
        {
          name: "docNumber",
          label: "No. Dokumen",
          renderData: (row) => (
            <div className={Styles.docNumber}>
              <span>{`${row?.docType} • ${row?.docNumber}`}</span>
              {row?.getMonthsDifference < 4 &&
              row?.getMonthsDifference >= 0 &&
              verify !== "expired" &&
              !isFecthingData ? (
                <Tooltip
                  title={
                    <span>
                      Kontrak {row?.docType} • {row?.docNumber} akan segera
                      berakhir{" "}
                      {row?.getMonthsDifference === 0
                        ? ""
                        : `${row?.getMonthsDifference} bulan lagi`}{" "}
                    </span>
                  }
                >
                  <div>
                    <Icon
                      icon={"information-solid"}
                      size={20}
                      color={"#D42701"}
                    />
                  </div>
                </Tooltip>
              ) : null}
            </div>
          ),
        },
        {
          name: "clientName",
          label: "Nama Klien",
        },
        {
          name: "bmName",
          label: "BM",
          renderData: (row) => (
            <StackedProfile
              profilePicture={Images.AVA_DEFAULT}
              imgSize="30px"
              title={row?.bmName || "-"}
              label={row?.bmPhoneNumber || "-"}
            />
          ),
        },
      ],
    };
  }, [data?.data, getMonthsDifference, verify, isFecthingData]);

  return (
    <div className={Styles.container}>
      <div className={Styles.header}>
        <div className={Styles.hLeft}>
          {[
            {
              title: "Butuh Verifikasi",
              value: "no",
            },
            {
              title: "Active",
              value: "yes",
            },
            {
              title: "Expired",
              value: "expired",
            },
          ]?.map((each, i) => (
            <button
              key={i}
              className={verify === each?.value ? Styles.active : ""}
              onClick={() => {
                setVerify(each?.value);
                setPage(1);
              }}
            >
              {each?.title}
            </button>
          ))}
        </div>

        <div className={Styles.rightBox}>
          <div className={Styles.hRight}>
            <OutlinedSearchBar
              placeholder={"Cari nomor FLB"}
              value={search}
              setValue={setSearch}
            />
          </div>

          <div
            className={Styles.downloadReportBox}
            onClick={() => setModalDownloadReport(true)}
          >
            <span>Download Report</span>
            <Icon icon={"arrow-down-tray"} size={20} color={"#FFFFFF"} />
          </div>
        </div>
      </div>
      <div className={Styles.content}>
        <CustomTable
          data={isFecthingData ? [] : template?.data || []}
          // minHeight={"100vh"}
          columns={
            verify === "no"
              ? [
                  ...template?.columns,
                  {
                    name: "updateDate",
                    label: "Tanggal & Waktu",
                    renderData: (row) => (
                      <span>
                        {row?.updatedAt
                          ? moment(new Date(row?.updatedAt)).format(
                              "DD MMM YYYY, HH:mm"
                            )
                          : "-"}
                      </span>
                    ),
                  },
                  {
                    name: "timestamp",
                    label: "Masa Aktif",
                    renderData: (row) => (
                      <span>
                        {row?.startDate
                          ? moment(new Date(row?.startDate)).format(
                              "DD MMM YYYY"
                            )
                          : "-"}{" "}
                        -{" "}
                        {row?.endDate
                          ? moment(new Date(row?.endDate)).format("DD MMM YYYY")
                          : "-"}
                      </span>
                    ),
                  },
                  {
                    name: "isVerified",
                    label: "Status",
                    renderData: (row) => (
                      <div
                        className={`${Styles.status} ${
                          row?.isVerified === null && row?.verifyDate === null
                            ? Styles.approved
                            : Styles.update
                        }`}
                      >
                        {row?.isVerified === null && row?.verifyDate === null
                          ? "NEW"
                          : "Update"}
                      </div>
                      // <div className={`${Styles.status} ${row?.verificationStatus !== 'rejected' ? Styles.approved : Styles.rejected}`}>
                      //   {row?.verificationStatus!== 'rejected' ? 'Disetujui' : 'Ditolak'}
                      // </div>
                    ),
                  },
                  {
                    name: "linkDownload",
                    label: "Action",
                    renderData: (row) => (
                      <div
                        className={Styles.iconRow}
                        onClick={() => {
                          if (row?.link) {
                            window.open(row?.link);
                          }
                        }}
                      >
                        <Icon
                          icon={"arrow-down-tray"}
                          size={20}
                          color={row?.link ? "#1571DE" : "#CED4DA"}
                        />
                      </div>
                    ),
                  },
                ]
              : verify === "yes"
              ? [
                  ...template?.columns,
                  {
                    name: "timestamp",
                    label: "Masa Aktif",
                    renderData: (row) => (
                      <span>
                        {row?.startDate
                          ? moment(new Date(row?.startDate)).format(
                              "DD MMM YYYY"
                            )
                          : "-"}{" "}
                        -{" "}
                        {row?.endDate
                          ? moment(new Date(row?.endDate)).format("DD MMM YYYY")
                          : "-"}
                      </span>
                    ),
                  },

                  {
                    name: "linkDownload",
                    label: "Action",
                    renderData: (row) => (
                      <div
                        className={Styles.iconRow}
                        onClick={() => {
                          if (row?.link) {
                            window.open(row?.link);
                          }
                        }}
                      >
                        <Icon
                          icon={"arrow-down-tray"}
                          size={20}
                          color={row?.link ? "#1571DE" : "#CED4DA"}
                        />
                      </div>
                    ),
                  },
                ]
              : [
                  ...template?.columns,
                  {
                    name: "timestamp",
                    label: "Tanggal & Waktu",
                    renderData: (row) => (
                      <span>
                        {row?.startDate
                          ? moment(new Date(row?.startDate)).format(
                              "DD MMM YYYY"
                            )
                          : "-"}{" "}
                        -{" "}
                        {row?.endDate
                          ? moment(new Date(row?.endDate)).format("DD MMM YYYY")
                          : "-"}
                      </span>
                    ),
                  },
                  {
                    name: "linkDownload",
                    label: "Action",
                    renderData: (row) => (
                      <div
                        className={Styles.iconRow}
                        onClick={() => {
                          window.open(row?.link);
                        }}
                      >
                        <Icon
                          icon={"arrow-down-tray"}
                          size={20}
                          color={"#1571DE"}
                        />
                      </div>
                    ),
                  },
                ]
          }
          value={limit}
          setValue={setLimit}
          selectedPage={page}
          setSelectedPage={setPage}
          totalPage={totalPage}
          isV2
          noContainerBorder
          totalData={totalData}
          withNumbering
          isLoading={isFecthingData}
          onClickRow={(row) => {
            navigate(
              `${pathname}/${row?.id}${
                verify === "yes"
                  ? "?v=yes"
                  : verify === "expired"
                  ? "?v=expired"
                  : ""
              }`
            );
          }}
          oddRowClassName={Styles.row}
          evenRowClassName={Styles.row}
          isHCList
          verify={verify}
        />
      </div>

      {modalDownloadReport && (
        <ModalWrapper
          show={modalDownloadReport}
          handleClose={() => {
            if (!isLoadingDownload) {
              setModalDownloadReport(false);
            }
          }}
        >
          <FilterDownloadReport
            handleClose={() => setModalDownloadReport(false)}
            isLoadingDownload={isLoadingDownload}
            setIsLoadingDownload={setIsLoadingDownload}
            type={
              verify === "no"
                ? "Waiting"
                : verify === "yes"
                ? "Active"
                : verify === "expired"
                ? "Expired"
                : "Waiting"
            }
          />
        </ModalWrapper>
      )}
    </div>
  );
}

/* eslint-disable no-unused-vars */

import IMAGES from "@Theme/Images";
import Styles from "./style.module.scss";
import LoginCard from "@Organism/Home/Authorization/Login";
import OTPCard from "@Organism/Home/Authorization/VerifyOTP";
import RegisterCard from "@Organism/Home/Authorization/Register";

export default function ClientLogin() {
  return (
    <div className={Styles.container}>
      <img className={Styles.background} src={IMAGES.AUTH_BG} alt="bg" />
      <LoginCard />
    </div>
  );
}

import { useDebounce } from "@Hooks/useDebounce";
import useQuery from "@Hooks/useQuery";
import BMDashboardLayout from "@Organism/Manager/Dashboard";
import AsyncErrorBoundary from "@Organism/Utils/AsyncErrorBoundary";
import UnderMaintenacePage from "@Organism/Utils/UnderMaintenance";
import { Suspense, useEffect, useState } from "react";
import { Await, useLoaderData, useLocation, useNavigate } from "react-router-dom";
import Styles from "./style.module.scss";

export default function BMDashboardPage({ rbmView = false, underMaintenance = false }) {
  const loadedData = useLoaderData();
  const packageDashboardSync = loadedData?.packageDashboardSync
  const packageBM = loadedData?.packageBM
  const packageClients = loadedData?.packageClients

  const { pathname } = useLocation();
  const navigate = useNavigate()

  const query = useQuery();
  // const name = query.get("n");



  const [value, setValue] = useState(query.get('l') || 10);
  const [pages, setPages] = useState(`1-${value}`);
  const [pageList, setPageList] = useState([]);
  const [selectedPage, setSelectedPage] = useState(query.get('p') || 1);

  const [searchTerm, setSearchTerm] = useState(query.get('n') ||"");
  const debouncedSearchTerm = useDebounce(searchTerm, 300)

  const [value2, setValue2] = useState(query.get('l') || 10);
  const [pages2, setPages2] = useState(`1-${value}`);
  const [pageList2, setPageList2] = useState([]);
  const [selectedPage2, setSelectedPage2] = useState(query.get('p') || 1);

  const [searchTerm2, setSearchTerm2] = useState(query?.get('n2') || "");
  const debouncedSearchTerm2 = useDebounce(searchTerm2, 300)


  useEffect(() => {
    navigate(
      {
        pathname: pathname,
        search: `?p=${selectedPage}&l=${value}&n=${debouncedSearchTerm}&p2=${selectedPage2}&l2=${value2}&n2=${debouncedSearchTerm2}`,
      },
      { replace: true }
    );
  }, [selectedPage, value, pathname, navigate, debouncedSearchTerm, selectedPage2, value2, debouncedSearchTerm2]);

  return (
    <div className={Styles.container}>

      {underMaintenance ? <UnderMaintenacePage /> :
        (!rbmView
          ? <div>bm dashboard</div>
          :
          <Suspense
            fallback={
              <BMDashboardLayout
                rbmView={rbmView}
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                value={value}
                setValue={setValue}
                pages={pages}
                setPages={setPages}
                pageList={pageList}
                setPageList={setPageList}
                selectedPage={selectedPage}
                setSelectedPage={setSelectedPage}
                isLoadingSync
                isLoadingTable
                searchTerm2={searchTerm2}
                setSearchTerm2={setSearchTerm2}
                value2={value2}
                setValue2={setValue2}
                pages2={pages2}
                setPages2={setPages2}
                pageList2={pageList2}
                setPageList2={setPageList2}
                selectedPage2={selectedPage2}
                setSelectedPage2={setSelectedPage2}
                isLoadingTable2
              />
            }
          >
            <Await
              resolve={packageDashboardSync}
              errorElement={
                <div style={{ height: "calc(100vh - 220px)" }}>
                  <AsyncErrorBoundary />
                </div>}
            >
              {(packageDashboardSync) => (
                <Suspense
                  fallback={
                    <BMDashboardLayout
                      rbmView={rbmView}
                      syncData={packageDashboardSync?.response}
                      searchTerm={searchTerm}
                      setSearchTerm={setSearchTerm}
                      value={value}
                      setValue={setValue}
                      pages={pages}
                      setPages={setPages}
                      pageList={pageList}
                      setPageList={setPageList}
                      selectedPage={selectedPage}
                      setSelectedPage={setSelectedPage}
                      isLoadingTable
                      searchTerm2={searchTerm2}
                      setSearchTerm2={setSearchTerm2}
                      value2={value2}
                      setValue2={setValue2}
                      pages2={pages2}
                      setPages2={setPages2}
                      pageList2={pageList2}
                      setPageList2={setPageList2}
                      selectedPage2={selectedPage2}
                      setSelectedPage2={setSelectedPage2}
                      isLoadingTable2
                    />
                  }
                >
                  <Await
                    resolve={packageBM}
                  >
                    {(packageBM) => (
                      <Suspense
                        fallback={<BMDashboardLayout
                          rbmView={rbmView}
                          syncData={packageDashboardSync?.response}
                          data={packageBM?.response?.data}
                          totalPage={packageBM?.response?.totalPage}
                          searchTerm={searchTerm}
                          setSearchTerm={setSearchTerm}
                          value={value}
                          setValue={setValue}
                          pages={pages}
                          setPages={setPages}
                          pageList={pageList}
                          setPageList={setPageList}
                          selectedPage={selectedPage}
                          setSelectedPage={setSelectedPage}
                          searchTerm2={searchTerm2}
                          setSearchTerm2={setSearchTerm2}
                          value2={value2}
                          setValue2={setValue2}
                          pages2={pages2}
                          setPages2={setPages2}
                          pageList2={pageList2}
                          setPageList2={setPageList2}
                          selectedPage2={selectedPage2}
                          setSelectedPage2={setSelectedPage2}
                          isLoadingTable2
                        />}
                      >
                        <Await
                          resolve={packageClients}
                          errorElement={
                            <div style={{ height: "calc(100vh - 220px)" }}>
                              <AsyncErrorBoundary />
                            </div>}            
                        >
                          {(packageClients) => (
                            <BMDashboardLayout
                              rbmView={rbmView}
                              syncData={packageDashboardSync?.response}
                              data={packageBM?.response?.data}
                              totalPage={packageBM?.response?.totalPage}
                              searchTerm={searchTerm}
                              setSearchTerm={setSearchTerm}
                              value={value}
                              setValue={setValue}
                              pages={pages}
                              setPages={setPages}
                              pageList={pageList}
                              setPageList={setPageList}
                              selectedPage={selectedPage}
                              setSelectedPage={setSelectedPage}
                              data2={packageClients?.response?.data}
                              totalPage2={packageClients?.response?.totalPage}
                              searchTerm2={searchTerm2}
                              setSearchTerm2={setSearchTerm2}
                              value2={value2}
                              setValue2={setValue2}
                              pages2={pages2}
                              setPages2={setPages2}
                              pageList2={pageList2}
                              setPageList2={setPageList2}
                              selectedPage2={selectedPage2}
                              setSelectedPage2={setSelectedPage2}
                            />
                          )}


                        </Await>
                      </Suspense>
                    )}
                  </Await>
                </Suspense>
              )
              }

            </Await>
          </Suspense>)
      }
    </div>
  );
}

import Styles from "./toggleButton.module.scss";
import React, { useEffect, useState } from "react";

const ToggleButton = ({ setTrue, defaultTrue }) => {
  const [isTrue, setIstrue] = useState(defaultTrue);

  useEffect(() => {
    setTrue(!isTrue);
  }, [isTrue, setTrue]);

  return (
    <>
      {isTrue ? (
        <div
          onClick={() => setIstrue(false)}
          className={Styles.containerActive}
        >
          <div className={Styles.toggleActive}></div>
        </div>
      ) : (
        <div onClick={() => setIstrue(true)} className={Styles.container}>
          <div className={Styles.toggle}></div>
        </div>
      )}
    </>
  );
};

export default ToggleButton;

/* eslint-disable no-unused-vars */
import React from "react";
import Styles from "./style.module.scss";
import Images from "@Theme/Images";

function isArrayOfString(data) {
  if (Array.isArray(data) && data.length > 0) {
    return data.every((element) => typeof element === "string");
  }
  return false;
}

const policy = [
  {
    title: null,
    body: `Kebijakan Privasi ini(termasuk Syarat dan Ketentuan Kerja365 Loker)memuat dasar-dasar bagi PT Permata Indonesia, sebuah perusahaan yang didirikan di Indonesia,berdomisili di Jakarta Selatan ,dan/atau oleh perusahaan dan platform terafiliasinya ("kami","milik kami",atau "PT PERMATA INDONESIA") untuk mengumpulkan ,menggunakan, memproses, menganalisis, menyimpan, memperbarui, membagi, mengalihkan, mengumumkan, menyajikan, menyebarluaskan, menghapus, memusnahkan, mengungkapkan, melindungi dan/atau melakukan aktivitas-aktivitas lainnya oleh sehubungan dengan(dalam hal relevan)("Memproses")Data Pribadi Anda saat Anda menggunakan Platform kami,sesuai dengan Undang-Undang Nomor 27 Tahun 2022 tentang Pelindungan Data Pribadi, peraturan-peraturan pelaksananya dan setiap peraturan dan undang-undang di Indonesia tentang pelindungan data yang terkait dengannya.Kebijakan privasi ini berlaku untuk Data Pribadi dalam kepemilikan atau kekuasaan kami, termasuk Data Pribadi yang telah kami kumpulkan,gunakan,ungkapkan,atau proses,untuk tujuan kami.
Mohon untuk membaca Kebijakan Privasi Kerja365 Loker ini dengan seksama dan membuat keputusan-keputusan yang sesuai sebelum mengakses, melakukan instalasi, dan/atau menggunakan layanan, produk, aplikasi, dan/atau situs web Kerja365 yang mana pun ("Platform"). Apabila Anda tidak setuju dengan Kebijakan Privasi ini dan Syarat dan Ketentuan kami,mohon untuk tidak menggunakan Platform ini.
Dengan melanjutkan akses, instalasi, dan/atau penggunaan Platform, Anda setuju untuk menjadi dan/atau mendaftar pada Platform dan menjadi pengguna Platform Kerja365 Loker (“Pengguna”). Anda mengakui dan setuju bahwa Anda telah membaca, mengerti, menerima, dan menyetujui Kebijakan Privasi Kerja365 Loker dan Anda setuju bahwa kami Memproses Data Pribadi Anda sebagaimana dimuat dalam Kebijakan Privasi ini. Kecuali didefinisikan lain, semua istilah yang menggunakan huruf kapital dalam Kebijakan Privasi ini memiliki arti yang sama dengan yang ada dalam Syarat dan Ketentuan Kerja365 Loker.
Kami mengerti pentingnya Data Pribadi dan akan melakukan yang terbaik untuk menjaga Data Pribadi Anda secara aman dan terpercaya. Pada saat yang sama, kami akan menggunakan langkah-langkah keamanan yang sesuai untuk menjaga Data Pribadi sesuai dengan standar keamanan yang berlaku dalam industri. Mohon untuk membaca dan mengerti Kebijakan Privasi ini dengan seksama sebelum menggunakan Platform kami.`,
  },
  {
    title: "Pendahuluan Kebijakan Privasi ini",
    body: `1.1. Di Kerja365 Loker ,kami menjaga privasi Anda dengan serius. Kami berkomitmen untuk menjaga kepercayaan Anda pada kami dan untuk mengikuti semua hukum perlindungan data yang berlaku terhadap kami.Selama menyediakan layanan atau memberikan akses atas Platform pada Anda,kami akan Memproses,termasuk Data Pribadi Anda.

1.2. Kebijakan Privasi ini ada agar Anda tahu tentang bagaimana kami Memproses data yang kami kumpulkan dan dapatkan selama menyediakan layanan atau memberikan akses atas Platform pada Anda. Kami hanya akan Memproses Data Pribadi Anda berdasarkan Kebijakan Privasi ini.
    
1.3. Sangat penting bahwa Anda membaca Kebijakan Privasi ini bersamaan dengan pemberitahuan lainnya yang berlaku yang kami sediakan dalam waktu tertentu saat kami Memproses Data Pribadi Anda agar Anda mengetahui penuh bagaimana dan mengapa kami menggunakan Data Pribadi Anda.
    
1.4. DENGAN MENGKLIK ATAU MEMILIH "DAFTAR", "SAYA SETUJU DENGAN KEBIJAKAN PRIVASI KERJA365 LOKER" DAN/ATAU PERNYATAAN SERUPA PADA HALAMAN REGISTRASI KERJA365 LOKER, ANDA MENGAKUI BAHWA ANDA SUDAH DIBERITAHU TENTANG DAN TELAH MENGERTI KETENTUAN KEBIJAKAN PRIVASI INI DAN ANDA TELAH SETUJU ATAS PENGUMPULAN DAN PEMROSESAN DATA YANG DIJELASKAN DALAM KEBIJAKAN INI.
    
1.5. Kebijakan Privasi ini berlaku bersamaan dengan pemberitahuan, ketentuan kontraktual, dan ketentuan persetujuan lain yang ada sehubungan dengan Pemrosesan Data Pribadi Anda oleh kami, dan tidak dimaksud untuk mengesampingkannya kecuali kami menyebutkan lain secara tegas.
    `,
  },
  {
    title: "Data Pribadi yang Kami Kumpulkan dari Anda",
    body: [
      {
        text: "Data Pribadi berarti informasi apa pun ,baik disimpan dalam bentuk materiil atau tidak, yang dapat menunjukkan identitas seorang individu atau dapat secara wajar dan secara langsung dipastikan oleh entitas yang memegang informasi tersebut atau yang saat disatukan dengan informasi yang lain dapat secara langsung dan secara pasti mengidentifikasi seorang individu.",
        type: "string",
        list: [],
      },
      {
        text: "Kami dapat mengumpulkan Data Pribadi seperti berikut dari Anda:",
        type: "list",
        list: [
          `Data identitas, termasuk namun tidak terbatas pada nama ,jenis kelamin, dan tanggal lahir.`,
          `Data kontak, termasuk namun tidak terbatas pada alamat email, dan nomor telepon seluler.`,
          `Data rekening, termasuk namun tidak terbatas pada data bank dan data pembayaran.`,
          `Data teknis, termasuk namun tidak terbatas pada alamat internet protokol(IP), data login, tipe browser internet, serta versinya, pengaturan zona waktu dan lokasi, tipe dan versi browser plug-in, sistem dan platform operasi, dan teknologi lainnya dalam perangkat yang Anda gunakan untuk mengakses Platform.`,
          `Data profil, termasuk namun tidak terbatas pada nomor telepon seluler terdaftar Anda, kode verifikasi, minat, pilihan, masukan, dan jawaban atas survei Anda.`,
          `Penggunaan data, termasuk namun tidak terbatas pada informasi mengenai bagaimana Anda menggunakan Platform, proses perekrutan, dan layanan.`,
          `Data Foto & Video, termasuk namun tidak terbatas pada Data Pribadi Anda yang termasuk dalam video perkenalan komersial perusahaan yang diunggah ke aplikasi Kerja365 Loker, `,
          `video dan Foto perkenalan personal yang Anda unggah dalam halaman Profil, konten audio dan video dari wawancara Anda baik yang diproduksi dan diserahkan oleh Anda sendiri atau yang direkam oleh Kerja365 Loker selama wawancara, dan kontek video dan audio lamaran pekerjaan yang Anda rekam dan unggah pada bagian CV/resume.`,
          `Data lain apapun yang relevan untuk memungkinkan kami untuk memberikan jasa atau akses kepada Platform kepada Anda.`,
        ],
      },
      {
        text: "Selama Anda menggunakan Platform dan penyediaan layanan, kami dapat menerima Data Pribadi Anda dalam keadaan sebagai berikut:",
        type: "list",
        list: [
          "Saat Anda membuat akun bersama kami.",
          "Saat Anda mendaftar untuk Platform kami yang mana pun, mendaftar untuk pekerjaan melalui Platform, dan/atau memposting mengenai lowongan pekerjaan dalam Platform.",
          "Saat Anda menggunakan fitur atau fungsi mana pun yang tersedia dalam Platform atau layanan kami.",
          "Saat Anda berlangganan pada publikasi atau pemasaran tambahan kami.",
          "Saat Anda mengikuti kompetisi, promosi, pelatihan, program, atau survei.",
          "Saat Anda berinteraksi dengan kami secara luring, termasuk saat ada berinteraksi dengan agen pelayanan pelanggan alih daya kami.",
          "Ketika Anda mengunggah konten yang Anda rekam dalam Platform termasuk namun tidak terbatas pada halaman Profil Anda dan pada bagian CV/Resume.",
          "Saat Anda menggunakan alat perekam video untuk menghasilkan materi foto, video beserta konten lainnya, atau menjawab pertanyaan video interview yang diberikan oleh pihak pemberi kerja merilisnya sendiri, atau memberikan kami kewenangan untuk merekam atau mengakses alat perekam video dari perangkat Anda dan merilis konten tersebut. Dengan melakukan hal tersebut, Anda menyetujui bahwa Kerja365 Loker akan Memproses Data Pribadi Anda termasuk namun tidak terbatas pada foto profil dan nama, serta akan menampilkan secara publik konten video yang telah Anda unggah.",
        ],
      },
      {
        text: "Anda hanya diperkenankan untuk mengirimkan Data Pribadi yang akurat dan tidak menyesatkan dan Anda harus menjaganya agar tetap terbaru dan memberitahu kami apabila ada perubahan apapun. Kami memiliki hak untuk meminta dokumentasi untuk memastikan Data Pribadi yang Anda berikan sebagai bagian dari proses verifikasi Pengguna kami.",
        type: "",
        list: [],
      },
      {
        text: "Kami hanya dapat Memproses Data Pribadi Anda apabila Anda dengan sukarela mengirimkan Data Pribadi tersebut pada kami.Apabila Anda memilih untuk tidak mengirimkan Data Pribadi Anda pada kami atau kemudian memilih untuk menarik kembali persetujuan Anda atas penggunaan Data Pribadi oleh kami, kami tidak lagi dapat menyediakan layanan atau akses atas Platform kami pada Anda.",
        type: "",
        list: [],
      },
      {
        text: "Anda boleh mengakses dan memperbaharui Data Pribadi Anda yang telah dikirimkan pada kami kapan pun sebagaimana dijelaskan di bawah ini.Kami memiliki hak untuk meminta dokumentasi lebih lanjut untuk memverifikasi pembaharuan apa pun atas Data Pribadi Anda.",
        type: "",
        list: [],
      },
      {
        text: "Apabila Anda menyediakan Data Pribadi pihak ketiga pada kami, Anda menyatakan dan menjamin bahwa Anda telah mendapatkan persetujuan yang diperlukan dari pihak ketiga tersebut untuk membagikan dan mengalihkan Data Pribadi miliknya pada kami, dan bagi kami untuk Memproses data tersebut berdasarkan Kebijakan Privasi ini. Apabila Anda mendaftar untuk Kerja365 Loker menggunakan akun media sosial Anda atau menghubungkan akun Kerja365 Loker Anda pada akun media sosial Anda atau menggunakan fitur media sosial Kerja365 Loker tertentu, kami dapat mengakses Data Pribadi mengenai Anda yang telah secara sukarela Anda bagikan pada penyelenggara media sosial Anda sesuai dengan kebijakan penyedia tersebut dan kami akan Memproses Data Pribadi Anda sesuai dengan Kebijakan Privasi ini.",
        type: "",
        list: [],
      },
    ],
  },
  {
    title: "Penggunaan dan Pengungkapan Data Pribadi",
    body: [
      {
        text: "Data Pribadi yang kami kumpulkan dari Anda dapat digunakan oleh kami, dibagikan atau dialihkan pada pihak ketiga(termasuk perusahaan terkait, penyelenggara layanan pihak ketiga dan penyelenggara layanan serta perusahaan terkait mereka, dan penjual pihak ketiga)untuk sebagian atau semua dari kegunaan berikut. ",
        type: "list",
        list: [
          "Untuk memfasilitasi penggunaan Anda atas layanan atau akses atas Platform kami, termasuk permintaan, masukan, tuntutan, atau sengketa melalui agen pelayanan pelanggan alih daya kami.",
          "Untuk memberi tahu Anda mengenai proses perekrutan.",
          "Untuk membandingkan Data Pribadi, dan memverifikasi dengan pihak ketiga guna memastikan bahwa Data Pribadi tersebut akurat.",
          "Untuk mengelola akun Anda (jika ada) bersama kami.",
          "Untuk memverifikasi dan menjalankan transaksi finansial sehubungan dengan pembayaran yang Anda lakukan secara daring dan/atau pembayaran gaji.",
          "Untuk melakukan audit atas pengunduhan data dari Platform.",
          "Untuk memperbaiki tata letak atau isi dari halaman Platform dan menyesuaikannya untuk Pengguna.",
          "Untuk mengidentifikasi pengunjung Platform.",
          "Untuk menjalankan riset mengenai demografi dan tindakan Pengguna.",
          "Untuk menyediakan Anda dengan informasi yang menurut kami akan Anda anggap berguna atau yang telah Anda minta dari kami, termasuk informasi mengenai layanan atau lowongan pekerjaan yang telah diposting, dengan catatan Anda telah memberikan indikasi bahwa Anda tidak menolak untuk di kontak guna hal-hal tersebut.",
          "Setelah mendapatkan persetujuan Anda sesuai hukum yang berlaku, kami dapat juga menggunakan Data Pribadi Anda untuk mengirimkan bahan pemasaran atau promosi mengenai kami atau lowongan pekerjaan yang telah diposting dari waktu ke waktu dan Kami dapat juga melakukan pengambilan keputusan otomatis guna hal-hal tersebut di atas.",
        ],
      },
      {
        text: "Anda dapat berhenti berlangganan untuk tidak lagi mendapatkan pemasaran atau informasi mengenai lowongan pekerjaan kapanpun dengan menggunakan fungsi berhenti berlangganan yang tercantum pada bahan pemasaran elektronik kami. Kami dapat menggunakan informasi kontak Anda untuk mengirimkan buletin berkala dari kami dan perusahaan terkait kami.",
        type: "",
        list: [],
      },
      {
        text: "Dalam keadaan luar biasa, kami mungkin akan diwajibkan untuk mengungkapkan Data Pribadi Anda, termasuk namun tidak terbatas pada apabila terdapat alasan untuk meyakini bahwa pengungkapan data tersebut diperlukan untuk mencegah ancaman terhadap nyawa atau kesehatan, atau untuk kegunaan penegakan hukum, atau untuk memenuhi permintaan atau persyaratan dalam ketentuan hukum.",
        type: "",
        list: [],
      },
      {
        text: "Kami mungkin membagikan dan mengizinkan pembagian Data Pribadi Anda pada pihak ketiga dan afiliasi kami, untuk alasan manapun yang disebutkan di atas, termasuk namun tidak terbatas pada, memfasilitasi penggunaan layanan kami oleh Anda, menyelesaikan proses perekrutan, mengelola akun Anda dan hubungan kami dengan Anda, pemasaran, dan memenuhi persyaratan dalam ketentuan hukum sebagaimana dianggap perlu oleh kami. Dalam memberikan Data Pribadi Anda dengan mereka, kami berupaya untuk memastikan bahwa pihak ketiga dan afiliasi kami menjaga Data Pribadi Anda dari akses tidak diizinkan, pengumpulan, penggunaan, pengungkapan, atau resiko lainnya dan menyimpan Data Pribadi Anda hanya selama diperlukan untuk agar Data Pribadi Anda dapat memenuhi kegunaan yang disebutkan di atas.",
        type: "",
        list: [],
      },
      {
        text: "Apabila lokasi Anda berada di Indonesia, kami mungkin membiarkan atau mengizinkan pembagian Data Pribadi Anda ke luar Indonesia untuk kegunaan mana pun yang disebutkan dalam Kebijakan Privasi ini. Dalam mengungkapkan atau mengalihkan, atau mengizinkan pengalihan dari, Data Pribadi Anda pada pihak ketiga atau afiliasi kami yang berada di luar negeri, kami mengambil langkah-langkah untuk memastikan bahwa pihak yang menerima memiliki standar perlindungan atas Data Pribadi yang sebanding dengan standar hukum perlindungan data pribadi yang berlaku terhadap kami.",
        type: "",
        list: [],
      },
    ],
  },
  {
    title: "Bagaimana Kami Mengumpulkan dan Menggunakan Data Pribadi Anda",
    body: [
      {
        text: `Pengguna Terdaftar
Saat Anda menggunakan layanan yang disediakan oleh Kerja365 Loker, Anda pertama-tama harus menjadi Pengguna terdaftar. Saat Anda mendaftar untuk akun Kerja365 Loker, Anda paling tidak harus menyediakan kami dengan informasi mengenai nomor telepon seluler Anda, dan kami akan memverifikasi identitas Anda dengan mengirimkan kode verifikasi One Time Password("OTP").`,
        type: "",
        list: [],
      },
      {
        text: `Otentikasi Pemberi Kerja
Anda harus memenuhi proses otentikasi Pengguna sebelum memposting mengenai lowongan pekerjaan di Kerja365 Loker. Anda harus menyediakan Data Pribadi Anda termasuk namun tidak terbatas pada nama lengkap, jenis kelamin, kewarganegaraan, nomor Kartu Tanda Pengenal, foto, nama organisasi, posisi, dan informasi lainnya yang mungkin dibutuhkan untuk pemberian layanan kami pada Anda. Bergantung pada skenario otentikasi yang sedang terjadi, Anda mungkin harus menyediakan juga paling tidak satu alamat email bisnis, kartu bisnis, bukti pekerjaan, dan salinan terpindai dari izin usaha untuk membuktikan bahwa Anda dapat merekrut atas nama bisnis tersebut. Pada saat pertama melakukan posting lowongan pekerjaan, Anda perlu juga menyediakan izin usaha dan kualifikasi lainnya untuk memverifikasi legalitas bisnis.`,
        type: "",
        list: [],
      },
      {
        text: `Pencari Kerja akan membuat profil daring, tampilan profil, dan mengirimkan profil layanan dan riset.`,
        type: "",
        list: [],
      },
      {
        text: "",
        type: "text",
        list: [
          `Membuat profil
Apabila Anda mendaftar sebagai Pencari Kerja dan menggunakan layanan Kerja365 Loker, Anda perlu membuat profil sendiri. Saat Anda membuat profil, Anda mungkin akan diminta untuk menyediakan informasi yang sewajarnya dibutuhkan oleh Kerja365 Loker termasuk namun tidak terbatas pada nama lengkap, jenis kelamin, tanggal lahir, dan identitas (Anda mungkin juga akan diminta memberikan informasi tambahan termasuk namun tidak terbatas pada nama, posisi, lokasi saat ini, latar belakang pendidikan, serta informasi relevan lainnya yang dibutuhkan agar kami dapat memberikan layanan kami pada Anda). Setelah Anda masuk, Anda dapat lanjut memperbaiki informasi profil Anda. Anda dapat memilih untuk menambah atau mengubah profil Anda dari waktu ke waktu. Anda dapat juga menambah atau memperbaiki filter pencarian kerja Anda, termasuk posisi yang diinginkan, gaji yang diinginkan, industri yang diinginkan, lokasi yang diinginkan, dan kelebihan dasar. Informasi profil lengkap Anda akan membantu perusahaan mengerti informasi Anda dengan lebih cepat, dan akan membantu kami merekomendasi informasi perekrutan yang sesuai untuk Anda, menyediakan rekomendasi layanan dan pengalaman layanan yang lebih baik. Apabila Anda tidak mengisi informasi tambahan yang disebutkan tadi, maka hal tersebut akan berdampak pada fungsi-fungsi dasar Kerja365 Loker.`,
          `Pengiriman profil
Saat Anda mengirim profil Anda, kami akan mengumpulkan data pengiriman Anda agar Anda dapat melihat perkembangan lamaran pekerjaan dan proses seleksi.`,
          `Riset
Anda setuju bahwa kami boleh mengundang Anda untuk mengikuti riset informasi yang terkait dengan layanan sumber daya manusia melalui telepon, SMS, email, atau melalui Platform.`,
        ],
      },
      {
        text: `Otentikasi Pemberi Kerja
Anda harus memenuhi proses otentikasi Pengguna sebelum memposting mengenai lowongan pekerjaan di Kerja365 Loker. Anda harus menyediakan Data Pribadi Anda termasuk namun tidak terbatas pada nama lengkap, jenis kelamin, kewarganegaraan, nomor Kartu Tanda Pengenal, foto, nama organisasi, posisi, dan informasi lainnya yang mungkin dibutuhkan untuk pemberian layanan kami pada Anda. Bergantung pada skenario otentikasi yang sedang terjadi, Anda mungkin harus menyediakan juga paling tidak satu alamat email bisnis, kartu bisnis, bukti pekerjaan, dan salinan terpindai dari izin usaha untuk membuktikan bahwa Anda dapat merekrut atas nama bisnis tersebut. Pada saat pertama melakukan posting lowongan pekerjaan, Anda perlu juga menyediakan izin usaha dan kualifikasi lainnya untuk memverifikasi legalitas bisnis.`,
        type: "",
        list: [],
      },
      {
        text: `Rekomendasi
Guna meningkatkan efisiensi dan angka kesuksesan pencarian kerja atau perekrutan pekerja, kami mungkin akan mencocokkan informasi perekrutan dan informasi pencarian kerja berdasarkan persetujuan Anda, dan menyarankan lowongan pekerjaan pada Pencari Kerja.`,
        type: "",
        list: [],
      },
      {
        text: `Pengecualian pada otorisasi
Dalam situasi sebagai berikut, kami dapat mengumpulkan dan menggunakan Data Pribadi yang dibutuhkan tanpa otorisasi Anda:`,
        type: "list",
        list: [
          "Berhubungan secara langsung dengan keamanan dan pertahanan nasional.",
          "Untuk mendeteksi dan mencari tahu mengenai tindakan yang dilarang, ilegal, atau curang.",
          "Berkaitan langsung dengan keamanan publik, kesehatan publik, dan kepentingan utama publik.",
          "Berkaitan langsung dengan investigasi kriminal, penuntutan, pengadilan, dan pelaksanaan putusan.",
          "Untuk tujuan menjaga hidup dan properti pemilik Data Pribadi atau individu lain, namun sulit mendapatkan persetujuan orang tersebut.",
          "Data Pribadi yang didapatkan diungkapkan pada publik oleh diri Anda sendiri.",
          "Mengumpulkan Data Pribadi dari Data Pribadi yang telah diungkapkan pada publik secara legal, termasuk berita legal, pengungkapan informasi pemerintahan, dan sarana lainnya.",
          "Diperlukan untuk menandatangani dan menjalankan kontrak sesuai kebutuhan Anda.",
          "Dibutuhkan untuk mempertahankan operasi aman dan stabil dari produk dan layanan yang disediakan, termasuk namun tidak terbatas pada mendeteksi dan membuang produk atau layanan yang telah gagal.",
          "Keadaan lainnya yang diatur dalam peraturan dan perundang-undangan.",
        ],
      },
    ],
  },
  {
    title: `Bagaimana Kami Mempercayakan Pemrosesan, Pembagian, Pengalihan, dan Pengungkapan pada Publik atas Data Pribadi Anda`,
    body: [
      {
        text: "Pemrosesan yang dipercayakan",
        type: "text",
        list: [
          "Beberapa modul atau fungsi tertentu dari fungsi bisnis Kerja365 Loker disediakan oleh pemasok eksternal. Contohnya, kami mungkin akan menggunakan pemberi layanan untuk membantu kami memberi pelayanan pelanggan. Kami akan membuat perjanjian kerahasiaan dengan perusahaan, organisasi, dan individu yang kami percaya untuk memproses Data Pribadi, yang mewajibkan mereka untuk memproses Data Pribadi sesuai dengan persyaratan kami, Kebijakan Privasi ini, dan langkah kerahasiaan serta keamanan yang relevan lainnya.",
          "Pertama, pemberi layanan dasar, kami memiliki akses atas pemberi layanan dasar pihak ketiga yang diperlukan untuk operasi Platform yang aman dan untuk terjalankannya fungsi bisnis, mengoptimalisasi produk dan/atau layanan yang kami sediakan, dan untuk meningkatkan pengalaman Anda. Otentikasi layanan Pemberi Kerja, saat Anda mendaftar sebagai Pencari Kerja di Kerja365 Loker, Anda setuju dan mengizinkan untuk menyediakan informasi wajah secara 3D pada pemberi layanan otentikasi yang diakses oleh Kerja365 Loker untuk menyelesaikan otentikasi yang dibutuhkan. seperti analisa foto, saat Anda ada dalam Platform Kerja365 Loker. Saat sedang aktif mengunggah foto, Anda setuju dan mengizinkan Kerja365 Loker untuk memberikan foto tersebut pada pemberi layanan informasi yang terkait.",
          "Kedua, pemberi layanan dengan nilai tambahan.Guna menyediakan Anda dengan lowongan pekerjaan dan produk dan/atau layanan perekrutan yang bervariasi,kami memiliki akses atas sejumlah pemberi layanan nilai tambah pihak ketiga yang profesional.Ketika produk dan/atau layanan dengan nilai tambahan yang Anda pilih disediakan oleh pemberi layanan nilai tambah pihak ketiga,kami akan menyediakan kepada pemberi layanan nilai tambah pihak ketiga informasi yang dibutuhkan untuk memenuhi produk dan/atau layanan dengan nilai tambahan yang Anda beli setelah Anda telah mengizinkannya secara terpisah.",
          "Meskipun kami akan menjaga Data Pribadi Anda dengan sebaik mungkin, dalam hal terjadinya pelanggaran data, kami akan menyediakan Anda dengan pemberitahuan tertulis secara elektronik segera saat memungkinkan setelah pelanggaran tersebut kami ketahui.",
        ],
      },
      {
        text: "Pembagian Data Pribadi",
        type: "text",
        list: [
          "Kami akan menjaga kerahasiaan Data Pribadi Anda.Kami tidak akan membagikan Data Pribadi Anda pada perusahaan organisasi, atau individu lain, kecuali dalam keadaan sebagai berikut:",
        ],
      },
      {
        text: "",
        type: "list",
        list: [
          "Telah terlebih dahulu mendapatkan izin atau persetujuan Anda.",
          "Sesuai dengan persyaratan peraturan, perundang-undangan, dan prosedur hukum yang berlaku.",
          "Merupakan persyaratan wajib dari badan administratif atau yudikatif.",
          "Perlu untuk menyediakan Data Pribadi Anda pada pihak ketiga untuk menjaga hidup, properti, atau kepentingan publik dari pemilik Data Pribadi atau individu lain dari bahaya.",
          "Membagikan pada perusahaan terafiliasi kami. Data Pribadi Anda mungkin akan dibagikan antara perusahaan terafiliasi kami. Kami hanya akan membagikan Data Pribadi yang perlu dibagikan, dan pembagian seperti ini tunduk pada Kebijakan Privasi ini. Apabila perusahaan terafiliasi tersebut ingin merubah kegunaan pemrosesan Data Pribadi, ia akan meminta persetujuan Anda lagi.",
        ],
      },
    ],
  },
  {
    title: `Penyimpanan Data Pribadi`,
    body: [
      {
        text: "Kami akan menyimpan Data Pribadi Anda selama dibutuhkan untuk memenuhi maksud dari pengumpulannya, dan/atau selama paling tidak 5 (lima) tahun atau jangka waktu penyimpanan lainnya yang dibutuhkan dan diizinkan oleh peraturan dan perundang-undangan yang berlaku di Indonesia.",
        type: "",
        list: [],
      },
      {
        text: "Kami akan berhenti menyimpan Data Pribadi Anda, atau mencabut sarana dari mana data tersebut dapat dikaitkan dengan Anda, segera saat wajar untuk menduga bahwa penyimpanan data tersebut tak lagi memenuhi maksud dari pengumpulan Data Pribadi, dan tidak lagi diperlukan untuk kegunaan hukum atau bisnis.",
        type: "",
        list: [],
      },
      {
        text: "Dalam hal Anda ingin data Anda dihapuskan dari basis data Kerja365 Loker, dan/atau bagi kami untuk berhenti menggunakan dan/atau mengungkapkan Data Pribadi Anda untuk alasan apapun, Anda dapat mengirimkan permintaan tertulis pada kontak kami di bawah.",
        type: "list",
        list: [
          "Setelah mendapatkan permintaan Anda, kami mungkin akan membutuhkan waktu untuk memproses permintaan tersebut serta untuk memberitahu Anda mengenai konsekuensi dari penghapusan data, termasuk bagaimana hal tersebut dapat berdampak pada hak dan tanggung jawab Anda terhadap kami. Umumnya, kami akan memproses permintaan Anda dalam 3x24 (tiga kali dua puluh empat) jam sejak menerimanya.",
          "Mohon mencatat bahwa menghapus data Anda dari basis data kami dapat menyebabkan kami untuk tidak lagi dapat menyediakan Anda dengan layanan kami, dan bahwa permintaan penghapusan data Anda tidak akan berdampak pada hak kami untuk mengungkapkan Data Pribadi Anda sesuai dengan peraturan dan perundang-undangan yang berlaku di Indonesia.",
        ],
      },
    ],
  },
  {
    title: `Pertanyaan Mengenai Kebijakan Privasi Ini`,
    body: [
      {
        text: `Apabila Anda miliki pertanyaan, masukan, atau komplain sehubungan dengan Data Pribadi Anda, mohon untuk menghubungi Pengaduan Pelanggan kami lewat email atau dalam pesan aplikasi.
Selebihnya, jika Anda memiliki pertanyaan mengenai Kebijakan Privasi ini, ingin mendapatkan akses dan/atau melakukan perbaikan atas Data Pribadi Anda, atau ingin meminta penghapusan data, mohon menghubungi kami melalui email di permataindonesiagroup@gmail.id.`,
        type: "",
        list: [],
      },
    ],
  },
];

export default function PrivacyPolicy() {
  return (
    <div className={Styles.container}>
      <div className={Styles.headWrapper}>
        <div className={Styles.header}>
          <div className={Styles.logoWrapper}>
            <div>
              <img src={Images?.INITIAL_LOGO} alt="logo" />
            </div>
            <span>PT Permata Indonesia Sejahtera</span>
          </div>
          <div className={Styles.watermarkWrapper}>
            <p>Powered by</p>
            <img src={Images.KERJA365_LIGHT} alt="watermark" />
          </div>
        </div>
      </div>
      <div className={Styles.body}>
        <p>Kebijakan Perlindungan Data Pribadi ("Kebijakan Privasi")</p>
        <div className={Styles.textWrapper}>
          {policy.map((el, idx) => (
            <div className={Styles.paragraph} key={idx}>
              {el.title && (
                <p className={Styles.bodyTitle}>
                  {idx}. {el.title}
                </p>
              )}

              <div className={`${Styles.textBodyWrapper}`}>
                {Array.isArray(el.body) ? (
                  el.body.map((elBody, idxBody) => (
                    <div className={Styles.list}>
                      {elBody.text && (
                        <p className={Styles.textBody} key={idxBody}>
                          {idx}.{idxBody + 1} {elBody.text}
                        </p>
                      )}
                      {elBody.type === "list" && (
                        <ul>
                          {elBody.list.map((el, idx) => (
                            <li key={idx}>
                              <p className={Styles.textBody}>{el}</p>
                            </li>
                          ))}
                        </ul>
                      )}
                      {elBody.type === "text" &&
                        elBody.list.map((el, idxList) => (
                          <div className={Styles.bodyList}>
                            <p className={Styles.textBody}>
                              {idx}.{idxBody + 1}.{idxList + 1} {el}
                            </p>
                          </div>
                        ))}
                    </div>
                  ))
                ) : (
                  <p className={`${Styles.textBody}`}>{el.body}</p>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

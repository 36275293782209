/* eslint-disable no-unused-vars */
import HomeAboutContainerLayout from "@Organism/Home/Business/HomeAboutContainer";
import HomeFooter from "@Organism/Home/Business/HomeFooter";
import HomeOpeningBannerLayout from "@Organism/Home/Business/HomeOpeningBanner";
import HomePersonalAdContainerLayout from "@Organism/Home/Business/HomePersonalAdContainer";
import HomeProductContainerLayout from "@Organism/Home/Business/HomeProductContainer";
import { useEffect, useRef } from "react";
import Styles from "./style.module.scss";

export default function BusinessHomePage() {
  const simulatorRef = useRef(null);
  const aboutRef = useRef(null);

  const targetElement = useRef();
  const scrollingTop = (event) => {
    const elmnt = targetElement;
    elmnt.current.scrollIntoView({
      behavior: "auto",
      block: "center",
      inline: "start",
    });
  };
  useEffect(() => {
    scrollingTop();
  }, []);

  return (
    <div className={Styles.main}>
      <div className={Styles.autoScroll} ref={targetElement} />
      <HomeOpeningBannerLayout />
      {/* <HomeSimulatorContainerLayout simulatorRef={simulatorRef} /> */}
      <HomeProductContainerLayout />
      <HomeAboutContainerLayout aboutRef={aboutRef} />
      <HomePersonalAdContainerLayout />
      <HomeFooter />
    </div>
  );
}

import { fileBaseUrl } from '@Config/api';
import useQuery from '@Hooks/useQuery';
import Images from '@Theme/Images';
import { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import Styles from './style.module.scss';

export default function DocView() {
  const query = useQuery()
  const url = fileBaseUrl + query.get('u')

  const [isDownloading, setIsDownloading] = useState(false)

  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  });

  const downloadFile = (
    filePath,
    fileName = `${query.get('u') || 'Document_Preview'}.pdf`,
  ) => {
    setIsDownloading(true)
    fetch(filePath, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/pdf',
      },
    })
      .then(response => response.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(new Blob([blob]));

        const link = document.createElement('a');
        link.href = url;
        link.download = fileName;

        document.body.appendChild(link);

        link.click();

        link.parentNode.removeChild(link);
      })
      .then(() => setIsDownloading(false))
  };


  return (
    <div className={`${Styles.container} ${window[0] < 768 || isMobile ? Styles.mobile : ''}`}>
      {(windowSize[0] < 768 || isMobile) ? (
        <div className={Styles.mobileContainer}>
          <img src={Images.AUTH_BG} alt="bg" />
          <img src={Images.KERJA365_LIGHT} alt="logo" />
          <h3>Document Preview</h3>
          {/* <span className={Styles.SubHeader}>Download Page</span> */}
          <span>Download the file first to view it on mobile</span>
          <button onClick={() => downloadFile(url)} disabled={isDownloading}>
            <span>{!isDownloading ? 'Download document' : 'Downloading document...'}</span>
          </button>

        </div>


      ) : (
        // <PDFReader url={url} showAllPage />
        <iframe src={url}
          title="doc"
          width="100%"
          height="100%"
        />
        // <ReactPDF
        //   file={{
        //     url: url
        //   }}
        // />
      )}
    </div>
  )
}
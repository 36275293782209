import InputField from '@Molecule/InputField'
import Styles from './style.module.scss'

export default function First({
  email, 
  setEmail
}) {

  return (
    <div className={Styles.container}>
      <InputField
      title="Email"
      value={email}
      onChange={(e)=> setEmail(e?.target.value)}
      placeholder={"example@mail.com"}
      />
    </div>
  )
}
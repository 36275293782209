import PrivacyPolicy from '@Organism/Additional/PrivacyPolicy'
import React from 'react'

export default function PrivacyPolicyPage() {
  return (
    <div>
        <PrivacyPolicy/>
    </div>
  )
}

import ErrorBubble from "@Atom/ErrorBubble";
import Styles from "./inputPhoneNumber.module.scss";
import { useState } from "react";

const InputPhoneNumber = ({
  placeholder,
  id,
  value,
  onChange,
  disabled,
  isError = false,
  isErrorV2 = false,
  readOnly = false,
  errorText = "",
  ...props
}) => {
  const [onFocus, setOnFocus] = useState(false)
  return (
    <div className={Styles.wrapper}>
      <div className={`${Styles.container} ${isError && !onFocus ? Styles.error : ""}`}>
        <div className={Styles.phoneCodeWrapper}>+62</div>
        <input
          className={Styles.input}
          // type="number"
          type="text" pattern="\d*"
          onKeyDown={(event) => {
            if (
              event?.key === "." ||
              event?.key === "-" ||
              event?.key === "e" ||
              event?.key === "=" ||
              (!value?.length && event?.key !== "8") 

            ) {
              event?.preventDefault();
            }
          }}
          onWheel={(e) => e.target.blur()}
          id={id || "fname"}
          name="fname"
          value={value || ""}
          onChange={onChange}
          onFocus={() => setOnFocus(true)}
          onBlur={() => setOnFocus(false)}
          placeholder={placeholder}
          disabled={disabled}
          maxLength={13}
        />
      </div>
      {isError && !disabled && !readOnly && !onFocus && (
        !isErrorV2
          ?
          <span className={Styles.errorText}>{errorText}</span>
          :
          <div>
            <ErrorBubble
              errorText={errorText}
            />
          </div>
      )}
    </div>

  );
};

export default InputPhoneNumber;

import { API } from "@Config/api";

export function getBMList(page = 1, limit = 10, search = 1) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(`/rbm/bm`, {
        headers: {
          // Authorization: sessionStorage.getItem('accessToken'),
          Authorization: localStorage.getItem("accessToken"),
        },
        params: {
          page, limit, search
        }
      });

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function getBMClients(id = '', page = 1, limit = 10, search = 1, clientType = 'OWNER') {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(`/rbm/clients/${id}`, {
        headers: {
          // Authorization: sessionStorage.getItem('accessToken'),
          Authorization: localStorage.getItem("accessToken"),
        },
        params: {
          page, limit, search, clientType
        }
      });

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}
export function getRBMClients(page = 1, limit = 10, search = 1) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(`/rbm/clients`, {
        headers: {
          // Authorization: sessionStorage.getItem('accessToken'),
          Authorization: localStorage.getItem("accessToken"),
        },
        params: {
          page, limit, search
        }
      });

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}
export function getBMDashboardSync(bmID = '', month = '', year = '', bmForChart = []) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(`/rbm/dashboard-sync`, {
        headers: {
          // Authorization: sessionStorage.getItem('accessToken'),
          Authorization: localStorage.getItem("accessToken"),
        },
        params: { bmID, month, year, bmForChart: JSON.stringify(bmForChart?.map(obj => +obj)) }
      });

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function downloadBM() {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(`/rbm/download-bm`, {
        headers: {
          Authorization: localStorage.getItem("accessToken"),
        },
        responseType: 'arraybuffer'
      });

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}
export function downloadClients() {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(`/rbm/download-clients`, {
        headers: {
          Authorization: localStorage.getItem("accessToken"),
        },
        responseType: 'arraybuffer'
      });

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function getFLBLogs(schemeID = '', page = 1, limit = 10) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.get(`/order/log`, {
        headers: {
          // Authorization: sessionStorage.getItem('accessToken'),
          Authorization: localStorage.getItem("accessToken"),
        },
        params: {
          schemeID, page, limit
        }
      });

      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}
import Icon from "@Atom/Icon";
import Images from "../../../../../theme/Images";
import Styles from "./Styles.module.scss";

export default function HomeOpeningBannerLayout() {
  return (
    <div className={Styles.openingBanner}>
      <div className={Styles.banner}>
        <div className={Styles.info}>
          <div className={Styles.wrapper}>
            <span className={Styles.title}>
              Temukan Pekerja Yang Sesuai Dengan Kebutuhan Anda
            </span>
          </div>
          <div className={Styles.wrapper}>
            <span className={Styles.content}>
              Temukan kemudahan dengan menggunakanan layanan kami di dukung
              dengan tenaga profesional di bidangnya
            </span>
          </div>
          <div className={Styles.wrapper}>
            <ul className={Styles.listGroup}>
              <li>
                <Icon icon="star-check" size={"30px"} />
                <span>Layanan service lengkap</span>
              </li>
              <li>
                <Icon icon="star-check" size={"30px"} />
                <span>Tenaga profesional di bidangnya</span>
              </li>
              <li>
                <Icon icon="star-check" size={"30px"} />
                <span>Layanan maksimal pada jam kerja</span>
              </li>
            </ul>
          </div>
        </div>
        <div className={Styles.imageWrapper}>
          <img
            className={Styles.image}
            src={Images.OPENING_BANNER_ILLUSTRATION}
            alt="OPENING_BANNER_ILLUSTRATION"
          />
        </div>
      </div>
    </div>
  );
}

import { useCallback, useEffect, useMemo, useState } from 'react'
import First from './First'
import Second from './Second'
import Third from './Third'
import Styles from './style.module.scss'
import Images from '@Theme/Images'
import Icon from '@Atom/Icon'
import { useLocation, useNavigate } from 'react-router-dom'
import Stepper from '@Molecule/Stepper'
import useQuery from '@Hooks/useQuery'
import { forgotPasswordStep1, forgotPasswordStep2, forgotPasswordStep3 } from '@Services/client/auth'
import DoneModal from '@Molecule/_modal/DoneModal'

export default function ForgotPasswordCard({
  currentStep,
  setCurrentStep,
  steps,
  setSteps
}) {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const [email, setEmail] = useState(null)
  const [otp, setOtp] = useState(null)
  const [password, setPassword] = useState(null)
  const [passwordConfirmation, setPasswordConfirmation] = useState(null)

  const [submitting, setSubmitting] = useState(false)

  const [isDone, setIsDone] = useState(false);


  const query = useQuery()
  const hash = query.get('h')
  const id = query.get('i')

  useEffect(() => {
    if (hash) {
      if (!id) {
        setCurrentStep(2)
      } else {
        setCurrentStep(3)
      }
    } else {
      setCurrentStep(1)
    }
  }, [hash, id, setCurrentStep])

  const [error, setError] = useState(null)

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setError(null);
      }, 5000);
    }
  }, [error]);

  const handleClickBack = useCallback(() => {
    navigate(-1)
  }, [navigate])


  const activeStep = useMemo(() => {
    switch (currentStep) {
      case 1:
        return {
          stepLogo: <img src={Images.LOCK_1} alt="logo" />,
          introTitle: 'Konfirmasi',
          introDesc: <p>Masukan email anda untuk konfirmasi selanjutnya</p>,
          content:
            <First
              email={email}
              setEmail={setEmail}
            />,
          buttonText: submitting ? 'Mengirim...' : 'Kirim',
          buttonDisabled: !email ? true : false
        }
      case 2:
        return {
          stepLogo: <img src={Images.LOCK_2} alt="logo" />,
          introTitle: 'OTP Konfirmasi',
          introDesc: <p>Kami telah mengirim kode verifikasi ke email <span>{email}</span>, mohon periksa dan masukan kode di kolom berikut</p>,
          content:
            <Second
              email={email}
              otp={otp}
              setOtp={setOtp}
            />,
          buttonText: submitting ? 'Mengonfirmasi...' : 'Konfirmasi',
          buttonDisabled: !otp ? true : false
        }
      case 3:
        return {
          stepLogo: <img src={Images.LOCK_3} alt="logo" />,
          introTitle: 'Kata Sandi Baru',
          introDesc: <p>Masukan kata sandi baru anda, pastikan anda mengingat kata sandi anda</p>,
          content:
            <Third
              password={password}
              setPassword={setPassword}
              passwordConfirmation={passwordConfirmation}
              setPasswordConfirmation={setPasswordConfirmation}
            />,
          buttonText: submitting ? 'Menyimpan...' : 'Simpan',
          buttonDisabled: (!password || !passwordConfirmation) ? true : false
        }
      default:
        return {
          stepLogo: <img src={Images.LOCK_1} alt="logo" />,
          introTitle: 'Konfirmasi',
          introDesc: <p>Masukan email anda untuk konfirmasi selanjutnya</p>,
          content:
            <First
              email={email}
              setEmail={setEmail}
            />,
          buttonText: submitting ? 'Mengirim...' : 'Kirim',
          buttonDisabled: !email ? true : false
        }
    }
  }, [currentStep, email, otp, password, passwordConfirmation, submitting])

  const handleSubmit = useCallback(async (e) => {
    e.preventDefault()
    setSubmitting(true)
    if (currentStep === 1) {
      try {
        const { response } = await forgotPasswordStep1({
          email: email,
        })
        setSubmitting(false)
        navigate(`${pathname}?h=${response?.hash}`)
      } catch (err) {
        setSubmitting(false)
        if (err?.response?.data?.error?.toLowerCase()?.includes('invalid email')) {
          setError('Invalid email')
        } else {
          setError(err?.response?.data?.error)
        }
      }
    } else if (currentStep === 2) {
      try {
        const { response } = await forgotPasswordStep2({
          otp: otp,
        }, hash)
        setSubmitting(false)
        navigate(`${pathname}?h=${hash}&i=${response?.id}`)
      } catch (err) {
        setSubmitting(false)
        if (err?.response?.data?.error?.toLowerCase()?.includes('wrong code')) {
          setError('Wrong OTP input')
        } else {
          setError(err?.response?.data?.error)
        }
      }
    } else {
      try {
        await forgotPasswordStep3({
          password: password,
          password2: passwordConfirmation
        }, id)
        setSubmitting(false)
        setIsDone(true)
      } catch (err) {
        setSubmitting(false)
        if (err?.response?.data?.error?.toLowerCase()?.includes('password does not match')) {
          setError('Passwords do not match')
        } else {
          setError(err?.response?.data?.error)
        }
      }
    }
  }, [
    currentStep,
    navigate,
    pathname,
    email,
    hash,
    otp,
    password,
    passwordConfirmation,
    id
  ])


  return (
    <div className={Styles.container}>
      <div className={Styles.header}>
        <img src={Images.KERJA365_LIGHT} alt="logo" />
        <button onClick={handleClickBack}>
          <Icon icon="arrow-left" size={20} />
          <span>Kembali</span>
        </button>
        <div className={Styles.subHeader}>
          <h3>Lupa Kata Sandi</h3>
          <div className={Styles.stepperWrapper}>
            <Stepper
              type="filled"
              onClick={() => { }}
              options={steps}
              currentStep={currentStep}
            />
          </div>
          <div className={Styles.stepLogo}>
            {activeStep?.stepLogo}
          </div>
          <div className={Styles.intro}>
            <h4>{activeStep?.introTitle}</h4>
            {activeStep?.introDesc}
          </div>

        </div>
      </div>
      <div className={Styles.main}>
        <form onSubmit={handleSubmit}>
          <div className={Styles.contentWrapper}>
            {activeStep?.content}
          </div>
          <div className={`${Styles.errorWrapper} ${error ? Styles.error : ""}`}>
            <p>{error ? error : "error placeholder"}</p>
          </div>
          <div className={Styles.buttonWrapper}>
            <button type="submit" disabled={activeStep?.buttonDisabled || submitting}>{activeStep?.buttonText}</button>
          </div>
        </form>
      </div>
      {isDone && (
        <DoneModal
          title="Sukses"
          description={
            <span className={Styles.modalDesc}>
              Password berhasil diganti
            </span>
          }
          doneButton={true}
          doneTitle={"Ke Login"}
          handleDone={() => {
            setIsDone(false);
            navigate("/login");
          }}
        />
      )}
    </div>
  )
}

import Icon from '@Atom/Icon'
import StaticProgressBar from '@Atom/StaticProgressBar'
import UseOutsideClick from '@Hooks/useOutsideClick'
import useWindowSize from '@Hooks/useWindowSize'
import AddPositionLocationModal from '@Molecule/_modal/AddPositionLocationModal'
import RequestTKModal from '@Molecule/_modal/RequestTKModal'
import SeeQualificationsModal from '@Molecule/_modal/SeeQualificationsModal'
import UpdateVacantQuotaModal from '@Molecule/_modal/UpdateVacantQuotaModal'
import Images from '@Theme/Images'
import moment from 'moment'
import { useEffect, useMemo, useRef, useState } from 'react'
import Styles from './style.module.scss'
import { useLocation, useNavigate } from 'react-router-dom'
import { makeRandomString } from '@Helpers/makeRandomString'
import ClientDashboardLogModal from '@Molecule/_modal/ClientDashboardLogModal'
import Tooltip from '@Atom/Tooltip'

export default function NewDashboardLayout({
  isLoading = false,
  progressBar = null,
  summaries = [],
  sync = null
}) {
  const [showRequest, setShowRequest] = useState(false)
  const [showLog, setShowLog] = useState(false)

  const { width } = useWindowSize()

  const dataPositions = useMemo(() => {
    return summaries?.map(obj => {
      return {
        id: obj?.positionID || (obj?.datas?.length ? obj?.datas[0]?.positionID : obj?.name),
        positionName: obj?.position,
        quota: obj?.totalQuota,
        fulfilled: obj?.totalFulfillment,
        vendor: obj?.totalVendor || obj?.totalQuota - obj?.totalVacant,
        vacant: obj?.totalVacant || 0,
        regions: obj?.datas?.map(d => {
          return {
            id: d?.id,
            positionID: d?.positionID,
            cityName: d?.City?.name,
            cityCode: d?.City?.code,
            updatedAt: new Date(d?.updatedAt),
            request: d?.quota,
            fulfilled: d?.fulfillment,
            vendor: d?.vendor,
            vacant: d?.vacant,
            qualifications: []
          }
        })
      }
    })
  }, [summaries])


  return (
    <div className={Styles.container}>
      <div className={Styles.header}>
        <img src={Images.CLIENT_HEADER} alt="building" />
        <div className={Styles.headerItems}>
          <h2>Dashboard Pemenuhan Tenaga Kerja <span>Outsourcing</span></h2>
          <div className={Styles.hActions}>
            {
              dataPositions?.length
              &&
              <button disabled className={Styles.add} onClick={() => setShowRequest(true)}>
                <span>Tambah Posisi Baru</span>
                <Icon icon={'add-circle'} size={20} />
              </button>
            }
            <button className={Styles.log} onClick={() => setShowLog(true)}>
              <Icon icon={'clock-back'} size={24} />
            </button>
          </div>
        </div>
      </div>
      <div className={Styles.content}>
        <div className={`${Styles.section} ${Styles.quotaFulfillment}`}>
          <div className={Styles.qFHeader}>
            <h3>Pemenuhan Kuota</h3>
            <div className={Styles.indicators}>
              {
                [
                  {
                    title: 'Jumlah Terpenuhi',
                    bgColor: '#1571DE',
                  },
                  {
                    title: 'Vendor',
                    bgColor: '#91BDF1',
                    fontStyle: 'italic',
                  },
                  {
                    title: 'Vacant',
                    bgColor: '#EDEDED',
                    fontStyle: 'italic',
                  }
                ]
                  ?.map((x, xI) => (
                    <div key={xI} className={Styles.indicator}>
                      <div style={{ backgroundColor: x?.bgColor }} />
                      <span style={{ fontStyle: x?.fontStyle || 'normal' }}>{x?.title}</span>
                    </div>
                  ))
              }
            </div>

          </div>

          <Tooltip
            content={
              <div className={Styles.tooltip}>
                <p>
                  <span>Jumlah Terpenuhi</span>
                  <span>:</span>
                  <span>{`${progressBar?.fulfillment} (${Math.floor(progressBar?.fulfillment / progressBar?.quota * 100)}%)`}</span>
                </p>
                <p>
                  <span>Vendor Lain</span>
                  <span>:</span>
                  <span>{progressBar?.vendor}</span>
                </p>
                <p>
                  <span>Vacant</span>
                  <span>:</span>
                  <span>{progressBar?.vacant}</span>
                </p>
              </div>
            }
            direction='top'
            backgroundColor='#fff'
            boxShadow='0px 6px 24px 0px rgba(0, 0, 0, 0.15)'
            tooltipMargin='75px'
            isV2
          >
            <StaticProgressBar
              fulfilledAmount={progressBar?.fulfillment || 0}
              middleFulfilledText={`${Math.floor(progressBar?.fulfillment / progressBar?.quota * 100)}%`}
              // fulfilledText={`${progressBar?.fulfillment || 0} (${Math.floor(+progressBar?.fulfillment / +progressBar?.quota * 100)}%)`}
              candidatesAmount={progressBar?.fulfillment + progressBar?.vendor}
              candidatesText={progressBar?.vendor}
              requestAmount={progressBar?.quota || 0}
              vacantAmount={progressBar?.vacant || 0}
              isV3
              borderRadius='0px'
              height={width > 768 ? '32px' : '28px'}
            />
          </Tooltip>
        </div>
        {dataPositions?.length
          ?
          <div className={Styles.positions}>

            {dataPositions?.map((d, dI) => (
              <PositionCard
                key={dI}
                d={d}
                sync={sync}
              />
            ))}
          </div>

          :
          <div className={Styles.empty}>
            <img src={Images.NO_DATA} alt='' />
            <span>Data Tidak Tersedia</span>
            <p>Untuk saat ini kamu belum memiliki order Tenaga Kerja.
              Ayo lakukan request tenaga kerja sekarang.</p>
            <button disabled onClick={() => setShowRequest(true)}>
              <span>Tambah Posisi Baru</span>
              <Icon icon={'add-circle'} size={20} />
            </button>
          </div>
        }
      </div>
      {
        showRequest
        &&
        <RequestTKModal
          show={showRequest}
          onClose={() => setShowRequest(false)}
          sync={sync}
          dataPositions={dataPositions}
        />
      }
      <ClientDashboardLogModal
        show={showLog
        }
        onClose={() => setShowLog(false)}
        dataPositions={dataPositions}
      />
    </div>
  )
}

const PositionCard = ({ d, sync = null }) => {
  const [seeQualifications, setSeeQualifications] = useState(null)

  const navigate = useNavigate()
  const { pathname } = useLocation()

  const onRenewData = () => {
    navigate(`${pathname}?t=${makeRandomString(5)}`)
  }


  const [expandMore, setExpandMore] = useState(false)
  const [addLocation, setAddLocation] = useState(false)
  const [updateVacantQuota, setUpdateVacantQuota] = useState(false)
  const moreRef = useRef()
  const outsideClickMore = UseOutsideClick(moreRef)

  useEffect(() => {
    if (expandMore && outsideClickMore) {
      setExpandMore(false)
    }
  }, [expandMore, outsideClickMore])

  const { width } = useWindowSize()


  return (
    <div className={`${Styles.section} ${Styles.positionCard}`}>
      <div className={Styles.pHeader}>
        <h3>{d?.positionName}</h3>
        <div className={Styles.pHActions}>
          <div>
            <button onClick={() => setSeeQualifications(d)}>
              {
                width > 768
                &&
                <span>
                  Lihat Kualifikasi
                </span>
              }
              <Icon icon={'eye-visible'} size={20} />
            </button>
          </div>
          <div ref={moreRef}>
            <button onClick={() => setExpandMore(!expandMore)}>
              <Icon icon={'three-dots'} size={20} />
            </button>
            {expandMore
              &&
              <div className={Styles.moreOptions}>
                {[
                  {
                    label: <p>Update Kuota & <span>Vacant</span></p>,
                    onClick: () => setUpdateVacantQuota(true)
                  },
                  // {
                  //   label: <p>Tambah Lokasi</p>,
                  //   onClick: () => setAddLocation(true),
                  // }
                ]
                  ?.map((o, oI) => (
                    <div key={oI}
                      className={o?.disabled ? Styles.disabled : ''}
                      onClick={() => {
                        if (!o?.disabled) {
                          o?.onClick()
                        }
                        setExpandMore(false)
                      }}>
                      {o?.label}
                    </div>
                  ))
                }
              </div>
            }
          </div>
        </div>

      </div>
      <div className={Styles.pIndicators}>
        {
          [
            {
              title: 'Total Kuota',
              value: d?.quota,
              subTitle: 'Man Power'
            },
            {
              title: 'Vacant',
              titleFontStyle: 'italic',
              value: d?.vacant,
              subTitle: 'Permintaan'
            },
            {
              title: 'Total Pemenuhan',
              extraTitle:
                <div className={Styles.extra}>
                  <span>Permata Indonesia</span>
                  <div>
                    <img src={Images.INITIAL_LOGO} alt='' />
                  </div>
                </div>,
              value: d?.fulfilled,
              subTitle: 'Man Power'
            },
            {
              title: 'Persentase Terhadap Pemenuhan',
              value: `${Math.floor(d?.fulfilled / d?.quota * 100)}%`,
              subTitle: 'Terpenuhi'
            }
          ]
            ?.map((i, iI) => (
              <div key={iI} className={Styles.pIndicator}>
                <div className={Styles.pTitle}>
                  <span>{i?.title}</span>
                  {!!i?.extraTitle && i?.extraTitle}
                </div>
                <span>{i?.value}</span>
                <p>{i?.subTitle}</p>
              </div>
            ))
        }
      </div>
      <div className={Styles.pList}>
        <table>
          <thead>
            {
              width > 788
                ?
                <tr>
                  <th>Kota</th>
                  <th>Tanggal <span style={{ fontStyle: 'italic' }}>Update</span></th>
                  <th>Jumlah Permintaan & Terpenuhi</th>
                </tr>
                :
                <tr>
                  <th>
                    <div className={Styles.mobileTHeader}>
                      <span>Kota</span>
                      <span>Tgl Permintaan</span>
                    </div>
                  </th>
                </tr>
            }

          </thead>
          <tbody>
            {d?.regions?.map((r, rI) => (
              width > 768
                ?
                <tr key={rI} className={rI === 0 || rI % 2 === 0 ? Styles.odd : ''}>
                  <td className={Styles.bold}>{r?.cityName}</td>
                  <td>{moment(new Date(r?.updatedAt))?.format('LL')}</td>
                  <td>
                    <div className={Styles.progressWrapper}>

                      <Tooltip
                        content={
                          <div className={Styles.tooltip}>
                            <p>
                              <span>Jumlah Terpenuhi</span>
                              <span>:</span>
                              <span>{`${r?.fulfilled} (${Math.floor(r?.fulfilled / r?.request * 100)}%)`}</span>
                            </p>
                            <p>
                              <span>Vendor Lain</span>
                              <span>:</span>
                              <span>{r?.vendor}</span>
                            </p>
                            <p>
                              <span>Vacant</span>
                              <span>:</span>
                              <span>{r?.vacant}</span>
                            </p>
                          </div>
                        }
                        direction='top'
                        backgroundColor='#fff'
                        boxShadow='0px 6px 24px 0px rgba(0, 0, 0, 0.15)'
                        tooltipMargin='75px'
                        isV2
                      >
                        <StaticProgressBar
                          fulfilledAmount={r?.fulfilled}
                          // fulfilledText={`${r?.fulfilled} (${Math.floor(r?.fulfilled / r?.request * 100)}%)`}
                          candidatesAmount={r?.vendor}
                          candidatesText={r?.vendor}
                          requestAmount={r?.request}
                          vacantAmount={r?.vacant}
                          isV3
                          borderRadius='0px'
                        />

                      </Tooltip>
                    </div>

                  </td>
                </tr>
                :
                <tr key={rI} className={rI === 0 || rI % 2 === 0 ? Styles.odd : ''}>
                  <td>
                    <div className={Styles.div}>
                      <div>
                        <span className={Styles.bold}>{r?.cityName}</span>
                        <span>{moment(new Date(r?.updatedAt))?.format('LL')}</span>
                      </div>
                      <StaticProgressBar
                        fulfilledAmount={r?.fulfilled}
                        fulfilledText={`${r?.fulfilled} (${Math.floor(r?.fulfilled / r?.request * 100)}%)`}
                        candidatesAmount={r?.fulfilled + r?.vendor}
                        candidatesText={r?.vendor}
                        requestAmount={r?.request}
                        vacantAmount={r?.vacant}
                        isV3
                        borderRadius='0px'
                      />
                    </div>
                  </td>
                </tr>
            ))}
          </tbody>
        </table>
      </div>
      {
        seeQualifications
        &&
        <SeeQualificationsModal
          data={seeQualifications}
          onClose={() => setSeeQualifications(null)}
          sync={sync}
        />
      }
      {
        addLocation
        &&
        <AddPositionLocationModal
          show={addLocation}
          positionData={d}
          onClose={() => setAddLocation(false)}
          sync={sync}
        />
      }
      {/* {
        updateVacantQuota
        && */}
      <UpdateVacantQuotaModal
        show={updateVacantQuota}
        data={d}
        onClose={() => setUpdateVacantQuota(false)}
        onRenew={onRenewData}
      />
      {/* } */}

    </div >
  )
}
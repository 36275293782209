import Icon from "@Atom/Icon";
import ModalWrapper from "@Atom/ModalWrapper";
import UploadBox from "@Atom/UploadBox";
import { fileBaseUrl } from "@Config/api";
import { allFilledObjChecker } from "@Helpers/allFilledObjChecker";
import UseOutsideClick from "@Hooks/useOutsideClick";
import InputDateField from "@Molecule/InputDateField";
import InputField, { InputTitle } from "@Molecule/InputField";
import Images from "@Theme/Images";
import { useCallback, useEffect, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import Styles from "./style.module.scss";
import ErrorBubble from "@Atom/ErrorBubble";

const PDFJS = require("pdfjs-dist/webpack");

export default function UpdateContractModal({
  show,
  onClose = () => {},
  onAdd = () => {},
  defaultValue,
}) {
  const [form, setForm] = useState({
    docType: defaultValue?.docType || "PKS",
    docNumber: defaultValue?.docNumber || "",
    startDate: defaultValue?.startDate || "",
    endDate: defaultValue?.endDate || "",
    file: defaultValue?.file || null,
    preview: defaultValue?.preview || null,
  });

  const [showTypes, setShowTypes] = useState(false);

  const [uploading, setUploading] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const [errorFileReject, setErrorFileReject] = useState({});

  const { getRootProps, open } = useDropzone({
    onDrop: (acceptedFiles, fileRejections) => {
      if (acceptedFiles?.length) {
        handleChangeForm("file", acceptedFiles[0]);
        setErrorFileReject({});
      }
      if (fileRejections?.length) {
        fileRejections.forEach((file) => {
          file.errors.forEach((err) => {
            if (err.code === "file-too-large") {
              setErrorFileReject({
                message:
                  "File terlalu besar. Ukuran maksimal file adalah 5MB !",
              });
            } else if (err.code === "file-invalid-type") {
              setErrorFileReject({
                message:
                  "Tipe file tidak sesuai. Pastikan file memiliki format PDF",
              });
            } else {
              setErrorFileReject({
                message: err?.message || "Something went wrong",
              });
            }
          });
        });
      }
    },
    accept: {
      "application/pdf": [".pdf"],
      // "image/jpeg": [],
      // "image/jpg": [],
      // "image/png": [],
    },
    multiple: false,
    noClick: true,
    // noDrag: form?.attachment,
    maxSize: 1048576 * 5,
  });

  const handleChangeForm = (name, value) => {
    setForm((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const [previewTemp, setPreviewTemp] = useState(defaultValue?.preview || null);

  const loadImageFromPDF = useCallback(async (url) => {
    setUploading(true);
    var pdf = await PDFJS.getDocument({ url: url }).promise;
    const canvas = document.createElement("canvas");
    const images = [];
    for (let i = 0; i < pdf.numPages; i++) {
      const page = await pdf.getPage(i + 1);
      const viewport = page.getViewport({ scale: 1 });
      const context = canvas.getContext("2d");
      canvas.height = viewport.height;
      canvas.width = viewport.width;
      await page.render({ canvasContext: context, viewport: viewport }).promise;
      images.push(canvas.toDataURL());
    }
    canvas.remove();
    setPreviewTemp(images[0]);
    handleChangeForm("preview", images[0]);
    setUploading(false);
  }, []);

  useEffect(() => {
    if (
      form?.file &&
      form?.file instanceof File &&
      defaultValue?.file !== form?.file
    ) {
      const objectUrl = URL.createObjectURL(form?.file);
      if (form?.file?.type?.split("/").pop().toLowerCase() === "pdf") {
        loadImageFromPDF(objectUrl);
      } else {
        setPreviewTemp(objectUrl);
      }

      // return () => URL.revokeObjectURL(objectUrl)
    }
  }, [defaultValue?.file, form?.file, loadImageFromPDF]);

  useEffect(() => {
    if (
      form?.file &&
      typeof form?.file === "string" &&
      form?.file?.includes("pdf") &&
      (defaultValue?.file !== form?.file ||
        (defaultValue?.file === form?.file && !previewTemp))
    ) {
      const objectUrl = fileBaseUrl + form?.file;
      loadImageFromPDF(objectUrl);
      // return () => URL.revokeObjectURL(objectUrl)
    }
  }, [defaultValue?.file, form?.file, loadImageFromPDF, previewTemp]);

  const ref = useRef();
  const outsideClick = UseOutsideClick(ref);

  useEffect(() => {
    if (outsideClick && showTypes) {
      setShowTypes(false);
    }
  }, [outsideClick, showTypes]);

  return (
    <ModalWrapper show={show}>
      <div className={Styles.container}>
        <div className={Styles.header}>
          <h3>Contract Update</h3>
          <img src={Images.BLOG_AND_ARTICLE} alt="" />
        </div>
        <div className={Styles.content}>
          <InputField
            title="Nomor Dokumen (PKS/SPK/WO/PO)"
            value={form?.docNumber}
            onChange={(e) => handleChangeForm("docNumber", e?.target?.value)}
            placeholder={"Nomor dokumen"}
            floatingStartAdornment={
              <div
                ref={ref}
                className={Styles.typeField}
                onClick={() => setShowTypes(!showTypes)}
              >
                <span>{form?.docType}</span>
                <Icon icon={showTypes ? "arrow-up" : "arrow-down"} size={16} />
                {showTypes && (
                  <div className={Styles.types}>
                    {["PKS", "SPK", "WO", "PO"]?.map((t, tI) => (
                      <div
                        key={tI}
                        className={t === form?.docType ? Styles.active : ""}
                        onClick={() => {
                          handleChangeForm("docType", t);
                          setShowTypes(false);
                        }}
                      >
                        {t}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            }
            top={"8px"}
            left={"8px"}
            startAdornmentDefaultPaddingLeft={"90px"}
            // isError={alertMessage && (!documentNumber || !documentType)}
            // isErrorV2
            // errorText={`Tipe dokument atau nomor dokumen belum diisi`}
            required
          />
          <div className={Styles.dates}>
            <InputDateField
              title={"Tanggal Mulai"}
              value={form?.startDate}
              setValue={(newVal) => {
                if (newVal > form?.endDate) {
                  handleChangeForm("endDate", "");
                }
                handleChangeForm("startDate", newVal);
              }}
              placeholder={"Select Date"}
              type={"date"}
              required
            />
            <InputDateField
              title={"Tanggal Berakhir"}
              value={form?.endDate}
              setValue={(newVal) => handleChangeForm("endDate", newVal)}
              placeholder={"Select Date"}
              type={"date"}
              required
              min={form?.startDate}
            />
          </div>

          <div {...getRootProps()} className={Styles.box}>
            <InputTitle
              title={"Lampiran Dokumen PKS/SPK/WO/PO/Adendum"}
              // color='#757575'
            />
            <UploadBox
              onClick={open}
              isPdf={true}
              isUploaded={form?.file || defaultValue?.file}
              uploading={uploading}
              isError={errorFileReject?.message}
              fileName={
                typeof form?.file === "string" ? form?.file : form?.file?.name
              }
              // fileImage={form?.file ? URL.createObjectURL(form?.file) : ''}
              fileImage={previewTemp}
            />
            {errorFileReject?.message && (
              <ErrorBubble errorText={errorFileReject?.message} />
            )}
          </div>
        </div>
        <div className={Styles.actions}>
          <button onClick={onClose}>Cancel</button>
          <button
            onClick={() => {
              onAdd({ ...form });
              onClose();
            }}
            disabled={!allFilledObjChecker(form)}
          >
            Save
          </button>
        </div>
      </div>
    </ModalWrapper>
  );
}

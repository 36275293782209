import ModalWrapper from '@Atom/ModalWrapper'
import Styles from './style.module.scss'
import { useState } from 'react'
import InputField from '@Molecule/InputField'
import Images from '@Theme/Images'

export default function NewAddPositionModal({
  show = false,
  setShow = () => { },
  onAdd = () => { },
  existingPositionNames = [],
  editMode = false,
  defaultPositionName = '',
  editQtyMode = false,
  defaultQty
}) {
  const [positionName, setPositionName] = useState(defaultPositionName || defaultQty || '')

  return (
    <ModalWrapper show={show} handleClose={() => setShow(false)}>
      <div className={Styles.container}>
        <div className={Styles.header}>
          <img src={Images.ADD_POSITION_LOGO} alt='' />
          <h3>{editQtyMode ? 'Edit Jumlah Pemenuhan' : `${editMode ? 'Edit' : 'Add'} Position`}</h3>
        </div>
        <div className={Styles.content}>
          <InputField
            title={editQtyMode ?'Jumlah' : 'Position'}
            value={positionName}
            placeholder={editQtyMode ? 'Minimal 1':'Enter position'}
            onChange={(e) => setPositionName(e?.target?.value?.toUpperCase())}
            type={editQtyMode ? 'number' : ''}
            onKeyDown={editQtyMode ?(evt) => ["e", "E", "+", "-", "."].includes(evt.key) && evt.preventDefault() : ()=> {}}
          />
          <div className={Styles.actions}>
            <button
              onClick={() => setShow(false)}
            >Cancel</button>
            <button
              disabled={!positionName || existingPositionNames?.find(obj => obj?.toUpperCase() === positionName?.toUpperCase())}
              onClick={() => {
                onAdd(positionName)
                setShow(false)
              }}
            >Save</button>
          </div>

        </div>
      </div>
    </ModalWrapper>
  )
}
import { StyleSheet } from "@react-pdf/renderer";

export default StyleSheet.create({
  page: {
    backgroundColor: "#fff",
    paddingLeft: 138 / 2,
    paddingRight: 138 / 2,
    paddingTop: 102 / 2,
    position: "relative",
    paddingBottom: "80pt",
  },
  titleWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    gap: 4 / 2,
    paddingBottom: 32 / 2,
  },
  headerWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    gap: 4 / 2,
    paddingBottom: 24 / 2,
    // borderBottom: "1px solid #EDEDED",
    // marginBottom: 24 / 2
  },
  divider: {
    height: "1px",
    width: "100%",
    backgroundColor: "#EDEDED",
    marginBottom: 24 / 2,
  },
  titleContentWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "8px",
  },
  iconTitleContent: {
    display: "flex",
    flexDirection: "row",
    borderRadius: "8px",
    padding: "8px",
    gap: "8px",
  },

  // LOG
  logContainer: {
    display: "flex",
    flexDirection: "column",
    padding: "10px 8px 10px 8px",
    backgroundColor: "#ffffff",
    borderRadius: "8px",
    marginTop: 24 / 2,
    border: "1px solid #EDEDED",
  },
  logIconWrapper: {
    backgroundColor: "#E1EDFE",
    borderRadius: "4px",
    padding: "4px",
    gap: "10px",
  },
  logBox: {
    display: "flex",
    flexDirection: "row",
    gap: "4px",
  },
  logUserNameWrapper: {
    display: "flex",
    padding: "4px 0px 4px 0px",
    alignItems: "flex-end",
    width: "15%",
    height: "20px",
    textOverflow: "ellipsis",
    fontWeight: "400",
    fontSize: "14px",
  },
  logUserNameText: {
    fontWeight: "500",
    fontSize: "10px",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  linkAvatar: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    alignContent: "center",
    width: "10%",
  },
  logWrapper: {
    width: "75%",
    display: "flex",
    flexDirection: "row",
    paddingBottom: "12px",
    justifyContent: "space-between",
    gap: "4px",
  },
  logContentWrapper: {
    width: "75%",
    display: "flex",
    flexDirection: "column",
    gap: "4px",
  },
  logTitleText: {
    color: "#1571DE",
    fontWeight: "500",
    fontSize: "10px",
  },
  logText: {
    color: "#757575",
    fontWeight: "400",
    fontSize: "8px",
  },
  logDateWrapper: {
    display: "flex",
    width: "25%",
    alignItems: "flex-end",
  },
  //END LOG

  verticalDivider: {
    flexGrow: 1,
    borderRight: "1px dashed #EDEDED",
  },
  companyIconWrapper: {
    display: "flex",
    flexDirection: "row",
    gap: 24 / 2,
    alignItems: "center",
  },
  companyIcon: {
    height: 75 / 2,
    width: 75 / 2,
    borderRadius: "50%",
    backgroundColor: "#F3F5F7",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  title: {
    fontSize: 24 / 2,
    color: "#000",
    fontFamily: "Helvetica-Bold",
  },
  docNumberWrapper: {
    display: "flex",
    flexDirection: "column",
  },
  docNumber: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    paddingTop: 4 / 2,
  },
  numbOrderText: {
    fontSize: 16 / 2,
    fontWeight: "400",
    color: "#000",
  },
  // eslint-disable-next-line no-dupe-keys
  section: {
    display: "flex",
    flexDirection: "column",
    maxWidth: "50%",
    paddingBottom: 16 / 2,
  },
  radius: {
    borderRadius: 8,
  },
  table: {
    width: "100%",
    borderColor: "#e8e8e8",
    borderWidth: 1,
    borderStyle: "solid",
    display: "flex",
    flexDirection: "column",
    marginBottom: 24 / 2,
  },
  row: {
    display: "flex",
    flexDirection: "row",
    borderBottomColor: "#e8e8e8",
    borderBottomWidth: 1,
    borderBottomStyle: "solid",
    paddingTop: 10 / 2,
    paddingBottom: 10 / 2,
  },
  columnLeft: {
    width: "50%",
    display: "flex",
    justifyContent: "center",
    paddingLeft: 20 / 2,
  },
  rowTitle: {
    fontSize: 16 / 2,
    fontWeight: "600",
    color: "#0a0a0a",
  },
  contentWrapper: {
    display: "flex",
    padding: 8 / 2,
    flexDirection: "row",
    width: "50%",
    gap: 8 / 2,
  },
  content: {
    width: "50%",
    display: "flex",
    flexDirection: "column",
    gap: 4 / 2,
  },
  cellContentWrapper: {
    paddingBottom: 8 / 2,
    display: "flex",
    flexDirection: "column",
    gap: 4 / 2,
  },
  contentWrapperHorizontal: {
    paddingBottom: 8 / 2,
    display: "flex",
    flexDirection: "row",
    backgroundColor: "salmon",
    width: "50%",
  },
  contentHorizontal: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "green",
  },
  regionWrapper: {
    paddingTop: 16 / 2,
    paddingBottom: 16 / 2,
  },
  delegationsWrapper: {
    // gap
    padding: 16 / 2,
    border: "1px solid #e0e0e0",
    borderRadius: 8,
    flexDirection: "row",
    flexWrap: "wrap",
    gap: 16 / 2,
    marginBottom: 16 / 2,
  },
  perJobLevel: {
    minWidth: "47%",
    maxWidth: "calc(50% - 32px)",
    padding: 16 / 2,
    backgroundColor: "#F5F5F5",
    borderRadius: 8,
    gap: 8,
  },
  jobLevel: {
    fontSize: 16 / 2,
    fontFamily: "Helvetica-Bold",
  },
  users: {
    gap: 8 / 2,
  },
  user: {
    flexDirection: "row",
    alignItems: "center",
    gap: 8,
    backgroundColor: "#FFFFFF",
    borderRadius: 8,
    paddingVertical: 4,
    paddingHorizontal: 8,
    border: "1px solid #e0e0e0",
  },
  uAvatar: {
    backgroundColor: "#e0e0e0",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    width: 18,
    aspectRatio: 1,
    borderRadius: "50%",
  },
  uChar: {
    fontFamily: "Helvetica-Bold",
    fontSize: 14 / 2,
  },
  uName: {
    fontSize: 14 / 2,
    flexGrow: 1,
  },
  picIndicator: {
    backgroundColor: "#E1E4F9",
    paddingHorizontal: 10,
    paddingVertical: 6 / 2,
    borderRadius: 10,
  },
  picText: {
    fontSize: 16 / 2,
    color: "#1571DE",
    fontFamily: "Helvetica-Bold",
  },
  manPowerCardWrapper: {
    borderRadius: 8,
    borderColor: "#e0e0e0",
    borderWidth: 1,
    borderStyle: "solid",
    display: "flex",
    flexDirection: "column",
  },
  jobQualificationCardContainer: {
    display: "flex",
    gap: 8 / 2,
  },
  jobQualificationCardWrapper: {
    borderRadius: 8,
    display: "flex",
    flexDirection: "column",
  },
  jobQualificationContentWrapper: {
    borderColor: "#e0e0e0",
    borderLeftWidth: 1,
    borderRightWidth: 1,
    borderBottomWidth: 1,
    borderStyle: "solid",
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
    paddingTop: 16 / 2,
    paddingBottom: 16 / 2,
    paddingLeft: 16 / 2,
    paddingRight: 16 / 2,
    display: "flex",
    gap: 10,
  },
  jobQualificationSectionWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
  },
  jobQualificationTitleWrapper: {
    backgroundColor: "#f5f5f5",
    borderTopRightRadius: 8,
    borderTopLeftRadius: 8,
    display: "flex",
    flexDirection: "row",
    paddingTop: 12 / 2,
    paddingBottom: 12 / 2,
    paddingLeft: 16 / 2,
    paddingRight: 16 / 2,
  },
  workEquipmentCardContent: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  manPowerCardContentHead: {
    display: "flex",
    flexDirection: "row",
    paddingBottom: 16 / 2,
  },
  cardContent: {
    display: "flex",
    flexDirection: "column",
    gap: 4 / 2,
    width: "50%",
    flex: 1,
  },
  manPowerInfoSection: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingTop: 16 / 2,
    paddingLeft: 16 / 2,
    paddingRight: 16 / 2,
    gap: 16 / 2,
  },
  manPowerAllowancePWESection: {
    display: "flex",
    flexDirection: "row",
    gap: 16 / 2,
    padding: 16 / 2,
    justifyContent: "space-between",
  },
  manPowerCardContentBody: {
    backgroundColor: "#f5f5f5",
    padding: 16 / 2,
    borderRadius: 8,
    flex: 1,
    gap: 16 / 2,
  },
  manPowerCardContentBodyTitle: {},
  manPowerCardContentBodyContent: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  footerWrapper: {
    position: "absolute",
    width: "100%",
    bottom: 30,
    left: 138 / 2,
  },
  footer: {
    borderTopColor: "#757575",
    borderTopStyle: "solid",
    borderTopWidth: 1,
    paddingTop: 16 / 2,
    paddingBottom: 16 / 2,
    display: "flex",
    flexDirection: "row",
    gap: 24 / 2,
  },
  footerSection: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: 8 / 2,
  },
  contractTable: {
    padding: 16 / 2,
  },
  ctHeader: {
    flexDirection: "row",
    gap: 20 / 2,
    border: "1px solid #e8e8e8",
    padding: 16 / 2,
    // paddingHorizontal: 16/2,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
  },
  ctCellFirst: {
    width: "24%",
  },
  ctCellSecond: {
    width: "20%",
  },
  ctCellThird: {
    width: "22%",
  },
  ctCellFourth: {
    width: "13%",
  },
  ctCellFifth: {
    width: "13%",
  },
  ctRow: {
    flexDirection: "row",
    gap: 20 / 2,
    border: "1px solid #e8e8e8",
    borderTop: "none",
    padding: 16 / 2,
  },
  odd: {
    backgroundColor: "#F5F7FE",
  },
  last: {
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
  },
  empty: {
    alignItems: "center",
    flexDirection: "column",
  },
  status: {
    width: "100%",
    border: "1px solid transparent",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 40,
    paddingTop: 4,
    paddingBottom: 4,
  },
  approved: {
    backgroundColor: "#EAFEF3",
    borderColor: "#27BB83",
  },
  waiting: {
    backgroundColor: "#FEF8F0",
    borderColor: "#FBDBB6",
  },
  dpContentWrapper: {
    padding: 16 / 2,
    gap: 32 / 2,
  },
  dpSection: {
    gap: 16 / 2,
  },
  dpContent: {
    gap: 16 / 2,
  },
  dpCard: {
    padding: 16 / 2,
    border: "1px solid #e8e8e8",
    gap: 16 / 2,
  },
  dpcHeader: {
    flexDirection: "row",
    gap: 8 / 2,
    alignItems: "center",
  },
  dpcLogo: {
    width: 24 / 2,
    height: 24 / 2,
  },
  dpcContent: {
    flexDirection: "row",
    flexWrap: "wrap",
    gap: 16 / 2,
  },
  dpcRole: {
    width: "49%",
    backgroundColor: "#F5F5F5",
    padding: 16 / 2,
    gap: 13 / 2,
  },
  dpcUsers: {
    gap: 6 / 2,
  },
  dpcUser: {
    backgroundColor: "#fff",
    paddingHorizontal: 12 / 2,
    paddingVertical: 8 / 2,
    flexDirection: "row",
    alignItems: "center",
    gap: 10 / 2,
  },
  dppCard: {
    border: "1px solid #e0e0e0",
    overflow: "clip",
  },
  dppHeader: {
    backgroundColor: "#F5F7FE",
    flexDirection: "row",
    padding: 16 / 2,
    justifyContent: "space-between",
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    borderBottom: "1px solid #e0e0e0",
  },
  dppHLeft: {
    flexDirection: "row",
    gap: 8 / 2,
    alignItems: "center",
  },
  dppHRight: {
    flexDirection: "row",
    gap: 8 / 2,
    alignItems: "center",
  },
  dppContent: {
    padding: 16 / 2,
    gap: 16 / 2,
  },

  dppSection: {
    gap: 10 / 2,
  },
  qTable: {
    border: "1px solid #e0e0e0",
  },
  qtHeader: {
    flexDirection: "row",
    backgroundColor: "#F5F5F5",
    paddingVertical: 10 / 2,
    paddingHorizontal: 16 / 2,
  },
  qtCollFirst: {
    width: "45%",
  },
  qtCollSecond: {
    flexGrow: 1,
  },
  qtBody: {
    padding: 16 / 2,
    display: "flex",
    flexDirection: "column",
    gap: 10,
  },
  qtRow: {
    flexDirection: "row",
  },
  allCity: {
    padding: 16 / 2,
    border: "1px solid #e0e0e0",
    gap: 16 / 2,
  },
  perCity: {
    gap: 12 / 2,
  },
  pcHeader: {
    flexDirection: "row",
    alignItems: "center",
    gap: 8 / 2,
  },
  pcIndicators: {
    flexDirection: "row",
    gap: 16 / 2,
  },
  indicator: {
    gap: 4 / 2,
    width: "50%",
  },
  pcDetails: {
    flexDirection: "row",
    gap: 16 / 2,
  },
  pcdCard: {
    width: "50%",
    backgroundColor: "#F5F5F5",
    padding: 16 / 2,
    gap: 10 / 2,
  },
  pcdList: {
    gap: 8 / 2,
  },
  pcdPoint: {
    flexDirection: "row",
    gap: 8 / 2,
    justifyContent: "space-between",
  },
  remarkBox: {
    padding: 16 / 2,
    border: "1px solid #e0e0e0",
    minHeight: 90 / 2,
  },
});

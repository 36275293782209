import Icon from "@Atom/Icon";
import Styles from "./style.module.scss";
import CandidateStepButton from "@Atom/CandidateStepButton";

const Filled = ({ options, currentStep, onClick, noDoneIcon, disableClick = false }) => {
  return (
    <div className={Styles.container}>
      {options.map((each, i) => {
        return (
          <div
            key={i}
            className={`${Styles.each} ${parseInt(each?.id) <= parseInt(currentStep)
              ? Styles.active
              : Styles.inactive
              }`}
          >
            <div
              className={`${Styles.divider} ${Styles.left} ${i === 0 && Styles.None
                }`}
            />

            <div className={Styles.desc}>
              <div
                className={`${Styles.button} ${each?.warning && !each?.isDone ? Styles.warning : ""
                  } ${disableClick || each?.id > currentStep ? Styles.disabled : ''}`}
                onClick={() => onClick(each?.id)}
              >
                {each?.isDone && !noDoneIcon ? (
                  <Icon icon="check" size={20} />
                ) : (
                  <span>{each?.id}</span>
                )}{" "}
                <div
                  className={`${Styles.label} ${i === options.length - 1 && Styles.lastLabel
                    }`}
                >
                  <span>{each?.label}</span>
                </div>
              </div>
            </div>

            <div
              className={`${Styles.divider} ${Styles.right} ${i === options?.length - 1 && Styles.None
                } ${i > currentStep - 2 ? Styles.inactive : ""}`}
            />
          </div>
        );
      })}
    </div>
  );
};
const Outlined = ({ options, currentStep, onClick, noWhiteSpace, ...props }) => {
  return (
    <div className={Styles.stepperContainer}>
      {options.map((each, i) => {
        return (
          <div
            key={i}
            className={`${Styles.step} ${parseInt(each?.id) <= parseInt(currentStep)
              ? Styles.active
              : Styles.inactive
              } ${each?.isDone ? Styles.done : ""}`}
          >
            <div
              className={`${Styles.circle} ${each?.isDone || options[i - 1]?.isDone ? Styles.pointer : ""
                }`}
              onClick={() => onClick(each?.id)}
            >
              {each?.isDone ? (
                <span>
                  <Icon icon="check" size={15} />
                </span>
              ) : (
                <span>{each?.id}</span>
              )}
            </div>
            <div
              className={`${Styles.label} ${i === options.length - 1 && Styles.lastLabel
                }`}
            >
              <span style={{ whiteSpace: noWhiteSpace ? 'nowrap' : 'normal' }}>{each?.label}</span>
            </div>
            <div
              className={`${Styles.leftBar} ${currentStep === each?.id || currentStep > each?.id
                ? Styles.activeBar
                : Styles.inactiveBar
                }`}
            ></div>
            <div
              className={`${Styles.rightBar} ${currentStep === each?.id + 1 || currentStep > each?.id
                ? Styles.activeBar
                : Styles.inactiveBar
                }`}
            ></div>
          </div>
        );
      })}
    </div>
  );
};

const Candidates = ({
  options,
  currentStep,
  onClick,
  disableBorderTop,
  disableBorderBottom,
  ...props
}) => {
  return (
    <div
      className={`${Styles.candidatesStepperContainer} ${disableBorderTop ? Styles.noBorderTop : ""
        } ${disableBorderBottom ? Styles.noBorderBottom : ""}`}
    >
      {options?.map((option) => (
        <CandidateStepButton
          key={option?.id}
          id={option?.id}
          title={option?.title}
          label={option?.label}
          isActive={currentStep === option?.id}
          onClick={onClick}
        />
      ))}
    </div>
  );
};

const PipeLineFilled = ({ options, currentStep, onClick, noDoneIcon }) => {
  return (
    <div className={Styles.containerFilledPipeline}>
      {options.map((each, i) => {
        return (
          <div
            key={i}
            className={`${Styles.each} ${parseInt(each?.id) <= parseInt(currentStep)
              ? Styles.active
              : Styles.inactive
              }`}
          >
            <div
              className={`${Styles.divider} ${Styles.left} ${i === 0 && Styles.None
                }`}
            />

            <div className={Styles.desc}>
              <div
                className={`${Styles.button} ${each?.warning && !each?.isDone ? Styles.warning : ""
                  }`}
                onClick={() => onClick(each?.id)}
              >
                {each?.isDone && !noDoneIcon ? (
                  <Icon icon="check" size={20} />
                ) : (
                  <span>{each?.id}</span>
                )}{" "}
                <div
                  className={`${Styles.label} ${i === options.length - 1 && Styles.lastLabel
                    }`}
                >
                  <span>{each?.label}</span>
                </div>
              </div>
            </div>

            <div
              className={`${Styles.divider} ${Styles.right} ${i === options?.length - 1 && Styles.None
                } ${i > currentStep - 2 ? Styles.inactive : ""}`}
            />
          </div>
        );
      })}
    </div>
  );
};

const Stepper = ({
  type,
  options,
  currentStep,
  onClick,
  disableBorderTop,
  disableBorderBottom,
  noDoneIcon = false,
  noWhiteSpace = false,
  disableClick = false,
  ...props
}) => {
  switch (type) {
    case "filled":
      return (
        <Filled
          {...props}
          options={options}
          currentStep={currentStep}
          onClick={onClick}
          noDoneIcon={noDoneIcon}
          disableClick={disableClick}
        />
      );
    case "filled-pipeline":
      return (
        <PipeLineFilled
          {...props}
          options={options}
          currentStep={currentStep}
          onClick={onClick}
          noDoneIcon={noDoneIcon}
        />
      );
    case "outlined":
      return (
        <Outlined
          {...props}
          options={options}
          currentStep={currentStep}
          onClick={onClick}
          noWhiteSpace={noWhiteSpace}
        />
      );
    case "candidates":
      return (
        <Candidates
          {...props}
          options={options}
          currentStep={currentStep}
          onClick={onClick}
          disableBorderTop={disableBorderTop}
          disableBorderBottom={disableBorderBottom}
        />
      );
    default:
      return (
        <Filled
          {...props}
          options={options}
          currentStep={currentStep}
          onClick={onClick}
          noDoneIcon={noDoneIcon}
          disableClick={disableClick}
        />
      );
  }
};

export default Stepper;

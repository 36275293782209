import IMAGES from "@Theme/Images";
import Styles from "./style.module.scss";
// eslint-disable-next-line
import LoginCard from "@Organism/Home/Authorization/Login";
import OTPCard from "@Organism/Home/Authorization/VerifyOTP";
import RegisterCard from "@Organism/Home/Authorization/Register";
import { useEffect, useState } from "react";

export default function ClientRegister() {
  // const [authState, setAuthState] = useState('login')
  const [hash, setHash] = useState(null);

  const [data, setData] = useState(null);

  const [step, setStep] = useState(1);

  useEffect(() => {
    if (hash) {
      setStep(2);
    }
  }, [hash]);

  return (
    <div className={Styles.container}>
      <img className={Styles.background} src={IMAGES.AUTH_BG} alt="bg" />
      {step === 1 ? (
        <RegisterCard setHash={setHash} data={data} setData={setData} />
      ) : (
        <OTPCard data={data} setStep={setStep} setHash={setHash} hash={hash} />
      )}
    </div>
  );
}

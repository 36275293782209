import Icon from "@Atom/Icon";
import OTPComponent from "@Molecule/OTPInput";
import DoneModal from "@Molecule/_modal/DoneModal";
import IMAGES from "@Theme/Images";
import { useEffect, useState } from "react";
import Styles from "./style.module.scss";
// eslint-disable-next-line
import { clientRegister, clientVerifyOTP } from "@Services/client/auth";
import { useNavigate } from "react-router-dom";
export default function OTPCard({ data, hash, setHash, setStep }) {
  // eslint-disable-next-line
  const [email, setEmail] = useState(data?.contactInformation?.contactEmail);
  // eslint-disable-next-line
  const [OTP, setOTP] = useState(null);
  const [error, setError] = useState(null);
  const [onVerify, setOnVerify] = useState(false);

  const [isSent, setIsSent] = useState(false);
  const [isDone, setIsDone] = useState(false);

  const navigate = useNavigate();

  const handleClickBack = (e) => {
    e.preventDefault();
    setStep(1);
  };

  const handleResend = async (e) => {
    try {
      e.preventDefault();
      const { response } = await clientRegister({
        contactInformation: {
          contactEmail: data?.contactInformation?.contactEmail,
        },
        clientInformation: {
          name: data?.clientInformation?.name,
          taxNumber: data?.clientInformation?.taxNumber,
          postal: data?.clientInformation?.postal,
          province: data?.clientInformation?.province,
          address: data?.clientInformation?.address,
          phoneNumber: data?.clientInformation?.phoneNumber,
          website: data?.clientInformation?.website || '',
        },
      });

      setHash(response?.hash);

      setIsSent(true);
    } catch (err) {
      console.log(err?.response);
    }
  };

  const handleConfirm = async (e) => {
    e.preventDefault();
    try {
      setOnVerify(true);
      await clientVerifyOTP(hash, {
        clientInformation: data?.clientInformation,
        contactInformation: data?.contactInformation,
        otp: OTP,
      });
      setIsDone(true);
    } catch (err) {
      if (err?.response?.data?.error?.toLowerCase()?.includes("wrong code")) {
        setError("Wrong OTP input");
      } else {
        setError(err?.response?.data?.error);
      }
    }
    setOnVerify(false);
  };

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setError(null);
      }, 5000);
    }
  }, [error]);

  return (
    <div className={Styles.container}>
      <div className={Styles.logo}>
        <img src={IMAGES.KERJA365_LIGHT} alt="logo" />
      </div>
      <button
        onClick={handleClickBack}
        className={Styles.backNav}
        type="button"
      >
        <Icon icon="arrow-left" size={20} />
        <span>Kembali</span>
      </button>
      <div className={Styles.header}>
        <h3>Konfirmasi</h3>
        <p>
          Kami telah mengirim kode verifikasi ke email <span>{email}</span>,
          mohon periksa dan masukan kode di kolom berikut
        </p>
      </div>

      <form onSubmit={handleConfirm}>
        <OTPComponent
          onChange={(value) => {
            setOTP(value);
          }}
          alert={error}
        />
        <div className={`${Styles.errorWrapper} ${error ? Styles.error : ""}`}>
          <span>{error ? error : "alert placeholder"}</span>
        </div>
        <div className={Styles.resendWrapper}>
          <button onClick={handleResend} type="button">
            Kirim Ulang kode?
          </button>
        </div>
        <div className={Styles.buttonWrapper}>
          <button
            type="submit"
            className={Styles.submitButton}
            disabled={!OTP || OTP?.length < 4 || onVerify}
          >
            {onVerify ? "Mengonfirmasi..." : "Konfirmasi"}
          </button>
        </div>
      </form>
      {isSent && (
        <DoneModal
          title="Terkirim"
          description={
            <span className={Styles.modalDesc}>
              Kode verifikasi berhasil terkirim ke email{" "}
              <strong>{data?.contactInformation?.contactEmail}</strong>
            </span>
          }
          doneButton={true}
          doneTitle={"Kembali"}
          handleDone={() => setIsSent(false)}
        />
      )}
      {isDone && (
        <DoneModal
          title="Sukses"
          description={
            <span className={Styles.modalDesc}>
              Perusahaan anda berhasil terdaftar
            </span>
          }
          doneButton={true}
          doneTitle={"Ke Login"}
          handleDone={() => {
            setIsDone(false);
            navigate("/login");
          }}
        />
      )}
    </div>
  );
}

import Pagination from "@Molecule/Pagination";
import { useEffect, useState } from "react";
import Styles from "./style.module.scss";
import TableEmptyHandler from "@Atom/TableEmptyHandler";
import Images from "@Theme/Images";
import OutlinedSearchBar from "@Atom/OutlinedSearchBar";
import { Skeleton } from "@mui/material";

export default function CustomTable({
  type,
  data = [],
  columns = [],
  value = 5,
  setValue = () => {},
  selectedPage = 0,
  setSelectedPage = () => {},
  totalPage = 0,
  noContainerBorder,
  isV2 = false,
  noStickyPagination = false,
  stickyTop,
  totalData,
  noPageList = false,
  isLoading = false,
  isLoadingSkeleton = false,
  height = "100%",
  minHeight = "250px",
  search = "",
  setSearch = () => {},
  isContractList = false,
  searchQuery = "",
  withNumbering = false,
  oddRowClassName = "",
  evenRowClassName = "",
  noRowBorder = false,
  onClickRow,
  noPagination = false,
  customEmptyHandler,
  isError = false,
  isBs,
  inputRef = null,
  isHCList,
  verify,
}) {
  const [pages, setPages] = useState(`1-${value}`);
  const [pageList, setPageList] = useState([]);

  const [searchTemp, setSearchTemp] = useState("");

  useEffect(() => {
    setSearchTemp(search);
  }, [search]);

  return (
    <div
      style={{ height, minHeight }}
      className={`${Styles.container} ${
        noContainerBorder ? Styles.noBorder : ""
      } ${isV2 ? Styles.v2 : ""} ${isError ? Styles.error : ""}`}
    >
      {/* <div
        className={`${Styles.tableContainer} ${
          data?.length ? Styles.grow : ""
        } ${isContractList && !searchQuery ? Styles.noShrink : ""}`}
      ></div> */}
      <div
        className={`${Styles.tableContainer} ${
          data?.length ? Styles.grow : ""
        } ${isContractList && !searchQuery ? Styles.noShrink : ""}`}
      >
        <table
          className={`${
            type === "sticky-left"
              ? Styles.stickyLeft
              : type === "sticky-left-right"
              ? Styles.stickyLeftRight
              : type === "sticky-right"
              ? Styles.stickyRight
              : ""
          } ${withNumbering ? Styles.withNumbering : ""}`}
        >
          <thead className={stickyTop ? Styles.stickyTop : ""}>
            <tr>
              {withNumbering && (
                <th
                  style={{
                    width: "50px",
                    maxWidth: "100px",
                    minWidth: "50px",
                  }}
                >
                  <span>No</span>
                </th>
              )}
              {columns?.map((column) => (
                <th
                  key={column?.name}
                  style={{
                    textAlign: column?.align || "left",
                    minWidth: column?.minWidth || "auto",
                    maxWidth: column?.maxWidth || "auto",
                  }}
                  className={`${column?.thClassName || ""} ${
                    column?.hideOnMobile ? Styles.hideOnMobile : ""
                  } ${column?.showOnMobile ? Styles.showOnMobile : ""}`}
                >
                  {column?.renderLabel ? (
                    column?.renderLabel(column)
                  ) : (
                    <span>{column?.label}</span>
                  )}
                  {type && type !== "default" && (
                    <div
                      className={`${Styles.leftSeparator} ${
                        column?.showLeftSeparator ? Styles.forceShow : ""
                      }}`}
                    />
                  )}
                  {type && type !== "default" && (
                    <div
                      className={`${Styles.rightSeparator} ${
                        column?.showRightSeparator ? Styles.forceShow : ""
                      }`}
                    />
                  )}
                </th>
              ))}
            </tr>
          </thead>

          <tbody
            className={`${isContractList && !searchQuery ? Styles.dNone : ""} ${
              noRowBorder ? Styles.noRowBorder : ""
            }`}
          >
            {!!data?.length &&
              !isLoading &&
              !isLoadingSkeleton &&
              data?.map((row, i) => (
                <tr
                  key={i}
                  className={
                    i === 0 || i % 2 === 0 ? oddRowClassName : evenRowClassName
                  }
                  onClick={onClickRow ? () => onClickRow(row) : () => {}}
                  style={{
                    cursor: onClickRow ? "pointer" : "auto",
                  }}
                >
                  {withNumbering && (
                    <td
                      className={`${
                        isHCList &&
                        row?.getMonthsDifference < 4 &&
                        row?.getMonthsDifference >= 0 &&
                        verify !== "expired" &&
                        Styles.red
                      }`}
                    >
                      {i + 1}
                    </td>
                  )}
                  {columns?.map((column) => (
                    <td
                      key={column?.name}
                      className={`${column?.tdClassName || ""} ${
                        column?.hideOnMobile ? Styles.hideOnMobile : ""
                      } ${column?.showOnMobile ? Styles.showOnMobile : ""} ${
                        isHCList &&
                        row?.getMonthsDifference < 4 &&
                        row?.getMonthsDifference >= 0 &&
                        verify !== "expired" &&
                        Styles.red
                      }`}
                      style={{
                        textAlign: column?.align || "left",
                        minWidth: column?.minWidth || "auto",
                        maxWidth: column?.maxWidth || "auto",
                        background:
                          isBs &&
                          row?.stepStatus?.toLowerCase() === "revision" &&
                          "#FFFAEF",
                      }}
                    >
                      {column?.renderData
                        ? column?.renderData(row)
                        : row?.[column?.name]}
                      {type && type !== "default" && (
                        <div
                          className={`${Styles.leftSeparator} ${
                            column?.showLeftSeparator ? Styles.forceShow : ""
                          }}`}
                        />
                      )}
                      {type && type !== "default" && (
                        <div
                          className={`${Styles.rightSeparator} ${
                            column?.showRightSeparator ? Styles.forceShow : ""
                          }`}
                        />
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            {isLoadingSkeleton &&
              [...Array(value)]?.map((row, i) => (
                <tr
                  key={i}
                  className={
                    i === 0 || i % 2 === 0 ? oddRowClassName : evenRowClassName
                  }
                >
                  {withNumbering && (
                    <td
                      style={{
                        maxWidth: "50px",
                        minWidth: "50px",
                      }}
                    >
                      <div>{i + 1}</div>
                    </td>
                  )}
                  {columns?.map((column, ic) => (
                    <td
                      id={column?.renderPopup ? `td-${row?.id}` : ""}
                      key={ic}
                      // className={column?.tdClassName || ''}
                      className={`${column?.tdClassName || ""} ${
                        column?.hideOnMobile ? Styles.hideOnMobile : ""
                      } ${column?.showOnMobile ? Styles.showOnMobile : ""}`}
                      style={{ textAlign: column.align || "left" }}
                    >
                      <div
                        className={Styles.wrapper}
                        style={{
                          textAlign: column.align || "left",
                        }}
                      >
                        {column?.skeleton || (
                          <Skeleton
                            duration={2}
                            className={Styles.defaultSkeleton}
                          />
                        )}

                        {column?.withBorderRight && (
                          <div className={Styles.borderRight} />
                        )}
                      </div>
                    </td>
                  ))}
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      {!data?.length || isLoading ? (
        !customEmptyHandler ? (
          <div
            className={`${Styles.emptyWrapper}`}
            style={{ opacity: isLoadingSkeleton ? 0 : 1 }}
          >
            {!isContractList ? (
              <TableEmptyHandler
                title={isLoading ? "Tunggu sebentar" : "Data Kosong"}
                desc={
                  isLoading
                    ? "Data sedang dimuat"
                    : "Tidak ada data atau data belum ada"
                }
                isV2={!!isContractList}
              />
            ) : (
              !!search?.length && (
                <TableEmptyHandler
                  title={isLoading ? "Tunggu sebentar" : "Data Kosong"}
                  desc={
                    isLoading
                      ? "Data sedang dimuat"
                      : "Tidak ada data atau data belum ada"
                  }
                  isV2={!!isContractList}
                />
              )
            )}
          </div>
        ) : (
          customEmptyHandler
        )
      ) : null}
      {isContractList && !search && !isLoading && (
        <div className={Styles.noSearchWrapper}>
          <div className={Styles.card}>
            <img src={Images.CANDIDATE_SEARCH} alt="" />
            <div className={Styles.desc}>
              <h3>Temukan Karyawan</h3>
              <span>
                Untuk memproses perpanjangan kontrak, cari karyawan terlebih
                dahulu
              </span>
            </div>
            <OutlinedSearchBar
              inputRef={inputRef}
              value={search}
              setValue={setSearch}
              value1={searchTemp}
              setValue1={setSearchTemp}
              placeholder={"Cari berdasarkan nama/NRK/NIK"}
              // onClickIcon={() => setSearch(searchTemp)}
            />
          </div>
        </div>
      )}

      {!noPagination && (
        <div
          className={`${Styles.paginationWrapper} ${
            noStickyPagination ? Styles.noSticky : ""
          }`}
        >
          <Pagination
            total={totalData || data?.length || 0}
            totalPage={totalPage || 0}
            value={value || 5}
            setValue={setValue}
            pages={pages}
            setPages={setPages}
            pageList={pageList}
            setPageList={setPageList}
            selectedPage={selectedPage}
            setSelectedPage={setSelectedPage}
            // isV2={isV2}
            bgColor={"#fff"}
            siblingCount={isV2 ? 0 : 1}
            noPageList={noPageList}
          />
        </div>
      )}
    </div>
  );
}

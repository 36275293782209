import moment from "moment";
import Styles from "./styles.module.scss";
import { fileBaseUrl } from "@Config/api";
import { useState } from "react";
import ModalWrapper from "@Atom/ModalWrapper";

const bulanSingkatan = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "Mei",
  "Jun",
  "Jul",
  "Agu",
  "Sep",
  "Okt",
  "Nov",
  "Des",
];

// Update locale default dengan nama bulan yang diinginkan
moment.updateLocale("id", {
  monthsShort: bulanSingkatan,
});

export default function ContractLogs({ data, isHC, selectedLogs, updateLogs }) {
  const [selectedID, setSelectedID] = useState(null);
  // const contactRef = useNav("Daftar Kontrak");

  return (
    <div className={Styles.container}>
      <div>Daftar Kontrak</div>
      <div>
        <div className={Styles.tableBox} id="Daftar Kontrak">
          <div className={Styles.tableHead}>
            <span>Document Number & Type</span>
            <span>Upload Date & Time</span>
            <span>Start-End Date</span>
            <span>Attachment</span>
            <span>Status</span>
          </div>

          <div className={Styles.tablebody}>
            {data?.contractLogs?.map((el, index) => {
              return (
                <div
                  className={`${Styles.body} ${
                    index === data?.clientContacts?.length - 1 &&
                    Styles.borderNone
                  } ${index % 2 === 0 ? Styles.blue : Styles.white} ${
                    isHC &&
                    selectedLogs?.key === "ClientContractLog" &&
                    selectedLogs?.data?.clientContractLogID === el?.id &&
                    Styles.yellow
                  } ${
                    isHC &&
                    updateLogs?.length > 0 &&
                    updateLogs?.find(
                      (log) =>
                        log?.table === "ClientContractLog" &&
                        log?.clientContractLogID === el?.id
                    ) &&
                    Styles.borderYellow
                  }`}
                  key={index}
                >
                  <div>
                    <span>{el?.flbNumber || "-"}</span>
                    <span>{el?.docType || "-"}</span>
                  </div>
                  <span>
                    {el?.updatedAt
                      ? moment(new Date(el?.updatedAt)).format(
                          "DD MMMM YYYY, HH:mm"
                        )
                      : "-"}
                  </span>
                  <span>
                    {el?.startDate
                      ? moment(new Date(el?.startDate)).format("DD MMM YYYY")
                      : "-"}{" "}
                    -{" "}
                    {el?.endDate
                      ? moment(new Date(el?.endDate)).format("DD MMM YYYY")
                      : "-"}
                  </span>
                  <span>
                    <span
                      onClick={() => {
                        if (el?.file) {
                          window.open(fileBaseUrl + el?.file);
                        }
                      }}
                      className={`${el?.file && Styles.active}`}
                    >
                      Lihat File
                    </span>
                  </span>
                  <span
                    className={`${Styles.defaultValue} ${
                      el?.status?.toLowerCase() === "waiting" && Styles.yellow
                    } ${el?.status?.toLowerCase() === "expired" && Styles.red}`}
                  >
                    {el?.status
                      ? el?.status?.toLowerCase() === "active"
                        ? "Approved"
                        : el?.status?.toLowerCase() === "waiting"
                        ? "Waiting"
                        : "Expired"
                      : "-"}
                  </span>
                </div>
              );
            })}
          </div>
        </div>

        <div className={Styles.tableBoxResponsive} id="Daftar Kontrak Resp">
          <div className={Styles.tableHead}>
            <span>Document Number & Type</span>
            <span>Action</span>
          </div>

          <div className={Styles.tablebody}>
            {data?.contractLogs?.map((el, index) => {
              return (
                <div
                  className={`${Styles.body} ${
                    index === data?.clientContacts?.length - 1 &&
                    Styles.borderNone
                  } ${index % 2 === 0 ? Styles.blue : Styles.white} ${
                    isHC &&
                    selectedLogs?.key === "ClientContractLog" &&
                    selectedLogs?.data?.clientContractLogID === el?.id &&
                    Styles.yellow
                  } ${
                    isHC &&
                    updateLogs?.length > 0 &&
                    updateLogs?.find(
                      (log) =>
                        log?.table === "ClientContractLog" &&
                        log?.clientContractLogID === el?.id
                    ) &&
                    Styles.borderYellow
                  }`}
                  key={index}
                >
                  <div>
                    <span>{el?.flbNumber || "-"}</span>
                    <span>{el?.docType || "-"}</span>
                  </div>
                  <div>
                    <div
                      onClick={() => {
                        setSelectedID(el);
                      }}
                    >
                      See More
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      {selectedID && (
        <ModalWrapper show={selectedID} handleClose={() => setSelectedID(null)}>
          <div className={Styles.containerDetail}>
            <div className={Styles.title}>Daftar Kontrak</div>
            <div>
              <span>Document Number</span>
              <span>{selectedID?.flbNumber || "-"}</span>
            </div>

            <div>
              <span>Type</span>
              <span>{selectedID?.docType || "-"}</span>
            </div>

            <div>
              <span>Upload Date & Time</span>
              <span>
                {selectedID?.updatedAt
                  ? moment(new Date(selectedID?.updatedAt)).format(
                      "DD MMMM YYYY, HH:mm"
                    )
                  : "-"}
              </span>
            </div>

            <div>
              <span>Start-End Date</span>
              <span>
                {" "}
                {selectedID?.startDate
                  ? moment(new Date(selectedID?.startDate)).format(
                      "DD MMM YYYY"
                    )
                  : "-"}{" "}
                -{" "}
                {selectedID?.endDate
                  ? moment(new Date(selectedID?.endDate)).format("DD MMM YYYY")
                  : "-"}
              </span>
            </div>

            <div>
              <span>Attachment</span>
              <span
                onClick={() => {
                  if (selectedID?.file) {
                    window.open(fileBaseUrl + selectedID?.file);
                  }
                }}
                className={`${
                  selectedID?.file ? Styles.active : Styles.disabled
                }`}
              >
                Lihat File
              </span>
            </div>

            <div>
              <span>Status</span>
              <span
                className={`${
                  selectedID?.status?.toLowerCase() === "active" && Styles.green
                } ${
                  selectedID?.status?.toLowerCase() === "waiting" &&
                  Styles.yellow
                } ${
                  selectedID?.status?.toLowerCase() === "expired" && Styles.red
                }`}
              >
                {" "}
                {selectedID?.status
                  ? selectedID?.status?.toLowerCase() === "active"
                    ? "Approved"
                    : selectedID?.status?.toLowerCase() === "waiting"
                    ? "Waiting"
                    : "Expired"
                  : "-"}
              </span>
            </div>
          </div>
        </ModalWrapper>
      )}
    </div>
  );
}

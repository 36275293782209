import InputField from "@Molecule/InputField";
import IMAGES from "@Theme/Images";
import { useEffect, useState } from "react";
import Styles from "./style.module.scss";
import { clientLogin } from "@Services/client/auth";
import { useNavigate } from "react-router-dom";

export default function LoginCard() {
  const [form, setForm] = useState({
    email: "",
    password: "",
  });

  const [isLoggingIn, setIsLoggingIn] = useState(false);

  const [error, setError] = useState(null);

  const handleChange = (e, name) => {
    setForm((prev) => {
      return {
        ...prev,
        [name]: e.target.value,
      };
    });
  };

  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      setIsLoggingIn(true);
      const { response } = await clientLogin(form);
      localStorage.setItem("accessToken", response?.accessToken);
      localStorage.setItem("currentMode", "dashboard")
      localStorage.setItem("username", response?.data?.name);
      localStorage.setItem("role", response?.data?.jobTitle);
      localStorage.setItem("id", response?.data?.id);
      localStorage.setItem("clientCode", response?.data?.clientCode);
      localStorage.setItem("phoneNumber", response?.data?.phoneNumber);
      localStorage.setItem("workUnit", response?.data?.workUnit);
      localStorage.setItem("profilePicture", response?.data?.profilePicture);
      localStorage.setItem("isClient", true);
      // sessionStorage.setItem("username", response?.data?.name);
      // sessionStorage.setItem("role", response?.data?.jobTitle);
      // sessionStorage.setItem("id", response?.data?.id);
      // sessionStorage.setItem("clientCode", response?.data?.clientCode);
      // sessionStorage.setItem("phoneNumber", response?.data?.phoneNumber);
      // sessionStorage.setItem("workUnit", response?.data?.workUnit);
      // sessionStorage.setItem("profilePicture", response?.data?.profilePicture);
      // sessionStorage.setItem("isClient", true);
      // sessionStorage.setItem("profilePicture", response?.data?.profilePicture);
      // sessionStorage.setItem("accessToken", response?.accessToken);
      // sessionStorage.setItem('currentMode', 'dashboard')
      navigate("/dashboard");
      setIsLoggingIn(false);
    } catch (err) {
      setIsLoggingIn(false);
      console.log(err?.response, "er");
      if (err?.response?.data?.error?.toLowerCase().includes("invalid")) {
        setError("Wrong email/password");
      } else {
        setError(err?.response?.data?.error);
      }
    }
  };

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setError(null);
      }, 5000);
    }
  }, [error]);

  return (
    <div className={Styles.container}>
      <div className={Styles.logo}>
        <img src={IMAGES?.KERJA365_LIGHT} alt="logo" />
      </div>
      <div className={Styles.header}>
        <div className={Styles.title}>
          <h3>Selamat Datang di Kerja 365</h3>
          <img src={IMAGES?.WAVE} alt="logo" />
        </div>
        <div className={Styles.subTitle}></div>
      </div>

      <form className={Styles.form} onSubmit={handleLogin}>
        <div className={Styles.boxField}>
          <InputField
            id="email"
            placeholder={"Email akun"}
            type="email"
            title="Email"
            value={form?.email}
            onChange={(e) => handleChange(e, "email")}
          />
        </div>
        <div className={Styles.boxField}>
          <InputField
            id="password"
            placeholder={"Kata sandi akun"}
            type="password"
            title="Password"
            value={form?.password}
            onChange={(e) => handleChange(e, "password")}
          />
        </div>
        <div onClick={()=> navigate('/forgot-password')} className={Styles.forgotPasswordWrapper}>
          <button type="button">Lupa kata sandi</button>
        </div>
        <div className={`${Styles.errorWrapper} ${error ? Styles.error : ""}`}>
          <p>{error ? error : "error placeholder"}</p>
        </div>
        <button type="submit" disabled={isLoggingIn}>
          {isLoggingIn ? "Logging In..." : "Log In"}
        </button>
      </form>
      {/* <div className={Styles.BottomLine}/> */}
    </div>
  );
}

import Images from "@Theme/Images";
import React from "react";
import Styles from "./Styles.module.scss";

export default function HomePersonalAdContainerLayout() {
  return (
    <div className={Styles.personalAdContainer}>
      <div>
        <img src={Images.PERSONAL_AD_IMAGE} alt="PERSONAL_AD_IMAGE" />
        <div>
          <span className={Styles.bigTitle}>Butuh Pekerjaan ?</span>
          <div>
            <span>
              Kami menyediakan layanan berbasis mobile khusus untuk pencari
              kerja, mulai kerja sama dengan kami sekarang
            </span>
          </div>

          <div className={Styles.linkContainer}>
            <a href="https://www.google.com">
              <img
                src={Images.GET_ON_GOOGLEPLAY_IMAGE}
                alt="GET_ON_GOOGLEPLAY_IMAGE"
              />
            </a>
            <a href="https://www.google.com">
              <img
                src={Images.GET_ON_APPSTORE_IMAGE}
                alt="GET_ON_APPSTORE_IMAGE"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

import InputField from '@Molecule/InputField'
import Styles from './style.module.scss'

export default function Third({
  password,
  setPassword,
  passwordConfirmation,
  setPasswordConfirmation

}) {
  return (
    <div className={Styles.container}>
      <InputField
        id="new-password"
        type="password"
        title="Kata Sandi"
        placeholder="Kata sandi baru"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <InputField
        id="new-password-confirmation"
        type="password"
        title="Konfirmasi Kata Sandi"
        placeholder="Konformasi kata sandi"
        value={passwordConfirmation}
        onChange={(e) => setPasswordConfirmation(e.target.value)}
      />
    </div>
  )
}
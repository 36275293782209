import { API } from "@Config/api";

export function clientLogin(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.post(`/client-contact/sign-in`, payload);
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function clientRegister(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.post(`/client-contact/sign-up`, payload);
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function clientVerifyOTP(hash, payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.post(
        `/client-contact/sign-up-verify/${hash}`,
        payload
      );
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function forgotPasswordStep1(payload) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.post(`/client-contact/forgot-password`, payload);
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function forgotPasswordStep2(payload, hash) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.post(`/client-contact/forgot-password-verify/${hash}`, payload);
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}

export function forgotPasswordStep3(payload, id) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await API.patch(`/client-contact/forgot-password-change-password/${id}`, payload);
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}



import IMAGES from "@Theme/Images";
import Styles from "./style.module.scss";
import ForgotPasswordCard from "@Organism/Home/Authorization/ForgotPassword";
import { useState } from "react";

export default function ClientForgotPassword() {
  // const [authState, setAuthState] = useState('login')

  const [step, setStep] = useState(1);

  const [stepsOptions, setStepsOptions] = useState([
    {
      id: 1,
      label: "Konfirmasi email",
      isDone: false,
    },
    {
      id: 2,
      label: "OTP",
      isDone: false,
    },
    {
      id: 3,
      label: "Kata Sandi Baru",
      isDone: false,
    },
  ])

  return (
    <div className={Styles.container}>
      <img className={Styles.background} src={IMAGES.AUTH_BG} alt="bg" />
      <ForgotPasswordCard
        steps={stepsOptions}
        setSteps={setStepsOptions}
        currentStep={step}
        setCurrentStep={setStep}
      />
    </div>
  );
}

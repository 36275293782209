import Icon from '@Atom/Icon';
import { fileBaseUrl } from '@Config/api';
import useQuery from '@Hooks/useQuery';
import UploadCard from '@Molecule/UploadCard';
import { createDocument, updateDocument } from '@Services/manager/client';
import { useCallback, useEffect, useRef, useState } from 'react';
import Styles from './style.module.scss';

const PDFJS = require("pdfjs-dist/webpack");

const Desktop = ({
  errorFileReject = {},
  inputFileRefs,
  onDrops,
  onChange,
  defaultPreviews,
  files,
  uploading,
  onClickButton,
  defaultDocuments,
  submitting,
  setShow,
  errors
}) => {

  console.log(errors, 'eee')

  return (
    <div className={Styles.background}>
      <div className={Styles.container}>
        <div className={Styles.header}>
          <h3>Company Document</h3>
          <button onClick={() => setShow(false)}>
            <Icon icon="cross" size={20} />
          </button>
        </div>
        <div className={Styles.content}>
          <UploadCard
            inputFileRef={inputFileRefs ? inputFileRefs[0] : null}
            onDrop={onDrops ? onDrops[0] : () => { }}
            onChange={(e) => onChange(e, 0)}
            defaultPreview={defaultPreviews ? defaultPreviews[0] : null}
            defaultName={defaultDocuments ? defaultDocuments[0]?.type : null}
            fileImage={files ? files[0]?.preview : null}
            fileName={files ? files[0]?.name : null}
            uploading={uploading ? uploading[0] : false}
            isUploaded={files[0]?.file && files[0]?.preview}
            title={files[0]?.title}
            showButton={true}
            onClickButton={(e) => onClickButton(e, 0)}
            submitting={submitting ? submitting[0] : false}
            errorText={errors[0]?.message}
          />
          <UploadCard
            inputFileRef={inputFileRefs ? inputFileRefs[1] : null}
            onDrop={onDrops ? onDrops[1] : () => { }}
            onChange={(e) => onChange(e, 1)}
            defaultPreview={defaultPreviews ? defaultPreviews[1] : null}
            defaultName={defaultDocuments ? defaultDocuments[1]?.type : null}
            fileImage={files ? files[1]?.preview : null}
            fileName={files ? files[1]?.name : null}
            uploading={uploading ? uploading[1] : false}
            isUploaded={files[1]?.file && files[1]?.preview}
            title={files[1]?.title}
            showButton={true}
            onClickButton={(e) => onClickButton(e, 1)}
            submitting={submitting ? submitting[1] : false}
            errorText={errors[1]?.message}
          />
          <UploadCard
            inputFileRef={inputFileRefs ? inputFileRefs[2] : null}
            onDrop={onDrops ? onDrops[2] : () => { }}
            onChange={(e) => onChange(e, 2)}
            defaultPreview={defaultPreviews ? defaultPreviews[2] : null}
            defaultName={defaultDocuments ? defaultDocuments[2]?.type : null}
            fileImage={files ? files[2]?.preview : null}
            fileName={files ? files[2]?.name : null}
            uploading={uploading ? uploading[2] : false}
            isUploaded={files[2]?.file && files[2]?.preview}
            title={files[2]?.title}
            showButton={true}
            onClickButton={(e) => onClickButton(e, 2)}
            submitting={submitting ? submitting[2] : false}
            errorText={errors[2]?.message}
          />
          <UploadCard
            inputFileRef={inputFileRefs ? inputFileRefs[3] : null}
            onDrop={onDrops ? onDrops[3] : () => { }}
            onChange={(e) => onChange(e, 3)}
            defaultPreview={defaultPreviews ? defaultPreviews[3] : null}
            defaultName={defaultDocuments ? defaultDocuments[3]?.type : null}
            fileImage={files ? files[3]?.preview : null}
            fileName={files ? files[3]?.name : null}
            uploading={uploading ? uploading[3] : false}
            isUploaded={files[3]?.file && files[3]?.preview}
            title={files[3]?.title}
            showButton={true}
            onClickButton={(e) => onClickButton(e, 3)}
            submitting={submitting ? submitting[3] : false}
            errorText={errors[3]?.message}
          />
          <UploadCard
            inputFileRef={inputFileRefs ? inputFileRefs[4] : null}
            onDrop={onDrops ? onDrops[4] : () => { }}
            onChange={(e) => onChange(e, 4)}
            defaultPreview={defaultPreviews ? defaultPreviews[4] : null}
            defaultName={defaultDocuments ? defaultDocuments[4]?.type : null}
            fileImage={files ? files[4]?.preview : null}
            fileName={files ? files[4]?.name : null}
            uploading={uploading ? uploading[4] : false}
            isUploaded={files[4]?.file && files[4]?.preview}
            title={files[4]?.title}
            showButton={true}
            onClickButton={(e) => onClickButton(e, 4)}
            submitting={submitting ? submitting[4] : false}
            errorText={errors[4]?.message}
          />
        </div>
        {errorFileReject?.message ? (
          <span className={Styles.errorText}>*{errorFileReject?.message}</span>
        ) : null}
        <div className={Styles.buttonWrapper}>
          <button onClick={() => setShow(false)}>Back to Detail</button>
        </div>
      </div>
    </div>
  )
}

const Mobile = () => {

  return (
    <div className={Styles.MobileContainer}>
      mobile
    </div>
  )
}

export default function EditDocumentsModal({
  type,
  files,
  setFiles,
  defaultDocuments,
  setTrigger,
  setShow
}) {
  const query = useQuery()
  const clientCode = query.get('c') || localStorage.getItem("clientCode")
  const [defaultPreviews, setDefaultPreviews] = useState([])
  const [uploading, setUploading] = useState([false, false, false, false, false])

  const [submitting, setSubmitting] = useState([false, false, false, false, false])

  const handleEditFiles = async (e, i) => {
    const newFiles = [...files];
    const newUploading = [...uploading]
    newUploading[i] = true
    setUploading(newUploading)
    const file = e.target.files[0];
    newFiles[i].file = file
    newFiles[i].name = file.name;
    const uri = URL.createObjectURL(file);
    var pdf = await PDFJS.getDocument({ url: uri }).promise
    const canvas = document.createElement("canvas");
    const images = [];
    for (let i = 0; i < pdf.numPages; i++) {
      const page = await pdf.getPage(i + 1);
      const viewport = page.getViewport({ scale: 1 });
      const context = canvas.getContext("2d");
      canvas.height = viewport.height;
      canvas.width = viewport.width;
      await page.render({ canvasContext: context, viewport: viewport }).promise;
      images.push(canvas.toDataURL());
    }
    canvas.remove();
    newFiles[i].preview = images[0];
    newUploading[i] = false
    setUploading(newUploading)
    setFiles(newFiles)
  }

  const [errorFileReject0, setErrorFileReject0] = useState({});
  const [errorFileReject1, setErrorFileReject1] = useState({});
  const [errorFileReject2, setErrorFileReject2] = useState({});
  const [errorFileReject3, setErrorFileReject3] = useState({});
  const [errorFileReject4, setErrorFileReject4] = useState({});
  const onDrop0 = useCallback(async (acceptedFiles, fileRejections) => {
    if (fileRejections?.length) {
      fileRejections.forEach((file) => {
        file.errors.forEach((err) => {
          if (err.code === "file-too-large") {
            setErrorFileReject0({
              message:
                "File terlalu besar. Ukuran maksimal file adalah 5MB !",
            });
          } else if (err.code === "file-invalid-type") {
             
              setErrorFileReject0({
                message:
                  "Tipe file tidak sesuai. Pastikan file memiliki format PDF",
              });
          } else {
            setErrorFileReject0({
              message: err?.message || 'Something went wrong'
            })
          }
          
        });
      });
    }

    if (acceptedFiles?.length > 0) {
      setErrorFileReject0({})

      const newFiles = [...files];
      const newUploading = [...uploading]
      newUploading[0] = true
      setUploading(newUploading)
      const file = acceptedFiles[0]
      newFiles[0].file = file
      newFiles[0].name = file.name;
      const uri = URL.createObjectURL(file);
      var pdf = await PDFJS.getDocument({ url: uri }).promise
      const canvas = document.createElement("canvas");
      const images = [];
      for (let i = 0; i < pdf.numPages; i++) {
        const page = await pdf.getPage(i + 1);
        const viewport = page.getViewport({ scale: 1 });
        const context = canvas.getContext("2d");
        canvas.height = viewport.height;
        canvas.width = viewport.width;
        await page.render({ canvasContext: context, viewport: viewport }).promise;
        images.push(canvas.toDataURL());
      }
      canvas.remove();
      newFiles[0].preview = images[0];
      newUploading[0] = false
      setUploading(newUploading)
      setFiles(newFiles)
    }

    // eslint-disable-next-line
  }, [files, uploading]);

  const onDrop1 = useCallback(async (acceptedFiles, fileRejections) => {
    if (fileRejections?.length) {
      fileRejections.forEach((file) => {
        file.errors.forEach((err) => {
          if (err.code === "file-too-large") {
            setErrorFileReject1({
              message:
                "File terlalu besar. Ukuran maksimal file adalah 5MB !",
            });
          } else if (err.code === "file-invalid-type") {
             
              setErrorFileReject1({
                message:
                  "Tipe file tidak sesuai. Pastikan file memiliki format PDF",
              });
          } else {
            setErrorFileReject1({
              message: err?.message || 'Something went wrong'
            })
          }
          
        });
      });
    }

    if (acceptedFiles?.length > 0) {
      setErrorFileReject1({})
      const newFiles = [...files];
      const newUploading = [...uploading]
      newUploading[1] = true
      setUploading(newUploading)
      const file = acceptedFiles[0]
      newFiles[1].file = file
      newFiles[1].name = file.name;
      const uri = URL.createObjectURL(file);
      var pdf = await PDFJS.getDocument({ url: uri }).promise
      const canvas = document.createElement("canvas");
      const images = [];
      for (let i = 0; i < pdf.numPages; i++) {
        const page = await pdf.getPage(i + 1);
        const viewport = page.getViewport({ scale: 1 });
        const context = canvas.getContext("2d");
        canvas.height = viewport.height;
        canvas.width = viewport.width;
        await page.render({ canvasContext: context, viewport: viewport }).promise;
        images.push(canvas.toDataURL());
      }
      canvas.remove();
      newFiles[1].preview = images[0];
      newUploading[1] = false
      setUploading(newUploading)
      setFiles(newFiles)
    }

    // eslint-disable-next-line
  }, [files, uploading]);

  const onDrop2 = useCallback(async (acceptedFiles, fileRejections) => {
    if (fileRejections?.length) {
      fileRejections.forEach((file) => {
        file.errors.forEach((err) => {
          if (err.code === "file-too-large") {
            setErrorFileReject2({
              message:
                "File terlalu besar. Ukuran maksimal file adalah 5MB !",
            });
          } else if (err.code === "file-invalid-type") {
             
              setErrorFileReject2({
                message:
                  "Tipe file tidak sesuai. Pastikan file memiliki format PDF",
              });
          } else {
            setErrorFileReject2({
              message: err?.message || 'Something went wrong'
            })
          }
          
        });
      });
    }

    if (acceptedFiles?.length > 0) {
      setErrorFileReject2({})
      const newFiles = [...files];
      const newUploading = [...uploading]
      newUploading[2] = true
      setUploading(newUploading)
      const file = acceptedFiles[0]
      newFiles[2].file = file
      newFiles[2].name = file.name;
      const uri = URL.createObjectURL(file);
      var pdf = await PDFJS.getDocument({ url: uri }).promise
      const canvas = document.createElement("canvas");
      const images = [];
      for (let i = 0; i < pdf.numPages; i++) {
        const page = await pdf.getPage(i + 1);
        const viewport = page.getViewport({ scale: 1 });
        const context = canvas.getContext("2d");
        canvas.height = viewport.height;
        canvas.width = viewport.width;
        await page.render({ canvasContext: context, viewport: viewport }).promise;
        images.push(canvas.toDataURL());
      }
      canvas.remove();
      newFiles[2].preview = images[0];
      newUploading[2] = false
      setUploading(newUploading)
      setFiles(newFiles)
    }

    // eslint-disable-next-line
  }, [files, uploading]);

  const onDrop3 = useCallback(async (acceptedFiles, fileRejections) => {
    if (fileRejections?.length) {
      fileRejections.forEach((file) => {
        file.errors.forEach((err) => {
          if (err.code === "file-too-large") {
            setErrorFileReject3({
              message:
                "File terlalu besar. Ukuran maksimal file adalah 5MB !",
            });
          } else if (err.code === "file-invalid-type") {
             
              setErrorFileReject3({
                message:
                  "Tipe file tidak sesuai. Pastikan file memiliki format PDF",
              });
          } else {
            setErrorFileReject3({
              message: err?.message || 'Something went wrong'
            })
          }
          
        });
      });
    }

    if (acceptedFiles?.length > 0) {
      setErrorFileReject3({})
      const newFiles = [...files];
      const newUploading = [...uploading]
      newUploading[3] = true
      setUploading(newUploading)
      const file = acceptedFiles[0]
      newFiles[3].file = file
      newFiles[3].name = file.name;
      const uri = URL.createObjectURL(file);
      var pdf = await PDFJS.getDocument({ url: uri }).promise
      const canvas = document.createElement("canvas");
      const images = [];
      for (let i = 0; i < pdf.numPages; i++) {
        const page = await pdf.getPage(i + 1);
        const viewport = page.getViewport({ scale: 1 });
        const context = canvas.getContext("2d");
        canvas.height = viewport.height;
        canvas.width = viewport.width;
        await page.render({ canvasContext: context, viewport: viewport }).promise;
        images.push(canvas.toDataURL());
      }
      canvas.remove();
      newFiles[3].preview = images[0];
      newUploading[3] = false
      setUploading(newUploading)
      setFiles(newFiles)
    }

    // eslint-disable-next-line
  }, [files, uploading]);
  const onDrop4 = useCallback(async (acceptedFiles, fileRejections) => {
    fileRejections.forEach((file) => {
      file.errors.forEach((err) => {
        if (err.code === "file-too-large") {
          setErrorFileReject4({
            message:
              "File terlalu besar. Ukuran maksimal file adalah 5MB !",
          });
        } else if (err.code === "file-invalid-type") {
           
            setErrorFileReject4({
              message:
                "Tipe file tidak sesuai. Pastikan file memiliki format PDF",
            });
        } else {
          setErrorFileReject4({
            message: err?.message || 'Something went wrong'
          })
        }
        
      });
    });

    if (acceptedFiles?.length > 0) {
      setErrorFileReject4({})
      const newFiles = [...files];
      const newUploading = [...uploading]
      newUploading[4] = true
      setUploading(newUploading)
      const file = acceptedFiles[0]
      newFiles[4].file = file
      newFiles[4].name = file.name;
      const uri = URL.createObjectURL(file);
      var pdf = await PDFJS.getDocument({ url: uri }).promise
      const canvas = document.createElement("canvas");
      const images = [];
      for (let i = 0; i < pdf.numPages; i++) {
        const page = await pdf.getPage(i + 1);
        const viewport = page.getViewport({ scale: 1 });
        const context = canvas.getContext("2d");
        canvas.height = viewport.height;
        canvas.width = viewport.width;
        await page.render({ canvasContext: context, viewport: viewport }).promise;
        images.push(canvas.toDataURL());
      }
      canvas.remove();
      newFiles[4].preview = images[0];
      newUploading[4] = false
      setUploading(newUploading)
      setFiles(newFiles)
    }

    // eslint-disable-next-line
  }, [files, uploading]);

  const ref0 = useRef()
  const ref1 = useRef()
  const ref2 = useRef()
  const ref3 = useRef()
  const ref4 = useRef()

  const resetFiles = (i) => {
    const newDefaultPreviews = [...defaultPreviews]
    newDefaultPreviews[i] = files[i].preview
    setDefaultPreviews(newDefaultPreviews)
    setFiles([
      {
        type: "AKTA CLIENT",
        title: 'Akta Perusahaan',
        file: null,
        url: null,
        preview: null,
        name: null,
      },
      {
        type: "NPWP CLIENT",
        title: 'NPWP',
        file: null,
        url: null,
        preview: null,
        name: null,
      },
      {
        type: "SIUP CLIENT",
        title: 'SIUP',
        file: null,
        url: null,
        preview: null,
        name: null,
      },
      {
        type: "DOMISILI CLIENT",
        title: 'Domisili',
        file: null,
        url: null,
        preview: null,
        name: null,
      },
      {
        type: "DOKUMEN SPK/PKS/WO/PO",
        title: 'Dokumen SPK/PKS/WO/PO',
        file: null,
        url: null,
        preview: null,
        name: null,
      },
    ])
  }

  useEffect(() => {
    const loadDefaultImages = async () => {
      const arr = []
      for (let i = 0; i < defaultDocuments.length; i++) {
        const images = []
        if (defaultDocuments[i] === undefined) {
          arr.push(null)
        } else {
          const uri = fileBaseUrl + defaultDocuments[i].url;
          var pdf = await PDFJS.getDocument({ url: uri }).promise
          const canvas = document.createElement("canvas");
          for (let i = 0; i < pdf.numPages; i++) {
            const page = await pdf.getPage(i + 1);
            const viewport = page.getViewport({ scale: 1 });
            const context = canvas.getContext("2d");
            canvas.height = viewport.height;
            canvas.width = viewport.width;
            await page.render({ canvasContext: context, viewport: viewport }).promise;
            images.push(canvas.toDataURL());
          }
          canvas.remove();
          arr.push(images[0])
        }
      }
      setDefaultPreviews(arr)
    }
    loadDefaultImages()

  }, [defaultDocuments])

  const onClickButton = async (e, i) => {
    e.preventDefault()
    const formData = new FormData()
    formData.append("file", files[i].file)
    formData.append("type", files[i].type)
    formData.append("clientCode", clientCode)

    if (defaultDocuments[i] === undefined) {
      try {
        const newSubmitting = [...submitting]
        newSubmitting[i] = true
        setSubmitting(newSubmitting)
        await createDocument(formData)
        resetFiles(i)
        const newSubmitting2 = [...submitting]
        newSubmitting[i] = false
        setSubmitting(newSubmitting2)
        setTrigger(Math.random())
      } catch (err) {
        const newSubmitting = [...submitting]
        newSubmitting[i] = false
        setSubmitting(newSubmitting)
        console.log(err)
      }
    } else {
      try {
        const newSubmitting = [...submitting]
        newSubmitting[i] = true
        setSubmitting(newSubmitting)
        await updateDocument(defaultDocuments[i].id, formData)
        resetFiles(i)
        const newSubmitting2 = [...submitting]
        newSubmitting[i] = false
        setSubmitting(newSubmitting2)
        setTrigger(Math.random())
      } catch (err) {
        const newSubmitting = [...submitting]
        newSubmitting[i] = false
        setSubmitting(newSubmitting)
        console.log(err)
      }
    }
  }

  switch (type) {
    case "mobile":
      return <Mobile />
    case "desktop":
      return <Desktop
        errors={[errorFileReject0, errorFileReject1, errorFileReject2, errorFileReject3, errorFileReject4]}
        inputFileRefs={[ref0, ref1, ref2, ref3, ref4]}
        onDrops={[onDrop0, onDrop1, onDrop2, onDrop3, onDrop4]}
        onChange={handleEditFiles}
        defaultPreviews={defaultPreviews}
        files={files}
        uploading={uploading}
        onClickButton={onClickButton}
        defaultDocuments={defaultDocuments}
        submittings={submitting}
        setShow={setShow}
      />
    default:
      return <Desktop
        errors={[errorFileReject0, errorFileReject1, errorFileReject2, errorFileReject3, errorFileReject4]}
        inputFileRefs={[ref0, ref1, ref2, ref3, ref4]}
        onDrops={[onDrop0, onDrop1, onDrop2, onDrop3, onDrop4]}
        onChange={handleEditFiles}
        defaultPreviews={defaultPreviews}
        files={files}
        uploading={uploading}
        onClickButton={onClickButton}
        defaultDocuments={defaultDocuments}
        submittings={submitting}
        setShow={setShow}
      />
  }
}
import { addClientOrder } from "@Services/client/order";
import { useCallback, useEffect, useMemo, useState } from "react";
import FifthStep from "./Steps/FifthStep";
import FirstStep from "./Steps/FirstStep";
import FourthStep from "./Steps/FourthStep";
import SecondStep from "./Steps/SecondStep";
import ThirdStep from "./Steps/ThirdStep";
import Styles from "./style.module.scss";
import Zero from "./Steps/Zero";
import Images from "@Theme/Images";
import DoneModal from "@Molecule/_modal/DoneModal";
import debounce from "lodash.debounce";
import { useNavigate } from "react-router-dom";

const bankOptions = [
  {
    name: "BCA",
    number: "1783050505",
  },
  {
    name: "BNI",
    number: "0195900064",
  },
  {
    name: "BNI CONTIGENCY",
    number: "8000177898",
  },
  {
    name: "BRI",
    number: "037601000660300",
  },
  {
    name: "BTPN",
    number: "4843000059",
  },
  {
    name: "CIMB NIAGA",
    number: "800007679100",
  },
  {
    name: "DANAMON",
    number: "000071968697",
  },
  {
    name: "BANK DBS IND",
    number: "3320129862",
  },
  {
    name: "DKI",
    number: "41208000408",
  },
  {
    name: "MANDIRI",
    number: "1280004585938",
  },
  {
    name: "MANDIRI MCM",
    number: "0700006667153",
  },
  {
    name: "MAYBANK",
    number: "2026165210",
  },
  {
    name: "MEGA",
    number: "010940011002145",
  },
  {
    name: "MNC BANK",
    number: "100010000103026",
  },
  {
    name: "NOBU BANK",
    number: "17830003001",
  },
  {
    name: "PERMATA",
    number: "701583183",
  },
  {
    name: "SAMPOERNA",
    number: "1021555106",
  },
  {
    name: "SINARMAS",
    number: "0045791556",
  },
  {
    name: "OCBC NISP",
    number: "417800003234",
  },
];

export default function MPOLayout({
  data,
  steps,
  setSteps,
  currentStep,
  setCurrentStep,
  maxStep,
  setMaxStep,
}) {
  const defaultContractForm = JSON.parse(
    sessionStorage.getItem("contractForm")
  );
  const defaultWorkSchemeForm = JSON.parse(
    sessionStorage.getItem("workSchemeForm")
  );
  const defaultManPowerForm = JSON.parse(
    sessionStorage.getItem("manPowerForm")
  );
  const defaultManfee = JSON.parse(sessionStorage.getItem("manfee"));
  const defaultTOP = JSON.parse(sessionStorage.getItem("TOP"));
  const defaultRemark = JSON.parse(sessionStorage.getItem("remark"));

  const [trigger, setTrigger] = useState(null);

  const [contractForm, setContractForm] = useState({
    contract: defaultContractForm?.contract
      ? defaultContractForm?.contract
      : null,
  });

  const contractTypeOptions = useMemo(() => {
    let arr = [];
    const options = data?.options;
    for (let i = 0; i < options.length; i++) {
      const option = options[i];
      if (
        option?.type === "contractTypeClient" &&
        option?.value === "Jasa Pemborongan"
      ) {
        arr.push({
          type: "PEMBORONGAN",
          title: option?.value,
          label: "PPN Dari Total Biaya",
          rules: [
            {
              id: 11,
              desc: "Status Karyawan PKWT atau Mitra",
            },
            {
              id: 12,
              desc: "PPh 21 dihitung dari penghasilan per bulan",
            },
            {
              id: 13,
              desc: "BPJS Kesehatan dihitung dari bantuan operasional (minimum UMP)",
            },
            {
              id: 14,
              desc: "BPJS Ketenagakerjaan JKK, JKM, JHT, atau JKK & JKM Dihitung dari bantuan operasional",
            },
            {
              id: 15,
              desc: "Manajemen Fee dihitung dari selisih nilai kontrak dengan biaya yang dikeluarkan",
            },
          ],
        });
      } else if (
        option?.type === "contractTypeClient" &&
        option?.value === "Penyedia Jasa Tenaga Kerja"
      ) {
        arr.push({
          type: "PJTK",
          title: option?.value,
          label: "PPN dari Manfee & PPN Fasilitas dari Total",
          rules: [
            {
              id: 21,
              desc: "Status Karyawan PKWT",
            },
            {
              id: 22,
              desc: "PPH 21 dihitung dari penghasilan yang disetahunkan",
            },
            {
              id: 23,
              desc: "BPJS Kesehatan dihitung dari Gapok + Tunjangan tetap (minimum UMP)",
            },
            {
              id: 24,
              desc: "BPJS Ketenagakerjaan JKK, JKM, JHT, JP Dihitung dari Gapok + Tunjangan tetap",
            },
            {
              id: 25,
              desc: "Manajemen Fee dihitung dari prosentase atau nominal yang disepakati",
            },
          ],
        });
      }
    }
    return arr;
  }, [data]);

  const firstStepDoneIndicator = useMemo(() => {
    if (contractForm?.contract) {
      return true;
    }
    return false;
  }, [contractForm]);

  const [workSchemeForm, setWorkSchemeForm] = useState({
    taxs: {
      pph21: defaultWorkSchemeForm?.taxs?.pph21
        ? defaultWorkSchemeForm?.taxs?.pph21
        : null,
      pph23: defaultWorkSchemeForm?.taxs?.pph23
        ? defaultWorkSchemeForm?.taxs?.pph23
        : null,
      ppn: defaultWorkSchemeForm?.taxs?.ppn
        ? defaultWorkSchemeForm?.taxs?.ppn
        : null,
    },
    bpjs: {
      registration: defaultWorkSchemeForm?.bpjs?.registration
        ? defaultWorkSchemeForm?.bpjs?.registration
        : null,
      paymentProof: defaultWorkSchemeForm?.bpjs?.paymentProof
        ? defaultWorkSchemeForm?.bpjs?.paymentProof
        : null,
      jkk: defaultWorkSchemeForm?.bpjs?.jkk
        ? defaultWorkSchemeForm?.bpjs?.jkk
        : null,
      jkm: defaultWorkSchemeForm?.bpjs?.jkm
        ? defaultWorkSchemeForm?.bpjs?.jkm
        : null,
      jht: defaultWorkSchemeForm?.bpjs?.jht
        ? defaultWorkSchemeForm?.bpjs?.jht
        : null,
      health: defaultWorkSchemeForm?.bpjs?.health
        ? defaultWorkSchemeForm?.bpjs?.health
        : null,
      akhdk: defaultWorkSchemeForm?.bpjs?.akhdk
        ? defaultWorkSchemeForm?.bpjs?.akhdk
        : null,
      jp: defaultWorkSchemeForm?.bpjs?.jp
        ? defaultWorkSchemeForm?.bpjs?.jp
        : null,
      cutOffBPJSTKO: defaultWorkSchemeForm?.bpjs?.cutOffBPJSTKO
        ? defaultWorkSchemeForm?.bpjs?.cutOffBPJSTKO
        : null,
    },
    others: {
      manfee: defaultWorkSchemeForm?.others?.manfee
        ? defaultWorkSchemeForm?.others?.manfee
        : [],
      thr: defaultWorkSchemeForm?.others?.thr
        ? defaultWorkSchemeForm?.others?.thr
        : null,
      uak: defaultWorkSchemeForm?.others?.uak
        ? defaultWorkSchemeForm?.others?.uak
        : null,
      tkoBank: defaultWorkSchemeForm?.others?.tkoBank
        ? defaultWorkSchemeForm?.others?.tkoBank
        : [],
    },
  });

  const workSchemeOptions = useMemo(() => {
    const arr = [];
    const options = data?.options;
    const banks = data?.banks;

    const taxs = [];
    const pph21 = [];
    const pph23 = [];
    const ppn = [];

    const bpjs = [];
    const registration = [];
    const paymentProof = [];
    const jkk = [];
    const jkm = [];
    const jht = [];
    const health = [];
    const akdhk = [];
    const jp = [];
    const cutOffBPJSTKO = [];

    const others = [];
    const manfee = [];
    const uak = [];
    const thr = [];
    const tkoBank = [];

    for (let i = 0; i < options?.length; i++) {
      const option = options[i];
      if (option?.type === "PPH21") {
        pph21.push({
          id: option.id,
          name: option?.value,
        });
      } else if (option?.type === "PPH23") {
        pph23.push({
          id: option.id,
          name: option?.value,
        });
      } else if (option?.type === "PPN") {
        ppn.push({
          id: option.id,
          name: option?.value,
        });
      } else if (option?.type === "Pendaftaran BPJS") {
        registration.push({
          id: option.id,
          name: option?.value,
        });
      } else if (option?.type === "Bukti Pendaftaran BPJS") {
        paymentProof.push({
          id: option.id,
          name: option?.value,
        });
      } else if (option?.type === "JKK") {
        jkk.push({
          id: option.id,
          name: option?.value,
        });
      } else if (option?.type === "JKM") {
        jkm.push({
          id: option.id,
          name: option?.value,
        });
      } else if (option?.type === "JHT") {
        jht.push({
          id: option.id,
          name: option?.value,
        });
      } else if (option?.type === "JP") {
        jp.push({
          id: option.id,
          name: option?.value,
        });
      } else if (option?.type === "KS") {
        health.push({
          id: option.id,
          name: option?.value,
        });
      } else if (option?.type === "AKDHK") {
        akdhk.push({
          id: option.id,
          name: option?.value,
        });
      } else if (option?.type === "Pengenaan Manfee") {
        manfee.push({
          id: option.id,
          name: option?.value,
        });
      } else if (option?.type === "Penagihan UAK") {
        uak.push({
          id: option.id,
          name: option?.value,
        });
      } else if (option?.type === "Penagihan THR") {
        thr.push({
          id: option.id,
          name: option?.value,
        });
      } else if (option?.type === "AKDHK") {
        akdhk.push({
          id: option.id,
          name: option?.value,
        });
      } else if (option?.type === "Potongan BPJS TKO") {
        cutOffBPJSTKO.push({
          id: option.id,
          name: option?.value,
        });
      }
    }

    for (let i = 0; i < banks.length; i++) {
      tkoBank.push({
        id: i,
        name: banks[i],
      });
    }

    taxs.push({
      pph21: pph21,
      pph23: pph23,
      ppn: ppn,
    });
    bpjs.push({
      registration: registration,
      paymentProof: paymentProof,
      jkk: jkk,
      jkm: jkm,
      jht: jht,
      health: health,
      akdhk: akdhk,
      jp: jp,
      cutOffBPJSTKO: cutOffBPJSTKO,
    });
    others.push({
      manfee: manfee,
      uak: uak,
      thr: thr,
      tkoBank: tkoBank,
    });

    arr.push({
      taxs: taxs[0],
      bpjs: bpjs[0],
      others: others[0],
    });

    return arr[0];
  }, [data]);

  const secondStepDoneIndicator = useMemo(() => {
    if (workSchemeForm?.taxs?.pph21 === null) {
      return false;
    } else if (workSchemeForm?.taxs?.pph23 === null) {
      return false;
    } else if (workSchemeForm?.taxs?.ppn === null) {
      return false;
    } else if (workSchemeForm?.others?.manfee?.length === 0) {
      return false;
    } else if (workSchemeForm?.others?.thr === null) {
      return false;
    } else if (workSchemeForm?.others?.uak === null) {
      return false;
    } else if (workSchemeForm?.others?.tkoBank?.length === 0) {
      return false;
    } else {
      return true;
    }
    // eslint-disable-next-line
  }, [workSchemeForm, trigger]);

  const fixedWorkSchemeManFee = useMemo(() => {
    let arr = [];

    const manfeeArr = workSchemeForm?.others?.manfee;
    for (let i = 0; i < manfeeArr?.length; i++) {
      const manfee = manfeeArr[i];
      arr.push(manfee?.name);
    }
    return arr.join("::");
  }, [workSchemeForm?.others?.manfee]);

  const fixedTkoBank = useMemo(() => {
    let arr = [];

    const banksArr = workSchemeForm?.others?.tkoBank;
    for (let i = 0; i < banksArr?.length; i++) {
      const bank = banksArr[i];
      arr.push(bank);
    }
    return arr.join("::");
  }, [workSchemeForm?.others?.tkoBank]);

  const fixedBpjsCut = useMemo(() => {
    return workSchemeForm?.bpjs?.cutOffBPJSTKO;
  }, [workSchemeForm?.bpjs?.cutOffBPJSTKO]);

  const [manPowerForm, setManPowerForm] = useState(
    defaultManPowerForm?.length > 0 ? defaultManPowerForm : []
  );

  const qualificationOptions = useMemo(() => {
    const qualifications = data?.qualifications;
    const arr = [];
    for (let i = 0; i < qualifications.length; i++) {
      const qualification = qualifications[i];

      arr.push({
        name: qualification?.value,
        denom: qualification.denom,
        value: null,
      });
    }

    return arr;
  }, [data]);

  const manPowerOptions = useMemo(() => {
    const arr = [];
    const options = data?.options;

    const contractTypeWorker = [];
    const personalWorkEquipment = [];
    const allowance = [];
    const areaWorkEquipment = [];

    for (let i = 0; i < options.length; i++) {
      const option = options[i];

      if (option?.type === "contractTypeWorker") {
        contractTypeWorker.push({
          id: option.id,
          name: option?.value,
        });
      } else if (option?.type === "Personal Work Equipment") {
        personalWorkEquipment.push({
          name: option?.value,
          value: "",
        });
      } else if (option?.type === "Allowance") {
        allowance.push({
          name: option?.value,
          value: "",
        });
      } else if (option?.type === "Area Work Equipment") {
        areaWorkEquipment.push({
          name: option?.value,
          value: "",
        });
      }
    }

    arr.push({
      contractTypeWorker: contractTypeWorker,
      personalWorkEquipment: personalWorkEquipment,
      allowance: allowance,
      areaWorkEquipment: areaWorkEquipment,
      cities: data?.cities,
      qualifications: qualificationOptions,
    });

    return arr[0];
  }, [data, qualificationOptions]);

  const thirdStepDoneIndicator = useMemo(() => {
    let temp;
    if (manPowerForm?.length === 0) {
      return false
    } else {
      for (let i = 0; i < manPowerForm?.length; i++) {
        const perLocation = manPowerForm[i]
        if (perLocation?.areaWorkEquipments?.length > 0) {
          for (let j = 0; j < perLocation?.areaWorkEquipments?.length; j++) {
            if (perLocation?.areaWorkEquipments[j]?.value && perLocation?.areaWorkEquipments[j]?.value !== 0) {
              temp = true
            } else {
              return false
            }
          }
        }
        if (perLocation?.jobs?.length > 0) {
          for (let j = 0; j < perLocation?.jobs?.length; j++) {
            const perJob = perLocation?.jobs[j]
            if (!perJob?.title || !perJob?.contractType || !perJob?.fulfillmentDate) {
              return false
            }

            if (perJob?.qualifications?.length < 1) {
              return false
            } else {
              for (let k = 0; k < perJob?.qualifications?.length; k++) {
                if (!perJob?.qualifications[k]?.value || perJob?.qualifications[k]?.value === 0 || perJob?.qualifications[k]?.value === '') {
                  return false
                } else {
                  temp = true
                }
              }
            }

            for (let k = 0; k < perJob?.allowances?.length; k++) {
              if (!perJob?.allowances[k]?.value || perJob?.allowances[k]?.value === 0 || perJob?.allowances[k]?.value === '0' || perJob?.allowances[k]?.value === '') {
                return false
              } else {
                temp = true
              }
            }

            for (let k = 0; k < perJob?.personalEquipments?.length; k++) {
              if (!perJob?.personalEquipments[k]?.value || perJob?.personalEquipments[k]?.value === 0 || perJob?.personalEquipments[k]?.value === '0' || perJob?.personalEquipments[k]?.value === '') {
                return false
              } else {
                temp = true
              }
            }
          }
        } else {
          return false
        }

      }
    }

    return temp
    // eslint-disable-next-line
  }, [manPowerForm, trigger]);

  const [manfee, setManFee] = useState(defaultManfee || "7%");
  const [TOP, setTOP] = useState(defaultTOP || 0);
  const [remark, setRemark] = useState(defaultRemark ? defaultRemark : "");

  const onPrevious = () => {
    setCurrentStep(currentStep - 1);
  };

  const onNext = (isDone) => {
    const newSteps = [...steps];
    if (isDone) {
      newSteps[currentStep - 1].isDone = true;
      setSteps(newSteps);
      setCurrentStep(currentStep + 1);
    }
  };

  const maxStepId = useMemo(() => {
    const found = steps.find((el) => el?.isDone === false);
    return found?.id;
  }, [steps]);

  useEffect(() => {
    setMaxStep(maxStepId);
  }, [maxStepId, setMaxStep]);

  const form = useMemo(() => {
    return {
      contractType: contractForm?.contract,
      clientCode: sessionStorage.getItem("clientCode"),
      setManFee: manfee,
      setTOP: TOP,
      remark: remark,
      workSchemes: workSchemeForm,
      manPowers: manPowerForm,
    };
  }, [contractForm, workSchemeForm, manPowerForm, TOP, manfee, remark]);

  const formatDate = (date) => {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  const [isSuccess, setIsSuccess] = useState(false);
  const [error, setError] = useState(null);

  const [submitting, setSubmitting] = useState(null);

  const handleSubmit = async (e) => {
    setSubmitting(true);
    try {
      e.preventDefault();

      const formData = new FormData();

      formData.append("contractType", form?.contractType);
      formData.append("clientCode", form?.clientCode);
      formData.append("setManFee", form?.setManFee);
      formData.append("setTOP", form?.setTOP);
      formData.append("remark", form?.remark);

      formData.append(
        "workScheme[taxs][pph21]",
        form?.workSchemes?.taxs?.pph21
      );
      formData.append(
        "workScheme[taxs][pph23]",
        form?.workSchemes?.taxs?.pph23
      );
      formData.append("workScheme[taxs][ppn]", form?.workSchemes?.taxs?.ppn);
      formData.append(
        "workScheme[bpjs][registration]",
        form?.workSchemes?.bpjs?.registration
      );
      formData.append(
        "workScheme[bpjs][paymentProof]",
        form?.workSchemes?.bpjs?.paymentProof
      );
      formData.append("workScheme[bpjs][jkk]", form?.workSchemes?.bpjs?.jkk);
      formData.append("workScheme[bpjs][jkm]", form?.workSchemes?.bpjs?.jkm);
      formData.append("workScheme[bpjs][jht]", form?.workSchemes?.bpjs?.jht);
      formData.append(
        "workScheme[bpjs][akhdk]",
        form?.workSchemes?.bpjs?.akhdk
      );
      formData.append("workScheme[bpjs][jp]", form?.workSchemes?.bpjs?.jp);
      formData.append(
        "workScheme[bpjs][health]",
        form?.workSchemes?.bpjs?.health
      );
      formData.append(
        "workScheme[bpjs][cutOffBPJSTKO]",
        form?.workSchemes?.bpjs?.cutOffBPJSTKO
      );
      formData.append("workScheme[others][manfee]", fixedWorkSchemeManFee);
      formData.append(
        "workScheme[others][thr]",
        form?.workSchemes?.others?.thr
      );
      formData.append(
        "workScheme[others][uak]",
        form?.workSchemes?.others?.uak
      );
      formData.append("workScheme[others][tkoBank]", fixedTkoBank);

      const manPowersArr = form?.manPowers;

      for (let i = 0; i < manPowersArr?.length; i++) {
        const perlocationArr = form?.manPowers[i];
        const jobsArr = perlocationArr?.jobs;
        const areaEquipmentsArr = perlocationArr?.areaWorkEquipments;

        formData.append(`manPowers[${i}][location]`, perlocationArr?.location);

        for (let j = 0; j < areaEquipmentsArr.length; j++) {
          const equipment = areaEquipmentsArr[j];
          formData?.append(
            `manPowers[${i}][areaWorkEquipments][${j}][name]`,
            equipment?.name
          );
          formData?.append(
            `manPowers[${i}][areaWorkEquipments][${j}][price]`,
            equipment?.value
          );
        }

        for (let j = 0; j < jobsArr?.length; j++) {
          const allowancesArr = jobsArr[j]?.allowances;
          const qualificationsArr = jobsArr[j]?.qualifications;
          const personalEquipmentsArr = jobsArr[j]?.personalEquipments;

          const job = jobsArr[j];

          formData?.append(`manPowers[${i}][jobs][${j}][title]`, job?.title);
          formData?.append(
            `manPowers[${i}][jobs][${j}][contractType]`,
            job?.contractType
          );
          formData?.append(`manPowers[${i}][jobs][${j}][salary]`, job?.salary);
          formData?.append(
            `manPowers[${i}][jobs][${j}][fulfilmentDate]`,
            job?.fulfillmentDate ? formatDate(job?.fulfillmentDate) : null
          );
          formData?.append(`manPowers[${i}][jobs][${j}][demand]`, job?.demand);

          for (let k = 0; k < allowancesArr.length; k++) {
            formData?.append(
              `manPowers[${i}][jobs][${j}][allowances][${k}][name]`,
              allowancesArr[k]?.name
            );
            formData?.append(
              `manPowers[${i}][jobs][${j}][allowances][${k}][value]`,
              allowancesArr[k]?.value
            );
          }

          for (let k = 0; k < qualificationsArr.length; k++) {
            formData?.append(
              `manPowers[${i}][jobs][${j}][qualifications][${k}][name]`,
              qualificationsArr[k]?.name
            );
            formData?.append(
              `manPowers[${i}][jobs][${j}][qualifications][${k}][value]`,
              qualificationsArr[k]?.value
            );
          }

          for (let k = 0; k < personalEquipmentsArr.length; k++) {
            formData?.append(
              `manPowers[${i}][jobs][${j}][personalEquipments][${k}][name]`,
              personalEquipmentsArr[k]?.name
            );
            formData?.append(
              `manPowers[${i}][jobs][${j}][personalEquipments][${k}][price]`,
              personalEquipmentsArr[k]?.value
            );
          }
        }
      }

      await addClientOrder(new URLSearchParams(formData));
      setSubmitting(false);

      setIsSuccess(true);
    } catch (err) {
      setSubmitting(false);
      console.log(err?.response?.data?.error);
      setError({
        code: err?.response?.data?.code,
        message: err?.response?.data?.error,
      });
    }
  };

  const handleMakeRequest = () => {
    setMaxStep(1);
    setCurrentStep(1);
  };

  const handleResetForm = () => {
    setCurrentStep(0);
    setMaxStep(0);
    setContractForm({
      contract: null,
    });
    setWorkSchemeForm({
      taxs: {
        pph21: null,
        ppn: null,
      },
      bpjs: {
        registration: null,
        paymentProof: null,
        jkk: null,
        jkm: null,
        jht: null,
        health: null,
        akhdk: null,
        jp: null,
        cutOffBPJSTKO: null,
      },
      others: {
        manfee: [],
        thr: null,
        uak: null,
        tkoBank: [],
      },
    });

    setManPowerForm([]);
    setManFee("7%");
    setTOP(0);
    setRemark("");
  };

  const clearStorage = () => {
    sessionStorage.removeItem('contractForm');
    sessionStorage.removeItem('workSchemeForm');
    sessionStorage.removeItem('manPowerForm')
    sessionStorage.removeItem('remark')
    sessionStorage.removeItem('TOP')
    sessionStorage.removeItem('manfee')
    sessionStorage.removeItem('orderFiles')
    sessionStorage.removeItem('mpoStep')
    sessionStorage.removeItem('maxOrderStep')
    sessionStorage.removeItem('steps')
  }

  const navigate = useNavigate()
  const handleDone = () => {
    setIsSuccess(false);
    handleResetForm();
    clearStorage();
    navigate(`/dashboard/orders?c=${localStorage.getItem("clientCode")}`)
  };

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setError(null);
      }, 5000);
    }
  }, [error]);

  const renewTrigger = useCallback(() => {
    setTrigger(Math.random());
  }, []);

  const debounceTrigger = useMemo(
    () => debounce(renewTrigger, 500),
    [renewTrigger]
  );

  const renewDefault = useCallback(() => {
    sessionStorage.setItem('contractForm', JSON.stringify(contractForm));
    sessionStorage.setItem('workSchemeForm', JSON.stringify(workSchemeForm));
    sessionStorage.setItem('manPowerForm', JSON.stringify(manPowerForm))
    sessionStorage.setItem('remark', JSON.stringify(remark))
    sessionStorage.setItem('TOP', JSON.stringify(TOP))
    sessionStorage.setItem('manfee', JSON.stringify(manfee))
    sessionStorage.setItem('steps', JSON.stringify(steps))
    sessionStorage.setItem('maxOrderStep', maxStep)
  }, [contractForm, workSchemeForm, manPowerForm, remark, TOP, manfee, maxStep, steps])

  const debounceDefault = useMemo(() => debounce(renewDefault, 500), [renewDefault])

  useEffect(() => {
    if (currentStep !== 0) {
      debounceDefault();
    }
  }, [debounceDefault, trigger, currentStep]);

  return (
    <div className={Styles.container}>
      {currentStep === 5 && (
        <div className={`${Styles.submitHeader} ${error ? Styles.error : ""}`}>
          <div className={Styles.left}>
            <img src={Images.CHECK_LIST} alt="summary" />
            <div className={Styles.middle}>
              <h3>{error ? error?.code : "Summary"}</h3>
              <p>
                {error
                  ? error?.message
                  : "Silahkan cek kembali summary anda dan pastikan anda telah mengisi data dengan benar"}
              </p>
            </div>
          </div>

          <button disabled={error || submitting} onClick={handleSubmit}>
            {submitting ? "Submitting request..." : "Submit Request"}
          </button>
        </div>
      )}
      <div className={Styles.contentWrapper}>
        {currentStep === 0 && <Zero handleMakeRequest={handleMakeRequest} />}
        {currentStep === 1 && (
          <FirstStep
            debounceTrigger={debounceTrigger}
            form={contractForm}
            setForm={setContractForm}
            options={contractTypeOptions}
            isDone={firstStepDoneIndicator}
            onNext={() => onNext(firstStepDoneIndicator)}
          />
        )}
        {currentStep === 2 && (
          <SecondStep
            debounceTrigger={debounceTrigger}
            bankOptions={bankOptions}
            form={workSchemeForm}
            setForm={setWorkSchemeForm}
            options={workSchemeOptions}
            isDone={secondStepDoneIndicator}
            onNext={() => onNext(secondStepDoneIndicator)}
            onPrevious={() => onPrevious()}
            setTrigger={setTrigger}
          />
        )}
        {currentStep === 3 && (
          <ThirdStep
            debounceTrigger={debounceTrigger}
            form={manPowerForm}
            setForm={setManPowerForm}
            options={manPowerOptions}
            isDone={thirdStepDoneIndicator}
            onNext={() => onNext(thirdStepDoneIndicator)}
            onPrevious={() => onPrevious()}
            remark={remark}
            setRemark={setRemark}
            trigger={trigger}
            setTrigger={setTrigger}
          />
        )}
        {currentStep === 4 && (
          <FourthStep
            debounceTrigger={debounceTrigger}
            fixedManfee={fixedWorkSchemeManFee}
            fixedBpjsCut={fixedBpjsCut}
            manfee={manfee}
            setManfee={setManFee}
            TOP={TOP}
            setTOP={setTOP}
            form={manPowerForm}
            workSchemeForm={workSchemeForm}
            setForm={setManPowerForm}
            cities={data?.cities}
            isDone={true}
            onNext={() => onNext(true)}
            onPrevious={onPrevious}
          />
        )}
        {currentStep === 5 && (
          <FifthStep
            debounceTrigger={debounceTrigger}
            ppn={workSchemeForm?.taxs?.ppn}
            fixedManfee={fixedWorkSchemeManFee}
            fixedBpjsCut={fixedBpjsCut}
            form={form}
            onPrevious={onPrevious}
            contractType={contractTypeOptions}
            cities={data?.cities}
            onSubmit={handleSubmit}
            isSubmitting={submitting}
            isSuccess={isSuccess}
            setIsSuccess={setIsSuccess}
            error={error}
            setError={setError}
          />
        )}
      </div>
      {isSuccess && (
        <DoneModal
          title="Tekirim"
          description={
            <span className={Styles.modalText}>
              Request anda berhasil terkirim, tim kami akan segera menghubungi
              untuk melakukan verifikasi dan validasi
            </span>
          }
          doneButton
          handleDone={handleDone}
          doneTitle={"OK"}
        />
      )}
    </div>
  );
}

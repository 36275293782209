import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function Logout(){
    const navigate = useNavigate();
    useEffect( () => {
        localStorage.clear()
        sessionStorage.clear()
        navigate("/")
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return(<></>)
}
import React from "react";
import Styles from "./Styles.module.scss";

export default function IndustrySupported({ feature }) {
  return (
    <div className={Styles.industrySupported}>
      <div className={Styles.container}>
        <div className={Styles.feature}>
          {" "}
          <p className={Styles.featureName}>{feature.name}</p>{" "}
        </div>
        <div className={Styles.supportedJobs}>
          {feature.jobs.map((el, idx) => (
            <span className={Styles.jobName} key={idx}>
              {el}
            </span>
          ))}
        </div>
      </div>
    </div>
  );
}
